// Main Slider in homepage


.home-slider.owl-carousel {
    position: relative;
    .owl-item {
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: rgba(0, 0, 0, 0.2);
        }
        .item {
            position: relative;
            overflow: hidden;
            height: 520px;
            .slider-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 80% 50%;
                position: absolute;
                top: 0;
                left: 0;
            }
            .content {
                display: flex;
                align-items: center;
                height: 100%;
                z-index: 2;
                color: $white;
                justify-content: center;
                @include media-breakpoint-down(md) {
                    justify-content: left;
                }
                .info-panel {
                    max-width: 720px;
                    height: 100%;
                    width: 100%;
                    &.content-panel {
                        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
                        background-color: #007ecea8;
                        @include media-breakpoint-down(md) {
                            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
                            padding-left: 2%;
                        }
                        .skewed-panel {
                            padding: 125px 0 60px;
                            height: 100%;
                            position: relative;
                            .badge {
                                opacity: 0.54;
                                display: inline-block;
                                position: absolute;
                                left: 22%;
                                top: 90px;
                                margin: 0;
                                @include media-breakpoint-down(md) {
                                    left: 0;
                                }
                            }
                            .date {
                                display: flex;
                                align-items: center;
                                font-size: 15px;
                                font-weight: 500;
                                color: $white;
                                opacity: 0.54;
                                span:not(:last-child) {
                                    margin-right: 20px;
                                }
                                position: absolute;
                                justify-content: flex-end;
                                width: auto;
                                top: 90px;
                                right: 10%;
                                margin: 0;
                            }
                            .title {
                                margin-bottom: 17px;
                                font-size: 30px;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: italic;
                                width: 100%;
                            }
                            .description{
                                width: 100%;
                            }
                            &> p {
                                height: 55%;
                            }
                            .hovered {
                                position: absolute;
                                bottom: 90px;
                                width: 100%;
                                padding: 0 20% 0 8%;;
                                @include media-breakpoint-down(md) {
                                    padding-left: 0;
                                }
                                .countdown {
                                    display: flex;
                                    justify-content: center;
                                    margin: 0;
                                    .timer{
                                        font-size: 1.5rem;
                                        width: 50px;
                                        margin-right: 25px;
                                        text-align: center;
                                        &:after{
                                            right: -12px;
                                        }
                                    }
                                }
                                .circle{
                                    position: relative;
                                    left: -25px;
                                    @include media-breakpoint-down(md) {
                                        left : -7px;
                                    }
                                }
                            }
                            .slider-action {
                                position: absolute;
                                width: 80%;
                                left: 1%;
                                bottom: 40px;
                                text-align: center;
                                height: 40px;
                                @include media-breakpoint-down(md) {
                                    left: 0;
                                }
                            }
                        }
                        .skewed-left {
                            display: block;
                            width: 22%;
                            height: 100%;
                            float: left;
                            shape-outside: polygon(0 0, 100% 0, 10% 100%, 0 100%);
                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                        .skewed-right {
                            display: block;
                            float: right;
                            width: 22%;
                            height: 100%;
                            shape-outside: polygon(90% 0, 100% 0, 100% 100%, 0 100%);
                        }
                    }
                    &.empty-panel {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }
            }
            &.grand-public{
                .content {
                    .info-panel.content-panel {
                        background-color: #000000a8;
                    }
                }
            }
            &.page-slide {
                .content {
                    .info-panel.content-panel {
                        background-color: #00558aa8;
                    }
                }
            }
            &.link-slide {
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -70px;
                    width: 22%;
                    height: 520px;
                    transform: skew(-15deg, 0);
                    z-index: 1;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: calc(22% - 70px);
                    width: 30%;
                    height: 520px;
                    opacity: 0.79;
                    background-color: #333132;
                    transform: skew(-15deg, 0);
                    z-index: 0;
                    @include media-breakpoint-down(md) {
                        left: -20%;
                        width: 70%;
                    }
                }
            }
        }
    }
    &.owl-theme .owl-nav {
        display: none;
        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: 33px;
            right: 43px;
            display: block;
        }
    }
    &.owl-theme .owl-dots {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
    }
    .owl-nav .owl-prev.disabled,
    .owl-nav .owl-next.disabled {
        display: none;
    }
}

.owl-carousel:not(.home-slider) {
    .owl-stage-outer {
        padding-bottom: 36px;
    }
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: $blue;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
    border-radius: 50%;
}

.home-slider.owl-theme .owl-dots .owl-dot.active span,
.home-slider.owl-theme .owl-dots .owl-dot:hover span {
    background: $white;
}

.home-slider.owl-theme .owl-dots .owl-dot:focus,
.home-slider.owl-carousel .owl-nav button.owl-prev:focus,
.home-slider.owl-carousel .owl-nav button.owl-next:focus {
    outline: none;
}

.live-courses-slider {
    .owl-stage {
        // display: flex;
        // align-items: center;
    }
    .caption {
        text-align: center;
    }
    .owl-item {
        .item{
            width: 317px;
            margin: auto;
        }
    }
}

.small-group-slider {
    .owl-stage {
        // display: flex;
        // align-items: center;
    }
    .caption {
        text-align: center;
    }
    .owl-item {
        .item {
            width: 317px;
            margin: auto;
        }
    }
}

.planing-live-corse {
    margin-bottom: 50px;
    // .owl-item {
    //     width: 317px !important;
    // }
    @include media-breakpoint-up(lg) {
        .owl-stage-outer {
            padding: 16px 20px;
        }
    }
    &.owl-theme .owl-dots {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        .owl-dot.active span,
        .owl-dot:hover span {
            background: $blue;
        }
    }
    &.owl-theme {
        // display: none;
        @include media-breakpoint-up(lg) {
            display: block;
            .disabled {
                display: none;
            }
        }
    }
    .owl-nav {
        // display: none;
    }
    .owl-nav button.owl-prev {
        position: absolute;
        top: 41%;
        left: -40px;
    }
    .owl-nav button.owl-next {
        position: absolute;
        top: 41%;
        right: -40px;
    }
}

// Coach info box
.coach-info {
    position: relative;
    width: auto;
    max-width: 375px;
    height: auto;
    margin-top: 60px;
    margin-bottom: 20px;
    color: $white;
    .info {
        display: none;
        .title {
            position: relative;
            margin-bottom: 30px;
            font-family: $antique-regular;
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            color: #00416a;
            strong {
                font-family: $antique;
                font-size: 32px;
            }
            .votes {
                line-height: 1;
            }
            // &:after {
            //   content: "";
            //   position: absolute;
            //   left: 0;
            //   bottom: 0;
            //   width: 100%;
            //   border-bottom: 2px solid $white;
            // }
        }
        p.biography {
            -webkit-line-clamp: 12;
        }
        a {
            &.reserver-coach {
                color: #101010 !important;
            }
            &:hover {
                &.reserver-coach {
                    color: #101010 !important;
                }
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
            font-size: 14px;
            li {
                margin-bottom: 8px;
            }
        }
        p {
            font-size: 14px;
            line-height: normal;
            margin-top: 8px;
        }
    }
}

.personal-training-slider {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 60px;
        left: 50%;
        width: 375px;
        height: 494px;
        border-radius: 25px;
        box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.2);
        background: linear-gradient(142deg, #0083ca 1%, #005286);
        transform: translateX(-50%);
        @include media-breakpoint-down(md) {
            width: 96%;
        }
    }
    .owl-item .coach-img {
        width: 235px;
        height: 600px;
        object-fit: contain;
        z-index: 2;
        transform: scale(0.5);
        opacity: 0.2;
        filter: grayscale(0.5);
        transform: scale(0.5) translateX(50%);
        transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
    .owl-item.medium .coach-img {
        transform: scale(0.75);
        opacity: 0.45;
        filter: grayscale(0.5);
    }
    .owl-item.large .coach-img {
        position: absolute;
        top: -50px;
        right: 50%;
        transform: scale(1.17);
        filter: grayscale(0);
        opacity: 1;
    }
    .owl-item.active.center {
        transform: scale(1);
        .coach-info {
            width: 375px;
            padding: 32px 145px 32px 32px;
            @include media-breakpoint-down(md) {
                padding: 13px 139px 36px 0px;
                width: 96%;
            }
            .info {
                display: block;
                text-align: left;
            }
        }
        img.coach-img {
            right: -90px;
        }
    }
    .owl-item {
        text-align: center;
        .coach-info {
            display: inline-block;
        }
    }
    &.owl-theme .owl-nav {
        display: block;
    }
}

@include media-breakpoint-up(lg) {
    .personal-training-slider {
        .owl-nav button.owl-prev {
            position: absolute;
            top: 275px;
            left: calc(50% - 270px);
        }
        .owl-nav button.owl-next {
            position: absolute;
            top: 275px;
            right: calc(50% - 300px);
        }
    }
}

@include media-breakpoint-down(md) {
    .personal-training-slider {
        &:before {
        }
        .owl-item.active.center img.coach-img {
            right: 0;
            top: 21px;
            width: 146px;
            height: 464px;
        }
        .owl-nav button.owl-prev {
            position: absolute;
            bottom: 80px;
            left: 50%;
            margin-left: -18.5px;
            transform: translateX(-25px);
        }
        .owl-nav button.owl-next {
            position: absolute;
            bottom: 80px;
            left: 50%;
            margin-left: -18.5px;
            transform: translateX(25px);
        }
    }
}

.videos-slider {
    &.owl-theme .owl-nav {
        display: none;
        @media (min-width: 1100px) {
            display: block;
        }
    }
    .owl-nav button.owl-prev {
        position: absolute;
        top: 62px;
        left: -40px;
        width: 20px;
    }
    .owl-nav button.owl-next {
        position: absolute;
        top: 62px;
        right: -40px;
        width: 20px;
        &:hover {
            background: transparent;
        }
    }
    .owl-nav .owl-prev.disabled,
    .owl-nav .owl-next.disabled {
        display: none;
    }
    .owl-nav button.owl-prev {
        position: absolute;
        top: 62px;
        right: -40px;
        width: 20px;
        &:hover {
            background: transparent;
        }
    }
    // .owl-theme .owl-controls .owl-nav .disabled {
    //     opacity: 0.5;
    //     cursor: default;
    // }
}

@media (min-width: 1300px) and (max-width: 1400px) {
    .slider-live {
        margin-left: 11rem;
    }
}

@media (min-width: 1401px) and (max-width: 1600px) {
    .slider-live {
        margin-left: 6.9rem;
    }
}

@media (min-width: 992px) and (max-width: 1366px) {
    .slider-live {
        margin-left: 11.3333333333%;
    }
}
