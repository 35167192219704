@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "~animate.css";
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css";
@import "~ion-rangeslider/css/ion.rangeSlider.css";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~chart.js/dist/Chart.css";
@import "~jquery-toast-plugin/dist/jquery.toast.min.css";
@font-face {
  font-family: "AntiqueOliveStd-Nord";
  src: url("../../fonts/antique-olive/AntiqueOliveStd-Nord.eot");
  src: url("../../fonts/antique-olive/AntiqueOliveStd-Nord.eot?#iefix") format("embedded-opentype"), url("../../fonts/antique-olive/AntiqueOliveStd-Nord.woff2") format("woff2"), url("../../fonts/antique-olive/AntiqueOliveStd-Nord.woff") format("woff"), url("../../fonts/antique-olive/AntiqueOliveStd-Nord.ttf") format("truetype"), url("../../fonts/antique-olive/AntiqueOliveStd-Nord.svg#AntiqueOliveStd-Nord") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AntiqueOliveStd-Nord";
  src: url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.eot");
  src: url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.woff2") format("woff2"), url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.woff") format("woff"), url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.ttf") format("truetype"), url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.svg#AntiqueOliveStd-NordItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "AntiqueOliveStd";
  src: url("../../fonts/antique-olive/AntiqueOliveStd-Italic.eot");
  src: url("../../fonts/antique-olive/AntiqueOliveStd-Italic.eot?#iefix") format("embedded-opentype"), url("../../fonts/antique-olive/AntiqueOliveStd-Italic.woff2") format("woff2"), url("../../fonts/antique-olive/AntiqueOliveStd-Italic.woff") format("woff"), url("../../fonts/antique-olive/AntiqueOliveStd-Italic.ttf") format("truetype"), url("../../fonts/antique-olive/AntiqueOliveStd-Italic.svg#AntiqueOliveStd-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-Regular.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-Regular.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-SemiBoldItalic.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-SemiBoldItalic.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-BoldItalic.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-BoldItalic.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-Bold.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-Bold.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-UltraLight.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-UltraLight.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-UltraLightItalic.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-UltraLightItalic.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-Light.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-Light.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-SemiBold.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-SemiBold.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-MediumItalic.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-MediumItalic.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-Thin.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-Thin.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-RegularItalic.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-RegularItalic.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-Medium.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-Medium.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-LightItalic.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-LightItalic.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Geogrotesque";
  src: url("../../fonts/geogrotesque/Geogrotesque-ThinItalic.woff2") format("woff2"), url("../../fonts/geogrotesque/Geogrotesque-ThinItalic.woff") format("woff"), url("../../fonts/geogrotesque/Geogrotesque-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #0080ce;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #00c092;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0080ce;
  --secondary: #ebebeb;
  --success: #00c092;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #ffffff;
  --dark: #343434;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343434;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0080ce;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #005082;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #343434;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #343434;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #343434;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #d3d3d3;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #d3d3d3;
}
.table tbody + tbody {
  border-top: 2px solid #d3d3d3;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #d3d3d3;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #d3d3d3;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #343434;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8dbf1;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abde6;
}

.table-hover .table-primary:hover {
  background-color: #a3d0ed;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a3d0ed;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9f9f9;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: whitesmoke;
}

.table-hover .table-secondary:hover {
  background-color: #ececec;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ececec;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8ede0;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7adec6;
}

.table-hover .table-success:hover {
  background-color: #a4e8d7;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a4e8d7;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: white;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c6c6;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #959595;
}

.table-hover .table-dark:hover {
  background-color: #b9b9b9;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9b9b9;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #f0f2f3;
  border-color: #d3d3d3;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 18.5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #4fbcff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f0f2f3;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #343434;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00c092;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(0, 192, 146, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00c092;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300c092' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00c092;
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 146, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00c092;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2300c092' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #00c092;
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 146, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00c092;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00c092;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00c092;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00f3b9;
  background-color: #00f3b9;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 146, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00c092;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00c092;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00c092;
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 146, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #343434;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 9px 35px;
  font-size: 0.9375rem;
  line-height: normal;
  border-radius: 18.5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #343434;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #0080ce;
  border-color: #0080ce;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #0068a8;
  border-color: #00609b;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #0068a8;
  border-color: #00609b;
  box-shadow: 0 0 0 0.2rem rgba(38, 147, 213, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #0080ce;
  border-color: #0080ce;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #00609b;
  border-color: #00588e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 147, 213, 0.5);
}

.btn-secondary {
  color: #343434;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.btn-secondary:hover {
  color: #343434;
  background-color: #d8d8d8;
  border-color: #d2d2d2;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #343434;
  background-color: #d8d8d8;
  border-color: #d2d2d2;
  box-shadow: 0 0 0 0.2rem rgba(208, 208, 208, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #343434;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #343434;
  background-color: #d2d2d2;
  border-color: #cbcbcb;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 208, 208, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #00c092;
  border-color: #00c092;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #009a75;
  border-color: #008d6b;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #009a75;
  border-color: #008d6b;
  box-shadow: 0 0 0 0.2rem rgba(38, 201, 162, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #00c092;
  border-color: #00c092;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #008d6b;
  border-color: #008062;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 201, 162, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #343434;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #343434;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #343434;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(225, 172, 14, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #343434;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #343434;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 172, 14, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #343434;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-light:hover {
  color: #343434;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-light:focus, .btn-light.focus {
  color: #343434;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(225, 225, 225, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #343434;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #343434;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 225, 225, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343434;
  border-color: #343434;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #212121;
  border-color: #1b1b1b;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #212121;
  border-color: #1b1b1b;
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343434;
  border-color: #343434;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1b1b1b;
  border-color: #141414;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
}

.btn-outline-primary {
  color: #0080ce;
  border-color: #0080ce;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #0080ce;
  border-color: #0080ce;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0080ce;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #0080ce;
  border-color: #0080ce;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.5);
}

.btn-outline-secondary {
  color: #ebebeb;
  border-color: #ebebeb;
}
.btn-outline-secondary:hover {
  color: #343434;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 235, 235, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ebebeb;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #343434;
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 235, 235, 0.5);
}

.btn-outline-success {
  color: #00c092;
  border-color: #00c092;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #00c092;
  border-color: #00c092;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 146, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00c092;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00c092;
  border-color: #00c092;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 146, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #343434;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #343434;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-light:hover {
  color: #343434;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #343434;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #343434;
  border-color: #343434;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343434;
  border-color: #343434;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 52, 52, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343434;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343434;
  border-color: #343434;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 52, 52, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0080ce;
  text-decoration: none;
}
.btn-link:hover {
  color: #005082;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 9.5px 17px;
  font-size: 0.9375rem;
  line-height: normal;
  border-radius: 20px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #343434;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f0f2f3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #343434;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #272727;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #0080ce;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #343434;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 26.25px;
  padding-left: 26.25px;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 12.75px;
  padding-left: 12.75px;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f2f3;
  border: 1px solid #ced4da;
  border-radius: 18.5px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #0080ce;
  background-color: #0080ce;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4fbcff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #82cfff;
  border-color: #82cfff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f0f2f3;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0080ce;
  background-color: #0080ce;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 128, 206, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 128, 206, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 128, 206, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 128, 206, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #4fbcff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #f0f2f3;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #4fbcff;
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #f0f2f3;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 18.5px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #f0f2f3;
  border-left: inherit;
  border-radius: 0 18.5px 18.5px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0080ce;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #82cfff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d3d3d3;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0080ce;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #82cfff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d3d3d3;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0080ce;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #82cfff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #d3d3d3;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #d3d3d3;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #d3d3d3;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f0f2f3 #f0f2f3 #d3d3d3;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #d3d3d3 #d3d3d3 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #0080ce;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 2rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 18.5px;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f0f2f3;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0080ce;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
}
.page-link:hover {
  z-index: 2;
  color: #005082;
  text-decoration: none;
  background-color: #f0f2f3;
  border-color: #d3d3d3;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #0080ce;
  border-color: #0080ce;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #d3d3d3;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 6px 0.4em;
  font-size: 70%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.825rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #0080ce;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #00609b;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 206, 0.5);
}

.badge-secondary {
  color: #343434;
  background-color: #ebebeb;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #343434;
  background-color: #d2d2d2;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 235, 235, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #00c092;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #008d6b;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 192, 146, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #343434;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #343434;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #343434;
  background-color: #ffffff;
}
a.badge-light:hover, a.badge-light:focus {
  color: #343434;
  background-color: #e6e6e6;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343434;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1b1b1b;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 52, 52, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f0f2f3;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #00436b;
  background-color: #cce6f5;
  border-color: #b8dbf1;
}
.alert-primary hr {
  border-top-color: #a3d0ed;
}
.alert-primary .alert-link {
  color: #002338;
}

.alert-secondary {
  color: #7a7a7a;
  background-color: #fbfbfb;
  border-color: #f9f9f9;
}
.alert-secondary hr {
  border-top-color: #ececec;
}
.alert-secondary .alert-link {
  color: #616161;
}

.alert-success {
  color: #00644c;
  background-color: #ccf2e9;
  border-color: #b8ede0;
}
.alert-success hr {
  border-top-color: #a4e8d7;
}
.alert-success .alert-link {
  color: #003125;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-light hr {
  border-top-color: #f2f2f2;
}
.alert-light .alert-link {
  color: #6c6c6c;
}

.alert-dark {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6;
}
.alert-dark hr {
  border-top-color: #b9b9b9;
}
.alert-dark .alert-link {
  color: #020202;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #f0f2f3;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #0080ce;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #343434;
  background-color: #f0f2f3;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #0080ce;
  border-color: #0080ce;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #00436b;
  background-color: #b8dbf1;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #00436b;
  background-color: #a3d0ed;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #00436b;
  border-color: #00436b;
}

.list-group-item-secondary {
  color: #7a7a7a;
  background-color: #f9f9f9;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7a7a7a;
  background-color: #ececec;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #7a7a7a;
  border-color: #7a7a7a;
}

.list-group-item-success {
  color: #00644c;
  background-color: #b8ede0;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #00644c;
  background-color: #a4e8d7;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #00644c;
  border-color: #00644c;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #858585;
  background-color: white;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-dark {
  color: #1b1b1b;
  background-color: #c6c6c6;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1b1b;
  background-color: #b9b9b9;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #d3d3d3;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #d3d3d3;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #343434;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0080ce !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00609b !important;
}

.bg-secondary {
  background-color: #ebebeb !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #d2d2d2 !important;
}

.bg-success {
  background-color: #00c092 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #008d6b !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #ffffff !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark {
  background-color: #343434 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1b1b1b !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #d3d3d3 !important;
}

.border-top {
  border-top: 1px solid #d3d3d3 !important;
}

.border-right {
  border-right: 1px solid #d3d3d3 !important;
}

.border-bottom {
  border-bottom: 1px solid #d3d3d3 !important;
}

.border-left {
  border-left: 1px solid #d3d3d3 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0080ce !important;
}

.border-secondary {
  border-color: #ebebeb !important;
}

.border-success {
  border-color: #00c092 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #ffffff !important;
}

.border-dark {
  border-color: #343434 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #0080ce !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #005082 !important;
}

.text-secondary {
  color: #ebebeb !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #c5c5c5 !important;
}

.text-success {
  color: #00c092 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #007458 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #ffffff !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important;
}

.text-dark {
  color: #343434 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0e0e0e !important;
}

.text-body {
  color: #343434 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #ffffff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #d3d3d3 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #d3d3d3;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #d3d3d3;
  }
}
.icon-container {
  display: flex;
  align-items: center;
}
.icon-container .icon {
  margin-right: 7px;
}
.icon-container .left {
  margin-right: 0px;
  margin-left: 7px;
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon.active {
  filter: invert(27%) sepia(92%) saturate(2737%) hue-rotate(185deg) brightness(98%) contrast(101%);
}
.icon.disactive {
  filter: invert(72%) sepia(3%) saturate(6%) hue-rotate(100deg) brightness(87%) contrast(84%);
}

.i-sm {
  width: 12px;
  height: 12px;
}

.i-lg {
  width: 18px;
  height: 18px;
}

.i-xl {
  width: 22px;
  height: 22px;
}

.i-sxxl {
  width: 30px;
  height: 30px;
}

.i-xxl {
  width: 43px;
  height: 42px;
  background-repeat: no-repeat;
}

.i-xxxl {
  width: 80px;
  height: 50px;
  background-repeat: no-repeat;
}

.i-xxxxl {
  width: 80px;
  height: 107px;
  background-repeat: no-repeat;
}

.i-xxxxxl {
  width: 289px;
  height: 170px;
  background-repeat: no-repeat;
}

.i-card-users {
  width: 27px;
  height: 22px;
  background-repeat: no-repeat;
}

.i-card-user-pack {
  width: 20px;
  height: 22px;
  background-repeat: no-repeat;
}

.i-card-pt-pack {
  height: 79px;
  width: 75px;
  background-repeat: no-repeat;
}

.i-card-size {
  height: 25px;
  width: 24px;
  background-repeat: no-repeat;
}

.i-star-empty-size {
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
}

.i-star-size {
  height: 11px;
  width: 11px;
  margin-left: 0.15rem !important;
  background-repeat: no-repeat;
}

.i-book-size {
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
}

.icon-stopwatch {
  background-image: url(../../../img/icons/stopwatch.svg);
}

.icon-stopwatch-alternative {
  background-image: url(../../../img/icons/stopwatch-alternate.svg);
}

.icon-triangle {
  background-image: url(../../../img/icons/Triangle.svg);
}

.icon-triangle-up {
  transform: rotate(180deg);
  background-image: url(../../../img/icons/Triangle.svg);
}

.icon-shopping-basket {
  background-image: url(../../../img/icons/shopping-basket.svg);
}

.icon-1to1 {
  background-image: url(../../../img/icons/1t1.svg);
}

.icon-1to1-grey {
  background-image: url(../../../img/icons/1t1-grey.svg);
}

.icon-1to1-bleu {
  background-image: url(../../../img/icons/PT.svg);
}

.icon-onetoone-white {
  background-image: url(../../../img/icons/wifi-signal-1.svg);
}

.icon-search {
  background-image: url(../../../img/icons/search.svg);
}

.icon-coming-soon {
  background-image: url(../../../img/icons/bientot-01.svg);
}

.icon-iphone-send {
  background-image: url(../../../img/icons/smartphone-sending-data.svg);
}

.icon-nutrition-pack {
  background-image: url(../../../img/icons/nutrition-pack.svg);
}

.icon-nutrition-black {
  background-image: url(../../../img/icons/nutrition-black.svg);
}

.icon-small-group {
  background-image: url(../../../img/icons/small-group.svg);
}

.icon-small-group-white {
  background-image: url(../../../img/icons/small-group-white.svg);
}

.icon-audit-report-survey {
  background-image: url(../../../img/icons/survey.svg);
}

.icon-p-t {
  background-image: url(../../../img/icons/multiple-users-wifi.svg);
}

.icon-cart {
  background-image: url(../../../img/icons/cart.svg);
}

.icon-lock {
  background-image: url(../../../img/icons/lock-2.svg);
}

.icon-single-man {
  background-image: url(../../../img/icons/single-man-actions.svg);
}

.icon-black-wallet {
  background-image: url(../../../img/icons/black-wallet.svg);
}

.icon-credit-card {
  background-image: url(../../../img/icons/credit-card.svg);
}

.icon-accounting-coins-bill {
  background-image: url(../../../img/icons/accounting-coins-bill.svg);
}

.icon-visa {
  width: 30px;
  height: 30px;
  background-image: url(../../../img/icons/visa.svg);
}

.icon-master-card {
  width: 30px;
  height: 30px;
  background-image: url(../../../img/icons/master-card.svg);
}

.icon-trash-checkout {
  cursor: pointer;
  opacity: 1;
  background-image: url(../../../img/icons/trash-checkout.svg);
}

.icon-money-wallet {
  background-image: url(../../../img/icons/money-wallet.svg);
}

.icon-red-cross {
  background-image: url(../../../img/icons/red-cross-01.svg);
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
}

.icon-blue-check {
  background-image: url(../../../img/icons/check.svg);
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.icon-wallet-empty {
  background-image: url(../../../img/icons/wallet-empty.svg);
}

.icon-star {
  background-image: url(../../img/icons/star.svg);
}

.icon-star-gray {
  background-image: url(../../img/icons/star-gray.svg);
}

.icon-money-wallet-blue {
  background-image: url(../../img/icons/money-wallet-blue.svg);
}

.icon-men {
  background-image: url(../../img/icons/people-man-beard-1.svg);
}

.icon-women {
  background-image: url(../../img/icons/single-woman-actions.svg);
}

.icon-user {
  background-image: url(../../img/icons/user.svg);
}

.icon-padlock {
  background-image: url(../../img/icons/padlock.svg);
}

.icon-location {
  background-image: url(../../img/icons/location.svg);
}

.icon-location-blue {
  background-image: url(../../img/icons/location-blue.svg);
  background-position: bottom center;
  width: 22px;
  height: 25px;
}

.icon-phone-blue {
  background-image: url(../../img/icons/v2/phone-blue.svg);
  width: 22px;
  height: 25px;
  background-size: cover;
  background-position: bottom;
}

.icon-phone-gray {
  background-image: url(../../img/icons/v2/phone-gray.svg);
  width: 22px;
  height: 25px;
  background-size: cover;
  background-position: bottom;
}

.icon-ruler {
  background-image: url(../../img/icons/ruler.svg);
}

.icon-weight {
  background-image: url(../../img/icons/weight.svg);
}

.icon-money {
  background-image: url(../../img/icons/money.svg);
}

.icon-imc {
  background-image: url(../../img/icons/v2/imc.svg);
}

.icon-star-empty {
  background-image: url(../../img/icons/star-empty.svg);
}

.icon-star-empty-gray {
  background-image: url(../../img/icons/star-empty-gray.svg);
}

.icon-wifi {
  width: 15px;
  height: 10px;
  background-image: url(../../img/icons/wifi.svg);
}

.icon-wifi-black {
  background-image: url(../../img/icons/wifi-black.svg);
}

.icon-coach {
  background-image: url(../../img/icons/coach.svg);
}

.icon-calendar {
  background-image: url(../../img/icons/calendar.svg);
}

.icon-calendar-white {
  background-image: url(../../img/icons/calendar-white.svg);
}

.icon-calendar-black {
  background-image: url(../../img/icons/calendar-black.svg);
}

.icon-clock {
  opacity: 0.25;
  background-image: url(../../img/icons/clock-black.svg);
}
.icon-clock.clear {
  opacity: 1;
}

.icon-close {
  opacity: 1;
  background-image: url(../../img/icons/close.svg);
}

.icon-foursquare-check-in {
  opacity: 1;
  background-image: url(../../img/icons/foursquare-check-in.svg);
}

.icon-clock-black {
  background-image: url(../../img/icons/clock-black.svg);
}

.icon-clock-white {
  background-image: url(../../img/icons/clock-white.svg);
}

.icon-workout-blue {
  background-image: url(../../img/icons/workout-blue.svg);
}

.icon-workout-white {
  background-image: url(../../img/icons/workout-white.svg);
}

.icon-workout-black {
  background-image: url(../../img/icons/workout-black.svg);
}

.icon-yoga {
  background-image: url(../../img/icons/yoga.svg);
}

.icon-yoga-white {
  background-image: url(../../img/icons/yoga-white.svg);
}

.icon-balance {
  background-image: url(../../img/icons/balance.svg);
}

.icon-white-cn-calendar {
  background-image: url(../../img/icons/cn-calendar.svg);
}

.icon-white-pt-calendar {
  background-image: url(../../img/icons/pt-calendar.svg);
}

.icon-white-sg-calendar {
  background-image: url(../../img/icons/sg-calendar.svg);
}

.icon-flag-tn {
  background-image: url(../../img/icons/tn.png);
}

.icon-monitor {
  background-image: url(../../img/icons/monitor.svg);
}

.icon-monitor-blue {
  background-image: url(../../img/icons/monitor-blue.svg);
}

.icon-yoga-arm {
  background-image: url(../../img/icons/yoga-arm.svg);
}

.icon-yoga-arm-black {
  background-image: url(../../img/icons/yoga-arm-black.svg);
  width: 30px;
  height: 24px;
}

.icon-calendrier {
  background-image: url(../../img/icons/calendrier.svg);
}

.icon-alarm {
  background-image: url(../../img/icons/alarm.svg);
}

.icon-logout {
  background-image: url(../../img/icons/logout.svg);
}

.icon-eye {
  background-image: url(../../images/icons/view-1.svg);
}

.icon-eye-gray {
  background-image: url(../../images/icons/view-gray.svg);
}

.icon-intensity-0 {
  background-image: url(../../images/icons/intensity1.svg);
}

.icon-intensity-1 {
  background-image: url(../../images/icons/intensity1.svg);
}

.icon-intensity-2 {
  background-image: url(../../images/icons/intensity2.svg);
}

.icon-intensity-3 {
  background-image: url(../../images/icons/intensity3.svg);
}

.icon-filter {
  background-image: url(../../images/icons/filter.svg);
}

.icon-up-arrow {
  background-image: url(../../images/icons/up-arrow.svg);
}

.icon-down-arrow {
  background-image: url(../../images/icons/down-arrow.svg);
}

.icon-info-white {
  background-image: url(../../img/icons/info-white-2.svg);
  margin: -1px 0px -7px 1px;
  cursor: pointer;
}

.icon-right {
  position: absolute;
  right: 6px;
}
.icon-right.intensity {
  top: 0;
  right: 0;
}

.icon-users-1x {
  background-image: url(../../images/icons/users1x.svg);
}

.icon-users-5x {
  background-image: url(../../images/icons/one-to-one-5.svg);
}

.icon-plus {
  background-image: url(../../images/icons/plus+.svg);
}

.icon-threedots {
  background-image: url(../../images/icons/threedots.svg);
}

.icon-slider-prev {
  background-image: url(../../img/prev.svg);
}

.icon-slider-next {
  background-image: url(../../img/next.svg);
}

.icon-404 {
  background-image: url(../../img/404.svg);
  width: 100%;
  padding-top: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}

.icon-membership-expired {
  background-image: url(../../img/membership-expired.svg);
  width: 100%;
  padding-top: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}

.icon-shopping-cart-white {
  background-image: url(../../img/icons/shopping-cart-white.svg);
}

.icon-shopping-cart-blue {
  background-image: url(../../img/icons/shopping-cart-blue.svg);
}

.icon-calories {
  background-image: url(../../img/icons/calories.svg);
}

.icon-nutri-dash {
  background-image: url(../../img/icons/nutrition-dark-grey.svg);
}

.icon-nutri-grey {
  background-image: url(../../img/icons/nutrition-grey-session.svg);
}

.icon-arrow-right {
  background-image: url(../../img/icons/right-arrow.svg);
}

.icon-c-n {
  background-image: url(../../../img/icons/nutrition-calendar.svg);
}

.icon-info-solid {
  background-image: url(../../../img/icons/info-circle-solid.svg);
}

.icon-alert-delete {
  background-image: url(../../../img/error_red.svg);
}

.icon-sort-by {
  background-image: url(../../img/icons/v2/sort-by.svg);
}

.icon-goal {
  background-image: url(../../img/icons/v2/goal.svg);
}

.icon-envelope {
  background-image: url(../../img/icons/v2/envelope.svg);
}

.icon-envelope-gray {
  background-image: url(../../img/icons/v2/envelope-gray.svg);
}

.icon-login-gray {
  background-image: url(../../img/icons/v2/login.svg);
}

.icon-envelope-blue {
  background-image: url(../../img/icons/v2/envelope-blue.svg);
}

.icon-password-confirmation {
  background-image: url(../../img/icons/v2/password-confirmation.svg);
}

.icon-coming-soon-2 {
  background-image: url(../../img/icons/v2/coming-soon.svg);
}

.icon-coming-soon-white {
  background-image: url(../../img/icons/v2/coming-soon-white.svg);
}

.icon-info {
  background-image: url(../../img/icons/v2/info.svg);
}

.icon-info-grey {
  background-image: url(../../img/icons/v2/info-grey.svg);
}

.icon-calendar-white-2 {
  background-image: url(../../img/icons/v2/calendar-white.svg);
}

.icon-time-white {
  background-image: url(../../img/icons/v2/time-white.svg);
}

.icon-coach-white {
  background-image: url(../../img/icons/v2/coach-white.svg);
}

.icon-book {
  background-image: url(../../img/icons/v2/book.svg);
}

.icon-book-white {
  background-image: url(../../img/icons/v2/book-white.svg);
}

.icon-star-2 {
  background-image: url(../../img/icons/v2/star.svg);
}

.icon-star-empty-2 {
  background-image: url(../../img/icons/v2/star-empty.svg);
}

.icon-user-gray {
  background-image: url(../../img/icons/v2/user-gray.svg);
}

.icon-board-gray {
  background-image: url(../../img/icons/v2/board-gray.svg);
}

.icon-password-gray {
  background-image: url(../../img/icons/v2/password-gray.svg);
}

.icon-small-group-white-2 {
  background-image: url(../../img/icons/v2/small-group-white.svg);
}

.icon-live {
  background-image: url(../../img/icons/v2/live.svg);
}

.icon-live-gray {
  background-image: url(../../img/icons/v2/live-gray.svg);
}

.icon-live-white {
  background-image: url(../../img/icons/v2/live-white.svg);
}

.icon-vod-white {
  background-image: url(../../img/icons/v2/vod-white.svg);
}

.icon-calories-white {
  background-image: url(../../img/icons/v2/calories-white.svg);
}

.icon-goal-white {
  background-image: url(../../img/icons/v2/goal-white.svg);
}

.icon-body-area-white {
  background-image: url(../../img/icons/v2/body-area-white.svg);
}

.icon-body-area-white-2 {
  background-image: url(../../img/icons/v2/body-area-white-2.svg);
}

.icon-dashboard-blue {
  background-image: url(../../img/icons/v2/dashboard-blue.svg);
}

.icon-session-gray {
  background-image: url(../../img/icons/v2/session-gray.svg);
}

.icon-calendar-gray {
  background-image: url(../../img/icons/v2/calendar-gray.svg);
}

.icon-payment-gray {
  background-image: url(../../img/icons/v2/payment-gray.svg);
}

.icon-nutrition-plan-gray {
  background-image: url(../../img/icons/v2/nutrition-plan-gray.svg);
}

.icon-logout-gray {
  background-image: url(../../img/icons/v2/logout-gray.svg);
}

.icon-eye-2 {
  background-image: url(../../img/icons/v2/eye.svg);
}

.icon-eye-white {
  background-image: url(../../img/icons/v2/eye-white.svg);
}

.icon-date {
  background-image: url(../../img/icons/v2/date.svg);
}

.icon-live-gray-sm {
  background-image: url(../../img/icons/v2/live-gray-sm.svg);
}

.icon-personal-training-gray-sm {
  background-image: url(../../img/icons/v2/personal-training-gray-sm.svg);
}

.icon-small-group-gray-sm {
  background-image: url(../../img/icons/v2/small-group-gray-sm.svg);
}

.icon-next-session {
  background-image: url(../../img/icons/v2/next-session.svg);
}

.icon-id-card {
  background-image: url(../../img/icons/v2/id-card.svg);
}

span.desc-icon {
  align-items: baseline;
  padding-top: 10px;
}

body {
  font-size: 16px;
}
@media (max-width: 991.98px) {
  body {
    font-size: 14px;
  }
}
@media (max-width: 1199.98px) {
  body .container-lg,
body .container-md,
body .container-sm,
body .container {
    max-width: 100% !important;
  }
}
body.header-relative {
  padding-top: 82px;
}

.btn {
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  padding: 0.5rem 1.5625rem;
}

.btn-white {
  color: #343434;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white:hover {
  color: #ffffff;
  background-color: #343434;
  border-color: #343434;
}

.btn-outline-light {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.btn-outline-light:hover {
  color: #004066;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-light {
  color: #0080ce;
}
.btn-light:hover {
  color: #ffffff;
  background-color: #0080ce;
  border-color: #0080ce;
}

.btn-light-slide {
  color: #0080ce;
  background-color: #fff;
  border-color: #0080ce;
}
.btn-light-slide:hover {
  color: #0080ce;
  background-color: #fff;
  border-color: #0080ce;
}

button.btn.btn-primary.mr-2.liveModalSecondSlide {
  color: #0080ce;
  background-color: #fff;
  border-color: #0080ce;
}

.show-price {
  display: none;
}

.number-sess-header {
  max-width: 100% !important;
}

.btn-outline-dark {
  color: #ffffff;
}

.btn-card {
  text-decoration: none !important;
  margin-bottom: 1rem;
}
.btn-card:hover {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.22);
}

.disabledButton {
  pointer-events: none;
  opacity: 0.4;
}

.btn-outline-secondary {
  color: #343434;
}

.badge-white {
  color: #ffffff;
  border: solid 0.8px #ffffff;
}

p {
  font-size: 0.9375rem;
  line-height: 1.87;
}

*:focus {
  outline: none !important;
}

img.avatar {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 5px;
}

.section-icon {
  display: block;
  margin: 1rem auto;
}

.section-title {
  position: relative;
  display: inline-block;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 2.4rem;
  font-weight: bold;
  font-style: italic;
  text-transform: capitalize;
  text-align: left;
  color: #323132;
  padding-bottom: 0.5rem;
  margin-bottom: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.section-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 3px solid #0080ce;
}

.section-msg {
  position: relative;
  display: inline-block;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  text-transform: uppercase;
  text-align: left;
  color: #343434;
  padding-bottom: 0;
  margin-bottom: 0;
}

.section-description {
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;
  text-align: center;
}

.text-none {
  text-transform: none !important;
}

.fc-scroller {
  height: auto !important;
}

ul.numbered-list {
  padding-left: 0;
}
ul.numbered-list li {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 38px;
  margin-bottom: 1rem;
  padding-left: 4rem;
  list-style-type: none;
  counter-increment: li;
}
ul.numbered-list li:before {
  content: counter(li, decimal);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 40px;
  background: url(../../img/icons/rectangle.svg) no-repeat;
  font-family: "Montserrat", sans-serif;
  font-size: 1.0625rem;
  font-weight: 600;
  color: #0080ce;
}

ul.checked-list {
  padding-left: 0;
  list-style: none;
}
ul.checked-list li {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 2.25rem;
}
ul.checked-list li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background: url(../../img/icons/check.svg) no-repeat;
}

ul.tick-list {
  padding-left: 0;
  list-style: none;
}
ul.tick-list li {
  position: relative;
  margin-bottom: 1.5rem;
  padding-left: 2.25rem;
  font-weight: 500;
}
ul.tick-list li:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background: url(../../img/tick-blue.svg) no-repeat;
  background-size: contain;
}

.maxed-list ul {
  position: relative;
}
.maxed-list ul:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 31px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
}
.maxed-list ul.hidden:after {
  display: none;
}
.maxed-list ul li:nth-child(n+5) {
  display: none;
}
.maxed-list .show-more {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #0080ce;
  cursor: pointer;
}

.bg-gradient-1 {
  background-image: linear-gradient(to bottom, #ffffff, #f7f7f7 55%);
}

.back-link {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #343434;
}

.pagination {
  flex-wrap: wrap;
}
.pagination .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  color: #0080ce;
  border: none;
  border-radius: 50%;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 600;
}
.pagination .page-link.active {
  color: #ffffff;
  background-color: #0080ce;
}
.pagination .page-item:first-child .page-link {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.pagination .page-item:last-child .page-link {
  border: solid 1px #0080ce;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.irs--round .irs-handle {
  border: solid 2px #9db1ca;
}

.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single,
.irs--round .irs-bar {
  background-color: #0080ce;
}

.badge {
  margin-right: 0.75rem;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
}

.badge-info {
  color: #0080ce;
  background-color: rgba(0, 128, 206, 0.1);
}

.fix-right {
  right: 0 !important;
  left: auto !important;
  transform: translate3d(-5px, 28px, 0px) !important;
}

.stars {
  display: inline-block;
}
.stars input.star {
  display: none;
}
.stars label.star {
  float: right;
  transition: all 0.2s;
}
.stars label.star:hover {
  transform: rotate(-15deg) scale(1.3);
}
.stars label.star:before {
  content: "";
  display: block;
  background-image: url(../../img/icons/star-empty.svg);
  width: 37px;
  height: 37px;
  margin-right: 4px;
  margin-left: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.stars input.star:checked ~ label.star:before {
  transition: all 0.25s;
  background-image: url(../../img/icons/star.svg);
}
.stars input.star-5:checked ~ label.star:before {
  color: #fe7;
  text-shadow: 0 0 20px #952;
}
.stars input.star-1:checked ~ label.star:before {
  color: #f62;
}

.border-none {
  border: none !important;
}

.btn-link {
  font-style: normal;
  color: #0080ce;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  padding: 5px;
  text-decoration: underline;
}

.badge-pack {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.084599);
  border-radius: 8.5px;
  font-family: AntiqueOliveStd !important;
  font-size: 10px;
  line-height: 12px;
  margin-left: 13px;
  color: #0080ce !important;
  padding: 4px 0.7em !important;
  font-weight: 500 !important;
  width: fit-content;
  height: fit-content;
}

dd.history-text {
  white-space: pre-wrap;
}

.opac-100 {
  opacity: 1 !important;
}

.opac-30 {
  opacity: 0.3 !important;
}

.black-filter {
  filter: grayscale(1) brightness(0.55);
}

.white-filter {
  filter: brightness(0) invert(1);
}

.h-fit-content {
  height: fit-content !important;
}

.jq-toast-wrap.bottom-right {
  bottom: 1px !important;
  right: 7px !important;
}

.coming-soon {
  background-image: url(../../img/coming-soon.png);
  background-repeat: no-repeat;
  height: 280px;
}

.coming-soon-card {
  height: 280px;
}

.next-session-label {
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 16px;
  line-height: 18.62px;
  font-weight: 500;
  position: absolute;
  top: 68px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.coming-soon-label {
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: 25px;
  line-height: 31.22px;
  opacity: 0.6;
  position: absolute;
  top: 14.8rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.next-session-icon {
  position: absolute;
  top: 6rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

header {
  z-index: 99;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s linear;
}
header.is-sticky {
  background-color: #ffffff !important;
}
header.sticky-top {
  position: fixed !important;
}
header .member-ship-badge {
  border-radius: 0px;
  text-align: center;
  font-weight: 500;
}
header .member-ship-badge.alert-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #FFF;
}
header .member-ship-badge.alert-warning {
  background-color: #ff8f00;
  border-color: #ff8f00;
  color: #FFF;
}
header u.buyMembership {
  cursor: pointer;
}
@media (max-width: 1199.98px) {
  header .container-lg,
header .container-md,
header .container-sm,
header .container {
    max-width: 100% !important;
  }
}
header .options-navbar {
  display: flex;
  flex-wrap: nowrap;
}
@media (max-width: 991.98px) {
  header .options-navbar {
    position: absolute;
    right: 0;
    top: 12px;
    min-height: 38px;
  }
  header .options-navbar .dropdown-user {
    display: flex;
    align-items: center;
  }
  header .options-navbar .dropdown-user .logged-user {
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
  }
  header .options-navbar .dropdown-user .logged-user .user-info {
    display: none;
  }
  header .options-navbar .basket-toggle {
    margin-top: 0;
    display: flex;
    align-items: center;
    height: auto;
  }
  header .options-navbar .basket-toggle .dropdown-toggle {
    padding-right: 5px;
    margin-left: 5px;
  }
}
@media (min-width: 992px) {
  header .options-navbar .dropdown-user .dropdown:after {
    content: "";
    position: fixed;
    top: 81px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(52, 52, 52, 0.57);
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }
  header .options-navbar .dropdown-user .dropdown.show:after {
    visibility: visible;
    opacity: 1;
  }
}
header .options-navbar .dropdown-user .dropdown-menu {
  left: auto;
  right: 0;
  top: 100%;
  z-index: 99999;
  min-width: 11rem;
  background: #ffffff;
  box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.293816);
}
header .options-navbar .dropdown-user .dropdown-menu .dropdown-item {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #343434;
  padding: 0.5rem 1rem;
}
header .options-navbar .dropdown-user .dropdown-menu .dropdown-item.active {
  font-weight: 600;
  color: #0080ce;
  background: #ffffff;
}
header .options-navbar .dropdown-user .dropdown-menu .dropdown-item .icon {
  margin-right: 6px;
  position: relative;
  top: 3px;
}
header .navbar {
  padding: 0.5rem 0;
}
header .navbar .navbar-brand .image_full {
  max-width: 195px;
}
header .navbar .navbar-brand .image_mobile {
  max-width: 52px;
}
header .navbar-nav {
  justify-content: flex-end;
}
header .navbar-nav .nav-item {
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
header .navbar-nav .nav-item.btn-login {
  margin-right: 0;
}
@media (max-width: 1199.98px) {
  header .navbar-nav .nav-item {
    margin-right: 1rem;
  }
}
@media (max-width: 991.98px) {
  header .navbar-nav .nav-item {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}
header .navbar-nav .btn-primary {
  padding-left: 15px;
  padding-right: 15px;
}
header .navbar-expand-lg .navbar-collapse {
  min-height: 64px;
}
header .navbar-light .navbar-nav .nav-link {
  color: #343434;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  transition: all 0.3s linear;
}
header .navbar-light .navbar-toggler {
  border: none;
  padding-left: 5px;
  padding-right: 5px;
}
header .no-break {
  white-space: nowrap;
}
header .logged-user {
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 180px;
  margin-left: 0;
  margin-right: 0.75rem;
  padding-right: 1.5rem;
  padding-left: 1rem;
  border-left: solid 1px #dfdfdf;
  text-align: left;
}
header .logged-user:after {
  position: absolute;
  right: 0px;
  top: 8px;
}
header .logged-user .name {
  display: block;
  max-width: 112px;
  margin-bottom: 0.125rem;
  font-size: 0.75rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}
header .logged-user .avatar {
  width: 22px;
  height: 22px;
  margin-right: 12px;
}
@media (min-width: 992px) {
  header .basket-toggle:after {
    content: "";
    position: fixed;
    top: 81px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(52, 52, 52, 0.57);
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }
  header .basket-toggle.show:after {
    visibility: visible;
    opacity: 1;
  }
}
header .basket-toggle .dropdown-toggle {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.25rem;
  padding-left: 5px;
  border-left: solid 1px #dfdfdf;
}
header .basket-toggle .dropdown-toggle:after {
  display: none;
}
header .basket-toggle .badge {
  position: absolute;
  top: 8px;
  left: 22px;
  height: 22px;
  min-width: 22px;
}
header .basket-toggle table {
  margin-bottom: 0;
}
header .basket-toggle td {
  padding: 0.75rem 0.25rem;
  vertical-align: middle;
}
header .basket-toggle .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
header .basket-toggle .header .title {
  font-size: 18px;
  font-weight: bold;
  color: #0080ce;
}
header .basket-toggle .header .btn-link {
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  color: #343434;
}
header .basket-menu {
  max-width: 520px;
  width: calc(100vw - 1rem);
  right: -0.5rem !important;
  left: auto !important;
  top: calc(100% + 0.85rem);
  padding: 1rem;
  box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.29);
  background-color: #ffffff;
  border: none;
}
@media (max-width: 991.98px) {
  header .basket-menu {
    overflow-y: auto;
    max-height: 90vh;
  }
}
header .basket-menu #default-cart {
  text-align: center;
}
header .basket-menu .basket-img {
  width: 80px;
  height: 60px;
  border-radius: 9.2px;
  background-color: #d8d8d8;
  object-fit: cover;
}
header .basket-menu .label {
  font-size: 12px;
  font-weight: 600;
}
header .basket-menu .label.line {
  text-decoration: line-through;
}
header .basket-menu .info {
  padding-left: 0px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(52, 52, 52, 0.5);
}
header .basket-menu .info.msg {
  color: red;
  margin-top: -4px;
  font-size: 10px;
  white-space: nowrap;
}
header .basket-menu .price {
  font-size: 15px;
  font-weight: bold;
  color: #0080ce;
  white-space: nowrap;
}
header .basket-menu .link {
  font-size: 13px;
  font-weight: 500;
  color: #0080ce;
  text-decoration: underline;
}
header .basket-menu .total {
  padding-right: 16px;
  font-size: 19px;
  font-weight: bold;
  text-align: right;
  vertical-align: bottom;
}
header .basket-menu .total-price {
  font-size: 20px;
  font-weight: bold;
  color: #0080ce;
  text-align: left;
}
header .validate-basket {
  border-top: solid 1px #dedede;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
header .validate-basket .btn {
  display: block;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 24px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 13px;
  font-style: italic;
  text-transform: uppercase;
}
header .validate-basket a.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
header .validate-basket a.btn .icon-shopping-cart-white {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
header li.nav-item.active {
  border-bottom: 3px solid #0080ce;
}
header .navbar-light .navbar-nav .show > .nav-link,
header .navbar-light .navbar-nav .active > .nav-link,
header .navbar-light .navbar-nav .nav-link.show,
header .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
  font-weight: bold;
}
@media (max-width: 991.98px) {
  header {
    /*
    .basket-toggle {
        margin-top: 1rem;
        margin-left: 0;
    }
    */
  }
  header .navbar .navbar-brand {
    margin-right: 0;
  }
  header .logged-user,
header .basket-menu {
    border: none;
  }
}

body.new_payment header .alert {
  display: none;
}

.image_full {
  display: block;
}

.image_mobile {
  display: none;
}

@media (max-width: 575.98px) {
  .image_full {
    display: none;
  }

  .image_mobile {
    display: block;
  }
}
.live-course-menu {
  border-bottom: 3px solid #ff0000 !important;
}
.live-course-menu a.nav-link {
  color: #ff0000 !important;
}

.small-group-menu {
  border-bottom: 3px solid #ff8f00 !important;
}
.small-group-menu a.nav-link {
  color: #ff8f00 !important;
}

.personal-training-menu {
  border-bottom: 3px solid #5b5795 !important;
}
.personal-training-menu a.nav-link {
  color: #5b5795 !important;
}

.vod-menu {
  border-bottom: 3px solid #ffc200 !important;
}
.vod-menu a.nav-link {
  color: #ffc200 !important;
}

.nutrition-menu {
  border-bottom: 3px solid #00b915 !important;
}
.nutrition-menu a.nav-link {
  color: #00b915 !important;
}

.toggler-navbar {
  display: none;
}

.dashboard .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 264px;
  height: 100%;
  padding-top: 80px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  min-height: 100vh;
  margin-left: 0;
  z-index: 11;
  transition: margin 0.25s ease-out;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (max-width: 991.98px) {
  .dashboard .sidebar {
    margin-left: -264px;
  }
}
.dashboard .sidebar .unsubscribe {
  color: grey;
  text-decoration: underline;
}
.dashboard .sidebar .user {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.dashboard .sidebar .user .img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
  margin-right: 12px;
}
.dashboard .sidebar .user .link {
  font-size: 13px;
  color: #0080ce;
  float: right;
}
.dashboard .sidebar .name {
  margin-left: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
}
.dashboard .sidebar .age {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-left: 1rem;
  color: rgba(52, 52, 52, 0.385954);
}
.dashboard .sidebar .user-pack {
  display: flex;
  align-items: center;
  padding: 4px;
  border-bottom: solid 1px #efefef;
}
.dashboard .sidebar .user-pack .pack {
  width: 100%;
}
.dashboard .sidebar .user-pack .pack .pack-type {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.dashboard .sidebar .user-pack .pack .link {
  font-size: 16px;
  color: #0080ce;
  cursor: pointer;
  float: right;
}
.dashboard .sidebar .nav {
  font-size: 0.875rem;
  font-weight: 600;
}
.dashboard .sidebar .nav-item {
  margin-bottom: 15px;
}
.dashboard .sidebar .nav-item:first-child {
  margin-top: 15px;
}
.dashboard .sidebar .nav-link {
  display: flex;
  align-items: center;
  color: #343434;
  padding-left: 32px;
}
.dashboard .sidebar .nav-link .icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.dashboard .sidebar .nav-link.active {
  color: #0080ce;
  border-left: 5px solid #0080ce;
}
.dashboard .sidebar .height {
  border-top: 1px solid #eaeaea;
  padding: 14px;
  margin-top: 11px;
  width: 107%;
  height: 70px;
}
.dashboard .sidebar .height .size-user {
  position: relative;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #0080ce;
}
.dashboard .sidebar .height .Weight-user {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #0080ce;
}
.dashboard .sidebar .height .vl {
  border-left: 1px solid #eaeaea;
  height: 70px;
  position: absolute;
  bottom: -16px;
  right: 4px;
}
.dashboard .sidebar .height .pos-triangle {
  position: relative;
  top: 22px;
  left: 9px;
}
.dashboard .sidebar .imc {
  border-top: 1px solid #eaeaea;
  padding: 14px;
  margin-top: 11px;
  width: 107%;
  border-bottom: 1px solid #eaeaea;
  height: 70px;
}
.dashboard .sidebar .imc .size-user {
  position: relative;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #0080ce;
}
.dashboard .sidebar .imc .Weight-user {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #0080ce;
}
.dashboard .sidebar .imc .vl {
  border-left: 1px solid #eaeaea;
  height: 70px;
  position: absolute;
  bottom: -16px;
  right: 4px;
}
.dashboard .sidebar .imc .pos-triangle {
  position: relative;
  top: 22px;
  left: 9px;
}
.dashboard .no-member-ship {
  padding-top: 7rem !important;
}
.dashboard .toggler-navbar {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 2px;
  padding: 0;
  line-height: 10px;
  text-align: center;
  margin-right: 0.5rem;
  display: none;
  transition: all 0.25s ease-out;
}
@media (max-width: 991.98px) {
  .dashboard .toggler-navbar {
    display: block;
  }
}
.dashboard .toggler-navbar .icon {
  transition: all 0.25s ease-out;
}
.dashboard.toggled .toggler-navbar .icon {
  transition: all 0.25s ease-out;
  transform: rotate(0deg);
}
@media (max-width: 991.98px) {
  .dashboard.toggled .toggler-navbar .icon {
    transform: rotate(180deg);
  }
}
.dashboard.toggled .sidebar {
  margin-left: -265px;
}
@media (max-width: 991.98px) {
  .dashboard.toggled .sidebar {
    margin-left: 0;
  }
}
.dashboard.toggled .main {
  margin-left: 0;
}

.chart-weight {
  position: relative;
  bottom: 10px;
}

.label.icon-container {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  white-space: nowrap;
}
.label.icon-container.line {
  text-decoration: line-through;
}
.label.icon-container.msg {
  white-space: nowrap;
  color: red;
}

footer {
  margin-top: 2rem;
  padding-top: 57px;
  padding-bottom: 40px;
  color: #ffffff;
  background-color: #000000;
  font-family: montserrat;
  font-weight: 300;
  overflow: hidden;
}
footer .about p {
  text-align: justify;
  font-size: 15px;
}
footer .about small {
  font-size: 13px;
  font-weight: 700;
}
footer .title {
  margin-bottom: 22px;
  font-size: 18px;
  color: #0080ce;
  text-transform: uppercase;
  font-weight: 500;
}
footer ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
}
footer .available-apps {
  display: inline-flex;
  width: 100%;
}
footer .available-apps img {
  cursor: pointer;
  margin: 2px;
  max-width: 100%;
  height: auto;
}
footer .contact-us {
  display: block;
  padding: 0 0 0 30px;
  height: 30px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  word-wrap: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
footer .contact-us a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
  text-decoration: none;
}
footer .contact-us a:hover {
  color: #0080ce;
}
footer .contact-us a:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 30px;
  width: 30px;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0, 0, 400,400' ml-update='aware'%3E%3Cg%3E%3Cpath d='M74.800 77.626 C 55.028 78.885,40.283 93.022,38.796 112.147 C 38.287 118.691,38.289 281.910,38.798 288.054 C 40.241 305.461,52.999 319.027,70.800 322.085 C 76.233 323.018,325.967 322.823,330.329 321.882 C 348.048 318.060,359.752 305.215,361.200 288.000 C 361.719 281.840,361.720 118.199,361.202 111.946 C 359.776 94.743,347.576 81.534,329.800 77.949 C 327.000 77.384,83.446 77.076,74.800 77.626 M302.845 110.269 C 305.966 111.670,304.928 112.850,287.851 127.318 C 279.463 134.424,260.810 150.224,246.400 162.429 C 231.990 174.634,216.632 187.645,212.272 191.342 C 202.695 199.460,201.930 200.000,200.000 200.000 C 197.948 200.000,198.105 200.122,177.377 182.552 C 151.381 160.519,129.732 142.191,112.800 127.883 C 96.990 114.522,96.000 113.583,96.000 111.939 C 96.000 110.626,96.288 110.422,98.720 110.008 C 101.923 109.464,301.620 109.719,302.845 110.269 M75.750 140.992 C 76.554 141.537,80.674 144.906,84.906 148.477 C 95.111 157.089,125.255 182.551,129.800 186.397 C 131.780 188.072,136.370 191.947,140.000 195.007 C 143.630 198.067,150.650 203.992,155.600 208.175 C 189.323 236.668,185.025 234.316,202.600 233.889 C 215.673 233.572,209.728 237.517,255.800 198.584 C 276.040 181.481,294.040 166.276,295.800 164.796 C 297.560 163.315,304.580 157.387,311.400 151.622 C 326.293 139.033,326.960 138.630,328.488 141.305 C 329.401 142.901,329.222 284.993,328.306 286.668 C 326.123 290.660,335.643 290.384,200.000 290.384 C 64.357 290.384,73.877 290.660,71.694 286.668 C 70.605 284.678,70.620 142.745,71.709 141.082 C 72.603 139.718,73.832 139.690,75.750 140.992 ' stroke='none' fill='%230080ce' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
}
footer .useful-links ul li a {
  color: #ffffff;
  text-decoration: none;
  -moz-opacity: 0.88;
  -webkit-opacity: 0.88;
  filter: alpha(opacity=88);
  opacity: 0.88;
}
footer .useful-links ul li a:hover {
  -moz-opacity: 1;
  -webkit-opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1;
}
footer .social-network {
  /* margin-top: 20px; */
}
footer .social-network ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
footer .social-network ul li {
  margin-right: 5px;
}
footer .social-network ul li .social-network-picture {
  cursor: pointer;
  width: auto;
  height: 40px;
  -moz-opacity: 0.88;
  -webkit-opacity: 0.88;
  filter: alpha(opacity=88);
  opacity: 0.88;
}
footer .social-network ul li .social-network-picture:hover {
  -moz-opacity: 1;
  -webkit-opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1;
}
footer .newsletter .input-group {
  position: relative;
}
footer .newsletter .input-group:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0, 0, 400,400' ml-update='aware'%3E%3Cg%3E%3Cpath d='M198.389 119.615 C 194.066 120.268,189.033 122.481,183.600 126.120 C 174.127 132.464,131.407 166.362,128.279 170.016 C 125.229 173.579,125.400 170.721,125.400 218.200 L 125.400 260.600 126.351 262.703 C 127.434 265.099,129.339 266.941,131.980 268.146 C 134.268 269.189,148.050 269.430,218.200 269.651 C 271.980 269.821,267.976 270.062,271.610 266.428 C 275.110 262.928,274.866 266.094,275.256 219.212 C 275.636 173.622,275.696 175.028,273.212 171.418 C 271.742 169.280,269.699 167.593,227.390 133.560 C 211.191 120.531,206.686 118.364,198.389 119.615 M206.600 131.577 C 209.417 132.863,210.386 133.612,238.600 156.307 C 262.087 175.200,263.709 176.635,264.373 179.100 C 265.304 182.558,264.355 255.733,263.362 257.071 C 261.640 259.390,264.697 259.306,199.521 258.832 C 138.822 258.390,138.418 258.377,137.000 256.804 C 136.060 255.761,135.509 182.109,136.416 178.742 C 137.146 176.031,141.318 172.320,161.800 156.164 C 195.202 129.818,198.628 127.937,206.600 131.577 M241.825 197.009 C 241.008 197.563,238.148 199.780,235.470 201.934 C 209.312 222.974,205.098 225.642,198.932 225.067 C 193.844 224.591,192.417 223.674,171.836 207.647 C 157.411 196.414,157.848 196.720,156.231 196.720 C 154.357 196.720,153.439 197.318,151.635 199.712 C 147.900 204.668,147.082 203.653,167.800 219.775 C 188.177 235.632,191.057 237.190,200.000 237.187 C 209.561 237.184,211.707 235.969,236.368 216.588 C 252.040 204.272,252.508 203.678,249.660 199.746 C 246.905 195.943,244.588 195.134,241.825 197.009 ' stroke='none' fill='%23050505' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
}
footer .newsletter button {
  font-size: 20px;
  line-height: 1em;
  font-weight: 600;
  padding: 0 15px;
  font-family: var(--font-family-monospace);
}
footer .newsletter input[type=email] {
  padding: 6px 12px 6px 40px;
  height: 40px;
  line-height: 40px;
}
footer .footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid #3e3d3d;
  padding-top: 16px;
  font-size: 14px;
  color: #b1b2b2;
  text-transform: uppercase;
}
footer .footer-bottom a {
  color: #717274;
  text-decoration: none;
}

.b-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}
.b-info-container .b-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 0.75rem;
  max-width: 33%;
  width: 100%;
}
.b-info-container .b-info:last-child {
  padding-right: 0rem;
}

.b-info {
  text-align: left;
}
.b-info .label {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(52, 52, 52, 0.4);
}
.b-info .label img.icon {
  display: inline-block;
  width: 19px;
  height: 19px;
  object-fit: contain;
  margin-right: 0.625rem;
}
.b-info .info {
  font-size: 0.8125rem;
  font-weight: 600;
  color: #ffffff;
}
.b-info .info .badge {
  padding: 4px 8px;
  margin-left: 4px;
  font-weight: 600;
  margin-right: 0;
}
.b-info img.avatar {
  display: inline-block;
  width: 30px;
}

.countdown {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  color: #ffffff;
}
.countdown .timer {
  position: relative;
  width: 25%;
  font-size: 1.8rem;
  font-weight: 600;
  font-style: italic;
  text-shadow: 0 0 5px black;
}
.countdown .timer:not(:last-child):after {
  content: ":";
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
}
.countdown .timer.finished {
  opacity: 0.6;
}
.countdown span {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  text-align: center;
}

.video-boxes {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.video-boxes .video-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
  height: 108px;
  margin: 10px;
  border-radius: 13px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  cursor: pointer;
}
.video-boxes .video-box:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 33px;
  height: 33px;
  background: url("../../../img/player.svg") no-repeat;
  background-size: contain;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.video-boxes .video-box .category {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%) translateY(0);
  border-radius: 15px;
  padding: 0.25rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.53);
  font-size: 0.6875rem;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}
.video-boxes .video-box img {
  width: 75px;
  height: 97px;
  object-fit: contain;
}
@media (min-width: 992px) {
  .video-boxes .video-box {
    width: 170px;
    height: 180px;
  }
  .video-boxes .video-box:after {
    width: 55px;
    height: 54px;
  }
  .video-boxes .video-box .category {
    font-size: 15px;
    padding: 4px 16px;
  }
  .video-boxes .video-box img {
    width: 125px;
    height: 162px;
    object-fit: contain;
  }
}

.videos {
  position: relative;
  display: block;
}
.videos .video-link {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  z-index: 1;
  transform: translate(-50%, 15px) scale(0.95);
  transition: 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}
.videos:hover .video-boxes {
  transform: scale(0.95);
  filter: blur(10px);
  opacity: 0.7;
}
.videos:hover .video-link {
  opacity: 1;
  transform: translate(-50%, -7px);
}

ul.price-listing {
  list-style: none;
  padding-left: 0;
  margin-top: 2rem;
}
ul.price-listing li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 1.0625rem;
  font-style: italic;
  text-align: left;
  color: #343434;
}
ul.price-listing li .price {
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: bold;
  text-align: right;
}
ul.price-listing li.member {
  color: #0080ce;
}
ul.price-listing li:not(:last-child) {
  border-bottom: solid 1px #cdcdcd;
}

.b-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #343434;
  margin-bottom: 20px;
}
.b-type .title {
  padding-bottom: 4px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #0080ce;
  border-bottom: solid 5px #343434;
}
.b-type .title .cat-link {
  cursor: pointer;
  text-decoration: none;
}
.b-type .link {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #343434;
}

.b-filters {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.b-filters .dropdown {
  margin-bottom: 0.5rem;
}
.b-filters .dropdown:not(:last-child) {
  margin-right: 1rem;
}
@media (max-width: 991.98px) {
  .b-filters .dropdown {
    width: calc(50% - 10px);
    margin-right: 5px;
    margin-left: 5px;
  }
  .b-filters .dropdown:not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
  .b-filters .dropdown button {
    width: 100%;
  }
  .b-filters .dropdown .dropdown-menu {
    right: 0;
    left: auto;
  }
}
@media (max-width: 575.98px) {
  .b-filters .dropdown {
    width: calc(100% - 10px);
  }
  .b-filters .dropdown button {
    width: 100%;
  }
  .b-filters .dropdown .dropdown-menu {
    width: 100%;
  }
}
.b-filters .dropdown-toggle {
  font-size: 0.875rem;
  font-weight: 500;
}
.b-filters .dropdown-toggle::after {
  display: none;
}
.b-filters .dropdown-menu {
  min-width: 313px;
  padding: 0.5rem;
  margin-top: 0.25rem;
  border: none;
  border-radius: 3px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
}
.b-filters .dropdown-header {
  font-weight: 600;
  color: #343434;
}
.b-filters ul {
  position: relative;
  list-style: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 0;
  max-height: 235px;
  overflow-y: auto;
}
.b-filters ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}
.b-filters .dropdown-more {
  position: relative;
}
.b-filters .dropdown-more:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 11px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
}
.b-filters .form-check-label {
  font-size: 0.875rem;
  font-weight: 500;
}
.b-filters .form-search {
  margin: 0.5rem 1.5rem 1rem;
}
.b-filters .form-search input {
  font-size: 13px;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #cdcdcd;
  background-color: #ffffff;
}
.b-filters .filters-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.b-filters .filters-actions .reset,
.b-filters .filters-actions .submit {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.b-filters .filters-actions .reset {
  color: #8192a1;
}
.b-filters .filters-actions .submit {
  color: #0080ce;
}
.b-filters .avatar {
  width: 19px;
  height: 19px;
}
.b-filters .timeslot {
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.b-filters .timeslot .fromto {
  margin-bottom: 2rem;
  font-size: 0.8125rem;
  font-weight: 500;
}
.b-filters .timeslot .fromto span {
  color: #0080ce;
}
.b-filters.center {
  justify-content: center;
}
.b-filters .icon {
  margin-right: 10px;
  top: 3px;
  position: relative;
}

.filter-items {
  max-height: 500px;
  overflow-y: auto;
}
.filter-items ul {
  max-height: 100%;
}
.filter-items ul li:nth-child(n+5) {
  display: none;
}
.filter-items .show-more {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #0080ce;
  cursor: pointer;
}

.fullwidth-dropdown {
  position: static !important;
}
.fullwidth-dropdown .dropdown-menu {
  width: 100% !important;
}

.choice-list {
  margin-top: 1.5rem;
  list-style: none;
  padding-left: 0;
}
.choice-list .custom-radio {
  width: 100%;
  padding-left: 0;
  margin-bottom: 1rem;
  min-height: 58px;
  border-radius: 7px;
  border: solid 1px #c0cbd2;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 600;
}
.choice-list .custom-radio .custom-control-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px 16px 42px;
}
.choice-list .custom-radio .custom-control-label:before, .choice-list .custom-radio .custom-control-label:after {
  top: calc(50% - 0.5rem);
  left: 16px;
}
.choice-list .custom-radio .custom-control-label .number {
  font-size: 17px;
  font-weight: bold;
  color: #0080ce;
}
.choice-list .custom-radio .custom-control-label .price {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 15px;
  font-style: italic;
  position: relative;
  max-width: calc(50% + 10px);
}
.choice-list .custom-radio .custom-control-label .number-sess {
  max-width: calc(50% - 60px);
}
.choice-list .custom-radio .custom-control-label .badge-info {
  position: absolute;
  left: -60px;
  top: 7px;
}

.count-coaches {
  font-size: 14px;
  font-weight: 600;
  padding: 13px 4px;
}

.sort-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
}
.sort-dropdown .dropdown-toggle {
  display: inline-flex;
  width: 190px;
  padding: 9px 16px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
}
.sort-dropdown .dropdown-toggle:after {
  position: absolute;
  right: 16px;
  top: 12px;
  width: 14px;
  height: 14px;
  background: url(../../../img/down-arrow.svg) no-repeat;
  background-size: contain;
  border: none;
}
.sort-dropdown .dropdown-toggle i {
  margin-right: 8px;
}

.rotate180 {
  transform: rotate(180deg);
}

.cg-badge {
  width: 25px;
  height: 25px;
  display: initial;
  border-radius: 50%;
  position: relative;
  margin-left: 5px;
}
.cg-badge:before {
  content: attr(data-count);
  position: absolute;
  top: 50%;
  left: calc(50% + 13px);
  transform: translate(-50%, -50%);
  color: #ffffff;
  background: #0080ce;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 2px;
}

.coach-img {
  /*
  width: 200px;
  height: 600px;
  transform: scale(1.5);
  */
  object-fit: contain;
  max-width: 100%;
  bottom: -3rem;
  position: relative;
}

p.biography {
  display: block;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
}

.reserver-coach {
  outline: none;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none !important;
  color: #0080ce !important;
}

.reserver-coach:hover {
  color: #ffff !important;
}

.no-dispo {
  font-weight: 600;
}

.radio {
  margin: 0.5rem;
}
.radio input[type=radio] {
  position: absolute;
  opacity: 0;
}
.radio input[type=radio] + .radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 2px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: 0em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type=radio]:checked + .radio-label:before {
  background-color: #3197ee;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}

.pack-selection .number {
  font-size: 17px;
  font-weight: bold;
  color: #0080ce;
}
.pack-selection .price {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--gray-900);
}
.pack-selection .price.right {
  float: right;
}
.pack-selection .price.strikethrough {
  text-decoration: line-through;
  opacity: 0.46;
}

.btn-sort-order {
  width: 63px;
  padding: 9px 16px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
}

.pack {
  position: absolute;
  top: 1px;
  font-size: 1px;
}

.badge {
  margin-right: 0.75rem;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
}

.blink {
  animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.circle {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

/************************/
.home .b-info-container,
.small-group .b-info-container,
.live-courses .b-info-container,
.nutrition .b-info-container,
.personal-training .b-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.home .b-info-container .b-info,
.small-group .b-info-container .b-info,
.live-courses .b-info-container .b-info,
.nutrition .b-info-container .b-info,
.personal-training .b-info-container .b-info {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
  margin-bottom: 0.5rem;
  padding-right: 0;
}
.home .b-info-container .b-info.last-child,
.small-group .b-info-container .b-info.last-child,
.live-courses .b-info-container .b-info.last-child,
.nutrition .b-info-container .b-info.last-child,
.personal-training .b-info-container .b-info.last-child {
  margin-bottom: 0rem;
  padding: 0;
  width: 100%;
}
.home .b-info-container .b-info.last-child .label,
.small-group .b-info-container .b-info.last-child .label,
.live-courses .b-info-container .b-info.last-child .label,
.nutrition .b-info-container .b-info.last-child .label,
.personal-training .b-info-container .b-info.last-child .label {
  margin-bottom: 0.5rem;
}
.home .b-info-container .b-info:nth-child(even),
.small-group .b-info-container .b-info:nth-child(even),
.live-courses .b-info-container .b-info:nth-child(even),
.nutrition .b-info-container .b-info:nth-child(even),
.personal-training .b-info-container .b-info:nth-child(even) {
  padding-left: 2rem;
}
.home .b-info-container .b-info .label,
.small-group .b-info-container .b-info .label,
.live-courses .b-info-container .b-info .label,
.nutrition .b-info-container .b-info .label,
.personal-training .b-info-container .b-info .label {
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 0.25rem;
  font-weight: 300;
  color: white;
}
.home .b-info-container .b-info .label img.icon,
.small-group .b-info-container .b-info .label img.icon,
.live-courses .b-info-container .b-info .label img.icon,
.nutrition .b-info-container .b-info .label img.icon,
.personal-training .b-info-container .b-info .label img.icon {
  display: inline-block;
  width: 19px;
  height: 19px;
  object-fit: contain;
  margin-right: 0.625rem;
}
.home .b-info-container .b-info .info,
.small-group .b-info-container .b-info .info,
.live-courses .b-info-container .b-info .info,
.nutrition .b-info-container .b-info .info,
.personal-training .b-info-container .b-info .info {
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  font-style: italic;
  height: 48px;
}
.home .b-info-container .b-info .info .badge,
.small-group .b-info-container .b-info .info .badge,
.live-courses .b-info-container .b-info .info .badge,
.nutrition .b-info-container .b-info .info .badge,
.personal-training .b-info-container .b-info .info .badge {
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 8px;
  margin-left: 15px;
  margin-right: 0;
}
.home .b-info-container .b-info .info .badge i,
.small-group .b-info-container .b-info .info .badge i,
.live-courses .b-info-container .b-info .info .badge i,
.nutrition .b-info-container .b-info .info .badge i,
.personal-training .b-info-container .b-info .info .badge i {
  margin-right: 0.5rem;
}
.home .b-info-container .b-info .info.flex-between,
.small-group .b-info-container .b-info .info.flex-between,
.live-courses .b-info-container .b-info .info.flex-between,
.nutrition .b-info-container .b-info .info.flex-between,
.personal-training .b-info-container .b-info .info.flex-between {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: flex-start;
  justify-content: space-between;
}
.home .b-info-container .b-info .info.flex-around,
.small-group .b-info-container .b-info .info.flex-around,
.live-courses .b-info-container .b-info .info.flex-around,
.nutrition .b-info-container .b-info .info.flex-around,
.personal-training .b-info-container .b-info .info.flex-around {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: flex-start;
  justify-content: space-around;
}
.home .b-info-container .b-info img.avatar,
.small-group .b-info-container .b-info img.avatar,
.live-courses .b-info-container .b-info img.avatar,
.nutrition .b-info-container .b-info img.avatar,
.personal-training .b-info-container .b-info img.avatar {
  display: inline-block;
  width: 30px;
}
.home ul.price-listing li,
.small-group ul.price-listing li,
.live-courses ul.price-listing li,
.nutrition ul.price-listing li,
.personal-training ul.price-listing li {
  color: #ffffff;
}

.home-slider.owl-carousel {
  position: relative;
}
.home-slider.owl-carousel .owl-item:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
}
.home-slider.owl-carousel .owl-item .item {
  position: relative;
  overflow: hidden;
  height: 520px;
}
.home-slider.owl-carousel .owl-item .item .slider-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 80% 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.home-slider.owl-carousel .owl-item .item .content {
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2;
  color: #ffffff;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item .content {
    justify-content: left;
  }
}
.home-slider.owl-carousel .owl-item .item .content .info-panel {
  max-width: 720px;
  height: 100%;
  width: 100%;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel {
  clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
  background-color: #007ecea8;
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel {
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
    padding-left: 2%;
  }
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel {
  padding: 125px 0 60px;
  height: 100%;
  position: relative;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .badge {
  opacity: 0.54;
  display: inline-block;
  position: absolute;
  left: 22%;
  top: 90px;
  margin: 0;
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .badge {
    left: 0;
  }
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .date {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  opacity: 0.54;
  position: absolute;
  justify-content: flex-end;
  width: auto;
  top: 90px;
  right: 10%;
  margin: 0;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .date span:not(:last-child) {
  margin-right: 20px;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .title {
  margin-bottom: 17px;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  width: 100%;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .description {
  width: 100%;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel > p {
  height: 55%;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .hovered {
  position: absolute;
  bottom: 90px;
  width: 100%;
  padding: 0 20% 0 8%;
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .hovered {
    padding-left: 0;
  }
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .hovered .countdown {
  display: flex;
  justify-content: center;
  margin: 0;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .hovered .countdown .timer {
  font-size: 1.5rem;
  width: 50px;
  margin-right: 25px;
  text-align: center;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .hovered .countdown .timer:after {
  right: -12px;
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .hovered .circle {
  position: relative;
  left: -25px;
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .hovered .circle {
    left: -7px;
  }
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .slider-action {
  position: absolute;
  width: 80%;
  left: 1%;
  bottom: 40px;
  text-align: center;
  height: 40px;
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-panel .slider-action {
    left: 0;
  }
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-left {
  display: block;
  width: 22%;
  height: 100%;
  float: left;
  shape-outside: polygon(0 0, 100% 0, 10% 100%, 0 100%);
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-left {
    display: none;
  }
}
.home-slider.owl-carousel .owl-item .item .content .info-panel.content-panel .skewed-right {
  display: block;
  float: right;
  width: 22%;
  height: 100%;
  shape-outside: polygon(90% 0, 100% 0, 100% 100%, 0 100%);
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item .content .info-panel.empty-panel {
    display: none;
  }
}
.home-slider.owl-carousel .owl-item .item.grand-public .content .info-panel.content-panel {
  background-color: #000000a8;
}
.home-slider.owl-carousel .owl-item .item.page-slide .content .info-panel.content-panel {
  background-color: #00558aa8;
}
.home-slider.owl-carousel .owl-item .item.link-slide:before {
  content: "";
  position: absolute;
  top: 0;
  left: -70px;
  width: 22%;
  height: 520px;
  transform: skew(-15deg, 0);
  z-index: 1;
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item.link-slide:before {
    display: none;
  }
}
.home-slider.owl-carousel .owl-item .item.link-slide:after {
  content: "";
  position: absolute;
  top: 0;
  left: calc(22% - 70px);
  width: 30%;
  height: 520px;
  opacity: 0.79;
  background-color: #333132;
  transform: skew(-15deg, 0);
  z-index: 0;
}
@media (max-width: 991.98px) {
  .home-slider.owl-carousel .owl-item .item.link-slide:after {
    left: -20%;
    width: 70%;
  }
}
.home-slider.owl-carousel.owl-theme .owl-nav {
  display: none;
}
@media (min-width: 992px) {
  .home-slider.owl-carousel.owl-theme .owl-nav {
    position: absolute;
    bottom: 33px;
    right: 43px;
    display: block;
  }
}
.home-slider.owl-carousel.owl-theme .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
}
.home-slider.owl-carousel .owl-nav .owl-prev.disabled,
.home-slider.owl-carousel .owl-nav .owl-next.disabled {
  display: none;
}

.owl-carousel:not(.home-slider) .owl-stage-outer {
  padding-bottom: 36px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #0080ce;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  border-radius: 50%;
}

.home-slider.owl-theme .owl-dots .owl-dot.active span,
.home-slider.owl-theme .owl-dots .owl-dot:hover span {
  background: #ffffff;
}

.home-slider.owl-theme .owl-dots .owl-dot:focus,
.home-slider.owl-carousel .owl-nav button.owl-prev:focus,
.home-slider.owl-carousel .owl-nav button.owl-next:focus {
  outline: none;
}

.live-courses-slider .caption {
  text-align: center;
}
.live-courses-slider .owl-item .item {
  width: 317px;
  margin: auto;
}

.small-group-slider .caption {
  text-align: center;
}
.small-group-slider .owl-item .item {
  width: 317px;
  margin: auto;
}

.planing-live-corse {
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .planing-live-corse .owl-stage-outer {
    padding: 16px 20px;
  }
}
.planing-live-corse.owl-theme .owl-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.planing-live-corse.owl-theme .owl-dots .owl-dot.active span,
.planing-live-corse.owl-theme .owl-dots .owl-dot:hover span {
  background: #0080ce;
}
@media (min-width: 992px) {
  .planing-live-corse.owl-theme {
    display: block;
  }
  .planing-live-corse.owl-theme .disabled {
    display: none;
  }
}
.planing-live-corse .owl-nav button.owl-prev {
  position: absolute;
  top: 41%;
  left: -40px;
}
.planing-live-corse .owl-nav button.owl-next {
  position: absolute;
  top: 41%;
  right: -40px;
}

.coach-info {
  position: relative;
  width: auto;
  max-width: 375px;
  height: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  color: #ffffff;
}
.coach-info .info {
  display: none;
}
.coach-info .info .title {
  position: relative;
  margin-bottom: 30px;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  color: #00416a;
}
.coach-info .info .title strong {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 32px;
}
.coach-info .info .title .votes {
  line-height: 1;
}
.coach-info .info p.biography {
  -webkit-line-clamp: 12;
}
.coach-info .info a.reserver-coach {
  color: #101010 !important;
}
.coach-info .info a:hover.reserver-coach {
  color: #101010 !important;
}
.coach-info .info ul {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
}
.coach-info .info ul li {
  margin-bottom: 8px;
}
.coach-info .info p {
  font-size: 14px;
  line-height: normal;
  margin-top: 8px;
}

.personal-training-slider {
  position: relative;
}
.personal-training-slider:before {
  content: "";
  position: absolute;
  top: 60px;
  left: 50%;
  width: 375px;
  height: 494px;
  border-radius: 25px;
  box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.2);
  background: linear-gradient(142deg, #0083ca 1%, #005286);
  transform: translateX(-50%);
}
@media (max-width: 991.98px) {
  .personal-training-slider:before {
    width: 96%;
  }
}
.personal-training-slider .owl-item .coach-img {
  width: 235px;
  height: 600px;
  object-fit: contain;
  z-index: 2;
  transform: scale(0.5);
  opacity: 0.2;
  filter: grayscale(0.5);
  transform: scale(0.5) translateX(50%);
  transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.personal-training-slider .owl-item.medium .coach-img {
  transform: scale(0.75);
  opacity: 0.45;
  filter: grayscale(0.5);
}
.personal-training-slider .owl-item.large .coach-img {
  position: absolute;
  top: -50px;
  right: 50%;
  transform: scale(1.17);
  filter: grayscale(0);
  opacity: 1;
}
.personal-training-slider .owl-item.active.center {
  transform: scale(1);
}
.personal-training-slider .owl-item.active.center .coach-info {
  width: 375px;
  padding: 32px 145px 32px 32px;
}
@media (max-width: 991.98px) {
  .personal-training-slider .owl-item.active.center .coach-info {
    padding: 13px 139px 36px 0px;
    width: 96%;
  }
}
.personal-training-slider .owl-item.active.center .coach-info .info {
  display: block;
  text-align: left;
}
.personal-training-slider .owl-item.active.center img.coach-img {
  right: -90px;
}
.personal-training-slider .owl-item {
  text-align: center;
}
.personal-training-slider .owl-item .coach-info {
  display: inline-block;
}
.personal-training-slider.owl-theme .owl-nav {
  display: block;
}

@media (min-width: 992px) {
  .personal-training-slider .owl-nav button.owl-prev {
    position: absolute;
    top: 275px;
    left: calc(50% - 270px);
  }
  .personal-training-slider .owl-nav button.owl-next {
    position: absolute;
    top: 275px;
    right: calc(50% - 300px);
  }
}
@media (max-width: 991.98px) {
  .personal-training-slider .owl-item.active.center img.coach-img {
    right: 0;
    top: 21px;
    width: 146px;
    height: 464px;
  }
  .personal-training-slider .owl-nav button.owl-prev {
    position: absolute;
    bottom: 80px;
    left: 50%;
    margin-left: -18.5px;
    transform: translateX(-25px);
  }
  .personal-training-slider .owl-nav button.owl-next {
    position: absolute;
    bottom: 80px;
    left: 50%;
    margin-left: -18.5px;
    transform: translateX(25px);
  }
}
.videos-slider.owl-theme .owl-nav {
  display: none;
}
@media (min-width: 1100px) {
  .videos-slider.owl-theme .owl-nav {
    display: block;
  }
}
.videos-slider .owl-nav button.owl-prev {
  position: absolute;
  top: 62px;
  left: -40px;
  width: 20px;
}
.videos-slider .owl-nav button.owl-next {
  position: absolute;
  top: 62px;
  right: -40px;
  width: 20px;
}
.videos-slider .owl-nav button.owl-next:hover {
  background: transparent;
}
.videos-slider .owl-nav .owl-prev.disabled,
.videos-slider .owl-nav .owl-next.disabled {
  display: none;
}
.videos-slider .owl-nav button.owl-prev {
  position: absolute;
  top: 62px;
  right: -40px;
  width: 20px;
}
.videos-slider .owl-nav button.owl-prev:hover {
  background: transparent;
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .slider-live {
    margin-left: 11rem;
  }
}
@media (min-width: 1401px) and (max-width: 1600px) {
  .slider-live {
    margin-left: 6.9rem;
  }
}
@media (min-width: 992px) and (max-width: 1366px) {
  .slider-live {
    margin-left: 11.3333333333%;
  }
}
.card {
  padding: 0;
  margin-bottom: 1rem;
  border: none;
  border-radius: 1.25rem;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}
.card .card-img {
  height: 195px;
  object-fit: cover;
  border-radius: 13px;
  transition: all 0.3s ease-in-out;
}
.card .card-body {
  padding: 0;
}

.course-card {
  max-width: 317px;
  width: 100%;
  margin: 0 auto 3.125rem;
}
.course-card .card-top {
  position: relative;
  border-radius: 0.8125rem;
  overflow: hidden;
}
.course-card .hovered {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.course-card .hovered:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 0.8125rem;
  background-color: rgba(0, 128, 206, 0.6);
}
.course-card .hovered .content {
  width: 100%;
  font-style: italic;
  color: #ffffff;
  z-index: 1;
}
.course-card.active .card-img {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.course-card.active .hovered {
  opacity: 1;
}
.course-card .card-title {
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 7px;
  margin-top: 1rem;
  border-radius: 1.3125rem;
  background-color: rgba(240, 242, 243, 0.6);
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  color: #0080ce;
}
.course-card .media .badge {
  padding: 4px 8px;
  margin-top: 6px;
  font-weight: 600;
}

.group-card {
  margin: 0.125rem auto;
}
@media (min-width: 992px) {
  .group-card {
    margin: 0.125rem 0;
  }
}
.group-card .card-top {
  position: relative;
  border-radius: 0.8125rem;
  overflow: hidden;
}
.group-card .card-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, #000000 84%);
  border-radius: 13px;
}
.group-card .card-title {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  color: #ffffff;
}
.group-card .participants {
  display: flex;
  align-items: center;
  min-height: 54px;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-radius: 21px;
  background-color: #f0f2f3;
  padding: 0.375rem 1.3125rem;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  font-style: italic;
  color: #0080ce;
}
.group-card .participants .number {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
}
.group-card .participants .badge-danger {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-style: italic;
  color: #ffffff;
}
.group-card .avatars .avatar {
  display: inline-block;
  width: 30px;
  margin-left: -12px;
  border: 2px solid #ffffff;
}
.group-card .avatars .more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: -12px;
  color: #343434;
  border: solid 2px #ffffff;
  background-color: #c4d3dc;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
}
.group-card .hovered {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.group-card .hovered:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 0.8125rem;
  background-color: rgba(0, 128, 206, 0.6);
  border-radius: 0;
}
.group-card .hovered .content {
  width: 100%;
  font-style: italic;
  color: #ffffff;
  z-index: 1;
}
.group-card.active .card-img {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.group-card.active .hovered {
  opacity: 1;
}
.group-card .card-title-details {
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 7px;
  margin-top: 1rem;
  border-radius: 1.3125rem;
  background-color: rgba(240, 242, 243, 0.6);
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  color: #0080ce;
}

.group-card.detailed {
  height: auto;
  margin-bottom: 2rem;
}
.group-card.detailed .card-title {
  margin-bottom: 0;
}
.group-card.detailed .card-title .coach {
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
}
.group-card.detailed .card-title .coach .avatar {
  border: none;
  width: 24px;
  height: 24px;
}
.group-card.detailed .card-title .coach .badge {
  font-weight: 600;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.35);
}
.group-card.detailed .b-info-container {
  align-items: stretch;
}
.group-card.detailed .b-info {
  margin-right: 0;
  max-width: 50%;
  margin-bottom: 0.5rem;
}
.group-card.detailed .b-info .info {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0.25rem;
}
.group-card.detailed .b-info .info.wt-13 {
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-card.detailed .b-info .zone {
  margin-bottom: 0.25rem;
  width: 36px;
  height: 36px;
  object-fit: contain;
}
.group-card.detailed ul.price-listing {
  margin-top: 0;
  padding: 1.125rem 1.25rem 0;
  border-top: solid 1px #e5e5e5;
}
.group-card.detailed ul.price-listing li {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.group-card.detailed .btn {
  padding: 0.75rem 2rem;
  margin-bottom: 0.5rem;
  border-radius: 1.5rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  text-transform: uppercase;
}

.group-card.pinned {
  width: 100%;
  max-width: 370px;
  margin-left: auto;
  padding: 1.5rem;
  height: inherit;
  margin-bottom: 2rem;
}
.group-card.pinned .title {
  justify-content: center;
  padding-bottom: 0.875rem;
  margin-bottom: 0.25rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  color: #343434;
  border-bottom: solid 3px #0080ce;
}
.group-card.pinned .participants {
  background: #ffffff;
  margin-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
}
.group-card.pinned .price-listing {
  margin-top: 1.25rem;
  margin-bottom: 0;
}
.group-card.pinned .btn {
  display: block;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  width: 70%;
  margin: 0.25rem auto;
  border-radius: 1.5rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.8125rem;
  font-style: italic;
  text-transform: uppercase;
}
.group-card.pinned .progress {
  overflow: visible;
}

.coach-card {
  width: 270px;
  min-height: 170px;
  padding: 0;
  margin: 16px;
}
.coach-card .card-img-top {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}
.coach-card img.card-img-top {
  height: 170px;
  object-fit: cover;
}
.coach-card .card-body {
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 16px;
  font-style: italic;
  padding: 1rem;
  text-align: center;
}
.coach-card .card-body .price {
  margin-left: 8px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 18px;
  color: #0080ce;
}
.coach-card .card-title {
  display: block;
  padding: 0.8125rem;
  font-size: 0.875rem;
  font-style: italic;
  text-transform: uppercase;
  border-radius: 1.3125rem;
  background-color: #f0f2f3;
}
.coach-card .i-icon {
  display: inline-block;
}
.coach-card .i-icon img {
  width: 30px;
}
.coach-card .btn {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  text-transform: uppercase;
}

.owl-carousel .owl-item img.info-icon {
  width: 30px;
}

img.info-icon {
  width: 30px;
}

.jscroll-inner {
  margin: 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

img.miniature.i-xl {
  width: 25px;
  margin-right: 0.2rem;
}

/**********************/
.small-group #containerData {
  justify-content: space-around;
  margin: 0;
}
.small-group .small-group-card-container {
  max-width: 350px;
  padding: 0 15px;
}

.home .card,
.small-group .card,
.live-courses .card,
.nutrition .card,
.personal-training .card {
  margin-bottom: 0;
  border: none;
  border-radius: 1.5rem;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.12);
  background-color: #1f1f1f;
  color: #ffffff;
  overflow: hidden;
}
.home .card .card-img,
.small-group .card .card-img,
.live-courses .card .card-img,
.nutrition .card .card-img,
.personal-training .card .card-img {
  height: 200px;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
}
.home .card .card-body,
.small-group .card .card-body,
.live-courses .card .card-body,
.nutrition .card .card-body,
.personal-training .card .card-body {
  padding: 0.75rem;
}
.home .card.group-card .card-top,
.small-group .card.group-card .card-top,
.live-courses .card.group-card .card-top,
.nutrition .card.group-card .card-top,
.personal-training .card.group-card .card-top {
  position: relative;
  border-radius: 0;
  overflow: hidden;
}
.home .card.group-card .card-top:before,
.small-group .card.group-card .card-top:before,
.live-courses .card.group-card .card-top:before,
.nutrition .card.group-card .card-top:before,
.personal-training .card.group-card .card-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, #000000 84%);
  border-radius: 0;
}
.home .card.group-card .card-title,
.small-group .card.group-card .card-title,
.live-courses .card.group-card .card-title,
.nutrition .card.group-card .card-title,
.personal-training .card.group-card .card-title {
  position: absolute;
  bottom: 0;
  left: 1rem;
  margin-bottom: 0.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  color: #ffffff;
}
.home .card.group-card .card-title img,
.small-group .card.group-card .card-title img,
.live-courses .card.group-card .card-title img,
.nutrition .card.group-card .card-title img,
.personal-training .card.group-card .card-title img {
  width: 22px;
}
.home .card.group-card .participants,
.small-group .card.group-card .participants,
.live-courses .card.group-card .participants,
.nutrition .card.group-card .participants,
.personal-training .card.group-card .participants {
  display: flex;
  align-items: center;
  min-height: 54px;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-radius: 10px;
  background-color: #0085cd;
  padding: 0.75rem 1.3125rem;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  font-style: italic;
  color: #ffffff;
}
.home .card.group-card .participants .number,
.small-group .card.group-card .participants .number,
.live-courses .card.group-card .participants .number,
.nutrition .card.group-card .participants .number,
.personal-training .card.group-card .participants .number {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
}
.home .card.group-card .participants .badge-danger,
.small-group .card.group-card .participants .badge-danger,
.live-courses .card.group-card .participants .badge-danger,
.nutrition .card.group-card .participants .badge-danger,
.personal-training .card.group-card .participants .badge-danger {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-style: italic;
  color: #ffffff;
}
.home .card.group-card .avatars .avatar,
.small-group .card.group-card .avatars .avatar,
.live-courses .card.group-card .avatars .avatar,
.nutrition .card.group-card .avatars .avatar,
.personal-training .card.group-card .avatars .avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: -12px;
  position: relative;
  border-radius: 20px;
  border: 2px solid #ffffff;
}
.home .card.group-card .avatars .more,
.small-group .card.group-card .avatars .more,
.live-courses .card.group-card .avatars .more,
.nutrition .card.group-card .avatars .more,
.personal-training .card.group-card .avatars .more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: -12px;
  color: #343434;
  border: solid 2px #ffffff;
  background-color: #c4d3dc;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
}
.home .card.group-card .hovered,
.small-group .card.group-card .hovered,
.live-courses .card.group-card .hovered,
.nutrition .card.group-card .hovered,
.personal-training .card.group-card .hovered {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.home .card.group-card .hovered:after,
.small-group .card.group-card .hovered:after,
.live-courses .card.group-card .hovered:after,
.nutrition .card.group-card .hovered:after,
.personal-training .card.group-card .hovered:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0080ce;
}
.home .card.group-card .hovered .content,
.small-group .card.group-card .hovered .content,
.live-courses .card.group-card .hovered .content,
.nutrition .card.group-card .hovered .content,
.personal-training .card.group-card .hovered .content {
  width: 100%;
  font-style: italic;
  color: #ffffff;
  z-index: 1;
}
.home .card.group-card.active .card-img,
.small-group .card.group-card.active .card-img,
.live-courses .card.group-card.active .card-img,
.nutrition .card.group-card.active .card-img,
.personal-training .card.group-card.active .card-img {
  filter: blur(10px);
}
.home .card.group-card.active .hovered,
.small-group .card.group-card.active .hovered,
.live-courses .card.group-card.active .hovered,
.nutrition .card.group-card.active .hovered,
.personal-training .card.group-card.active .hovered {
  opacity: 1;
}
.home .card.group-card .card-title-details,
.small-group .card.group-card .card-title-details,
.live-courses .card.group-card .card-title-details,
.nutrition .card.group-card .card-title-details,
.personal-training .card.group-card .card-title-details {
  justify-content: center;
  border-radius: 1.3125rem;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 1.75rem;
  font-style: italic;
  color: #0080ce;
}
.home .course-card,
.small-group .course-card,
.live-courses .course-card,
.nutrition .course-card,
.personal-training .course-card {
  max-width: 317px;
  width: 100%;
}
.home .course-card .card-top,
.small-group .course-card .card-top,
.live-courses .course-card .card-top,
.nutrition .course-card .card-top,
.personal-training .course-card .card-top {
  position: relative;
  border-radius: 0;
  overflow: hidden;
}
.home .course-card .hovered,
.small-group .course-card .hovered,
.live-courses .course-card .hovered,
.nutrition .course-card .hovered,
.personal-training .course-card .hovered {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.home .course-card .hovered:after,
.small-group .course-card .hovered:after,
.live-courses .course-card .hovered:after,
.nutrition .course-card .hovered:after,
.personal-training .course-card .hovered:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: #0080ce;
}
.home .course-card .hovered .content,
.small-group .course-card .hovered .content,
.live-courses .course-card .hovered .content,
.nutrition .course-card .hovered .content,
.personal-training .course-card .hovered .content {
  width: 100%;
  font-style: italic;
  color: #ffffff;
  z-index: 1;
}
.home .course-card .hovered .content .countdown-live-title,
.small-group .course-card .hovered .content .countdown-live-title,
.live-courses .course-card .hovered .content .countdown-live-title,
.nutrition .course-card .hovered .content .countdown-live-title,
.personal-training .course-card .hovered .content .countdown-live-title {
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 1.2rem;
}
.home .course-card.active .card-img,
.small-group .course-card.active .card-img,
.live-courses .course-card.active .card-img,
.nutrition .course-card.active .card-img,
.personal-training .course-card.active .card-img {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.home .course-card.active .hovered,
.small-group .course-card.active .hovered,
.live-courses .course-card.active .hovered,
.nutrition .course-card.active .hovered,
.personal-training .course-card.active .hovered {
  opacity: 1;
}
.home .course-card .card-title,
.small-group .course-card .card-title,
.live-courses .course-card .card-title,
.nutrition .course-card .card-title,
.personal-training .course-card .card-title {
  justify-content: center;
  border-radius: 1.3125rem;
  background-color: #1f1f1f;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-style: italic;
  color: #ffffff;
  padding: 0;
  margin-top: 0;
  font-size: 1.75rem;
  font-weight: 700;
}
.home .course-card .media .badge,
.small-group .course-card .media .badge,
.live-courses .course-card .media .badge,
.nutrition .course-card .media .badge,
.personal-training .course-card .media .badge {
  padding: 4px 8px;
  margin-top: 6px;
  font-weight: 600;
}
.home .group-card.detailed,
.small-group .group-card.detailed,
.live-courses .group-card.detailed,
.nutrition .group-card.detailed,
.personal-training .group-card.detailed {
  height: auto;
  margin-bottom: 2rem;
}
.home .group-card.detailed .card-title .coach,
.small-group .group-card.detailed .card-title .coach,
.live-courses .group-card.detailed .card-title .coach,
.nutrition .group-card.detailed .card-title .coach,
.personal-training .group-card.detailed .card-title .coach {
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
}
.home .group-card.detailed .card-title .coach .avatar,
.small-group .group-card.detailed .card-title .coach .avatar,
.live-courses .group-card.detailed .card-title .coach .avatar,
.nutrition .group-card.detailed .card-title .coach .avatar,
.personal-training .group-card.detailed .card-title .coach .avatar {
  border: none;
  width: 24px;
  height: 24px;
}
.home .group-card.detailed .card-title .coach .badge,
.small-group .group-card.detailed .card-title .coach .badge,
.live-courses .group-card.detailed .card-title .coach .badge,
.nutrition .group-card.detailed .card-title .coach .badge,
.personal-training .group-card.detailed .card-title .coach .badge {
  font-weight: 600;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.35);
}
.home .group-card.detailed .b-info,
.small-group .group-card.detailed .b-info,
.live-courses .group-card.detailed .b-info,
.nutrition .group-card.detailed .b-info,
.personal-training .group-card.detailed .b-info {
  margin-right: 0;
  margin-bottom: 0.5rem;
}
.home .group-card.detailed .b-info .info,
.small-group .group-card.detailed .b-info .info,
.live-courses .group-card.detailed .b-info .info,
.nutrition .group-card.detailed .b-info .info,
.personal-training .group-card.detailed .b-info .info {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0.25rem;
}
.home .group-card.detailed .b-info .info.wt-13,
.small-group .group-card.detailed .b-info .info.wt-13,
.live-courses .group-card.detailed .b-info .info.wt-13,
.nutrition .group-card.detailed .b-info .info.wt-13,
.personal-training .group-card.detailed .b-info .info.wt-13 {
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home .group-card.detailed .b-info .info strong,
.small-group .group-card.detailed .b-info .info strong,
.live-courses .group-card.detailed .b-info .info strong,
.nutrition .group-card.detailed .b-info .info strong,
.personal-training .group-card.detailed .b-info .info strong {
  white-space: nowrap;
}
.home .group-card.detailed .b-info .zone,
.small-group .group-card.detailed .b-info .zone,
.live-courses .group-card.detailed .b-info .zone,
.nutrition .group-card.detailed .b-info .zone,
.personal-training .group-card.detailed .b-info .zone {
  margin-bottom: 0.25rem;
  width: 36px;
  height: 36px;
  object-fit: contain;
}
.home .group-card.detailed ul.price-listing,
.small-group .group-card.detailed ul.price-listing,
.live-courses .group-card.detailed ul.price-listing,
.nutrition .group-card.detailed ul.price-listing,
.personal-training .group-card.detailed ul.price-listing {
  margin-top: 0;
  padding: 1.125rem 1.25rem 0;
  border-top: solid 1px #0085cd;
}
.home .group-card.detailed ul.price-listing li,
.small-group .group-card.detailed ul.price-listing li,
.live-courses .group-card.detailed ul.price-listing li,
.nutrition .group-card.detailed ul.price-listing li,
.personal-training .group-card.detailed ul.price-listing li {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
  color: #ffffff;
}
.home .group-card.detailed .btn,
.small-group .group-card.detailed .btn,
.live-courses .group-card.detailed .btn,
.nutrition .group-card.detailed .btn,
.personal-training .group-card.detailed .btn {
  padding: 0.5rem 2rem;
  margin-bottom: 0.5rem;
  border-radius: 1.5rem;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 0.9rem;
  font-style: italic;
  text-transform: uppercase;
}
.home .group-card.pinned,
.small-group .group-card.pinned,
.live-courses .group-card.pinned,
.nutrition .group-card.pinned,
.personal-training .group-card.pinned {
  width: 100%;
  max-width: 370px;
  margin-left: auto;
  padding: 1.5rem;
  height: inherit;
  margin-bottom: 2rem;
}
.home .group-card.pinned .title,
.small-group .group-card.pinned .title,
.live-courses .group-card.pinned .title,
.nutrition .group-card.pinned .title,
.personal-training .group-card.pinned .title {
  justify-content: center;
  padding-bottom: 0.875rem;
  margin-bottom: 0.25rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  color: #ffffff;
  border-bottom: solid 3px #0080ce;
}
.home .group-card.pinned .participants,
.small-group .group-card.pinned .participants,
.live-courses .group-card.pinned .participants,
.nutrition .group-card.pinned .participants,
.personal-training .group-card.pinned .participants {
  background: #0085cd;
  margin-bottom: 1rem;
  padding: 0.75rem 1.3125rem;
}
.home .group-card.pinned .price-listing,
.small-group .group-card.pinned .price-listing,
.live-courses .group-card.pinned .price-listing,
.nutrition .group-card.pinned .price-listing,
.personal-training .group-card.pinned .price-listing {
  margin-top: 1.25rem;
  margin-bottom: 0;
}
.home .group-card.pinned .btn,
.small-group .group-card.pinned .btn,
.live-courses .group-card.pinned .btn,
.nutrition .group-card.pinned .btn,
.personal-training .group-card.pinned .btn {
  display: block;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  width: 70%;
  margin: 0.25rem auto;
  border-radius: 1.5rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.8125rem;
  font-style: italic;
  text-transform: uppercase;
}
.home .group-card.pinned .progress,
.small-group .group-card.pinned .progress,
.live-courses .group-card.pinned .progress,
.nutrition .group-card.pinned .progress,
.personal-training .group-card.pinned .progress {
  overflow: visible;
}
.home .coach-card,
.small-group .coach-card,
.live-courses .coach-card,
.nutrition .coach-card,
.personal-training .coach-card {
  width: 270px;
  min-height: 170px;
  padding: 0;
  margin: 16px;
  display: flex !important;
}
.home .coach-card .card-img-top,
.small-group .coach-card .card-img-top,
.live-courses .coach-card .card-img-top,
.nutrition .coach-card .card-img-top,
.personal-training .coach-card .card-img-top {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}
.home .coach-card img.card-img-top,
.small-group .coach-card img.card-img-top,
.live-courses .coach-card img.card-img-top,
.nutrition .coach-card img.card-img-top,
.personal-training .coach-card img.card-img-top {
  height: 170px;
  object-fit: cover;
}
.home .coach-card .card-body,
.small-group .coach-card .card-body,
.live-courses .coach-card .card-body,
.nutrition .coach-card .card-body,
.personal-training .coach-card .card-body {
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 16px;
  font-style: italic;
  padding: 1rem 1rem 110px 1rem;
  text-align: center;
  position: relative;
}
.home .coach-card .card-body .price,
.small-group .coach-card .card-body .price,
.live-courses .coach-card .card-body .price,
.nutrition .coach-card .card-body .price,
.personal-training .coach-card .card-body .price {
  margin-left: 8px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 18px;
  color: #0080ce;
}
.home .coach-card .card-body .card-bottom,
.small-group .coach-card .card-body .card-bottom,
.live-courses .coach-card .card-body .card-bottom,
.nutrition .coach-card .card-body .card-bottom,
.personal-training .coach-card .card-body .card-bottom {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  max-height: 110px;
}
.home .coach-card .card-title,
.small-group .coach-card .card-title,
.live-courses .coach-card .card-title,
.nutrition .coach-card .card-title,
.personal-training .coach-card .card-title {
  display: block;
  padding: 0;
  font-size: 0.875rem;
  font-style: italic;
  text-transform: uppercase;
  border-radius: 0;
  color: white;
  width: 100%;
  margin-bottom: 1rem;
  background-color: #1f1f1f;
  line-height: 1.5rem;
  height: 3rem;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home .coach-card .i-icon,
.small-group .coach-card .i-icon,
.live-courses .coach-card .i-icon,
.nutrition .coach-card .i-icon,
.personal-training .coach-card .i-icon {
  display: inline-block;
}
.home .coach-card .i-icon img,
.small-group .coach-card .i-icon img,
.live-courses .coach-card .i-icon img,
.nutrition .coach-card .i-icon img,
.personal-training .coach-card .i-icon img {
  width: 30px;
}
.home .coach-card .btn,
.small-group .coach-card .btn,
.live-courses .coach-card .btn,
.nutrition .coach-card .btn,
.personal-training .coach-card .btn {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  text-transform: uppercase;
}

.live-courses .live.grand-public .course-card .card-img {
  -webkit-filter: initial;
  filter: initial;
}
.live-courses .live.grand-public .course-card .hovered:after {
  background: url("../../../img/coach-smoke.png") no-repeat;
  background-size: 100% 100%;
  opacity: 0.3;
}
.live-courses .live.grand-public .course-card .card-top:after {
  background-color: #0080ce;
  content: "SANS INSCRIPTION";
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 50%;
  transform-origin: bottom left;
  transform: translate(29.29%, -100%) rotate(45deg);
  color: white;
  border-radius: 1em;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  padding: 24px 0 0;
}

.badge-free {
  margin-right: 0;
  font-size: 0.97rem;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-style: italic;
}

.badge-price {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 600;
}

.normal-price-label,
.normal-price {
  font-size: 0.85rem !important;
}

.normal-price {
  text-decoration: line-through !important;
  color: white !important;
}

.img-avatar {
  display: inline-block !important;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}
.img-avatar.img-avatar16 {
  width: 16px;
  height: 16px;
}
.img-avatar.img-avatar20 {
  width: 20px;
  height: 20px;
}
.img-avatar.img-avatar32 {
  width: 32px;
  height: 32px;
}
.img-avatar.img-avatar48 {
  width: 48px;
  height: 48px;
}
.img-avatar.img-avatar96 {
  width: 96px;
  height: 96px;
}
.img-avatar.img-avatar128 {
  width: 128px;
  height: 128px;
}
.img-avatar-thumb {
  margin: 0.25rem;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.3);
}
.img-avatar.img-avatar-rounded {
  border-radius: 0.25rem;
}

.img-thumb {
  padding: 0.375rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
}

.img-link {
  display: inline-block;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}
.img-link:hover {
  transform: scale(1.02);
  opacity: 0.75;
}
.img-link:active {
  transform: scale(1);
  opacity: 1;
}
.img-link.img-link-simple:hover {
  transform: scale(1);
}

.img-link-zoom-in {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.img-fluid.img-fluid-100,
.img-fluid-100 .img-fluid {
  width: 100%;
}

.payment-membership {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.payment-membership .pms {
  width: 100%;
  height: 88px;
  background-color: #FFF;
  line-height: 85px;
  border-radius: 12px;
  position: relative;
}
.payment-membership .checked {
  border: 2px solid #0080ce;
}
.payment-membership .in-checked {
  border: 2px solid #cecece;
}
.payment-membership label.radio-label {
  font-weight: 600;
  width: 60%;
}
.payment-membership .member-extra {
  border-left: 1px solid #cecece;
  border-right: 1px solid #cecece;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
}
.payment-membership .last-row {
  border-left: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
}
.payment-membership .product {
  font-weight: 600;
}
.payment-membership .member-ship-title {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-style: italic;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 4rem;
  text-transform: uppercase;
}
.payment-membership .first-row {
  height: 135px;
  border-left: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-top: 1px solid #cecece;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  line-height: 100px;
}
.payment-membership .amount {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-style: italic;
  font-size: 27px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 100px;
  border-bottom: 1px solid #cecece;
}
.payment-membership input[type=radio] {
  width: 12%;
  height: 1.3rem;
  position: relative;
  top: 5px;
}
.payment-membership button#payMembership {
  height: 46px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 13px;
  font-weight: 100;
  font-style: italic;
  text-transform: uppercase;
}
.payment-membership .small {
  font-size: 13px;
  font-weight: 400;
  font-family: "Montserrat";
  text-transform: lowercase;
}
.payment-membership i.icon.icon-visa.pos-payment {
  position: relative;
  top: 9px;
}
.payment-membership i.icon.icon-master-card.pos-payment {
  position: relative;
  top: 9px;
}
@media (max-width: 414px) {
  .payment-membership .member-ship-title {
    font-family: "AntiqueOliveStd-Nord", sans-serif;
    font-style: italic;
    font-size: 18px !important;
    font-weight: bold;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
  .payment-membership .amount {
    font-family: "AntiqueOliveStd-Nord", sans-serif;
    font-style: italic;
    font-size: 14px !important;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 100px;
    border-bottom: 1px solid #cecece;
  }
}

.reset-page {
  margin-top: 2rem;
}
.reset-page .rest {
  position: relative;
  display: inline-block;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  color: #323132;
  padding-bottom: 0.5rem;
  margin-bottom: 1.75rem;
  font-size: 25px;
  line-height: 29px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.reset-page .rest:after {
  content: "";
  position: absolute;
  left: 26%;
  bottom: -5px;
  width: 47%;
  border-bottom: 3px solid #0080ce;
}
.reset-page .text-rest {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  color: #343434;
  padding: 28px;
}
.reset-page .btn-rest {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 13px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-style: italic;
}
.reset-page .link-rest {
  margin-top: 5rem;
  text-decoration: underline;
}

section.live-courses {
  position: relative;
  background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
  padding-top: 30px;
  padding-bottom: 84px;
}
section.live-courses:before, section.live-courses:after {
  content: "";
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
  z-index: 2;
}
@media (min-width: 767.98px) {
  section.live-courses:before, section.live-courses:after {
    width: 184px;
  }
}
@media (min-width: 768px) {
  section.live-courses:before {
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #f7f7f7 97%);
  }
  section.live-courses:after {
    left: 0;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #f7f7f7 97%);
  }
}

.live-courses-slider {
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
}

.small-group-slider {
  position: relative;
  margin-bottom: 20px;
}
.small-group-slider .course-card {
  height: 474px;
}

section.nutritions {
  position: relative;
  min-height: 520px;
  margin-top: 140px;
  margin-bottom: 50px;
  color: #ffffff;
}
section.nutritions:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: calc(100% + 15px);
  border-radius: 25px;
  background-image: linear-gradient(70deg, #4b4b4b 2%, #2d2d2d 98%);
}
section.nutritions .section-title {
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 0;
  color: #0080ce;
}
section.nutritions .section-title:after {
  display: none;
}

.nutritionist-boxes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 300px;
  overflow-x: auto;
  padding: 1.25rem 0.625rem 0 0.625rem;
  margin-bottom: 15px;
}

.nutritionist-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  max-width: 335px;
  height: 100px;
  padding: 10px 10px 10px 110px;
  margin-bottom: 30px;
  margin-right: 30px;
  border-radius: 13px;
  background-color: #0c84c7;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.12);
}
.nutritionist-box > div {
  display: block;
  width: 100%;
  text-align: center;
}
.nutritionist-box img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100%;
  object-fit: cover;
}
.nutritionist-box .name {
  margin-bottom: 10px;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  font-style: italic;
  color: #ffffff;
  text-transform: capitalize;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.nutritionist-box .job {
  display: block;
  margin-bottom: 16px;
  font-family: "Geogrotesque", "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  color: rgba(52, 52, 52, 0.5);
}
.nutritionist-box a {
  padding: 0.2rem 2rem;
  font-style: italic;
  text-transform: capitalize;
}

@media (min-width: 992px) {
  /*
  .nutritionist-boxes {
      height: 370px;
  }
  .nutritionist-box {
      width: 100%;
      max-width: 350px;
      height: 109px;
      padding-left: 140px;
      img {
          width: 118px;
      }
      .name {
          font-size: 0.9375rem;
      }
      .job {
          font-size: 0.875rem;
          margin-bottom: 21px;
      }
      a {
          font-size: 0.875rem;
      }
  }
  */
}
section.personal-training {
  position: relative;
  min-height: 609px;
  margin-top: 6rem;
  color: rgba(255, 255, 255, 0.54);
}
section.personal-training:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 609px;
  border-radius: 25px;
  background: url("/img/pt_home.png");
}
section.personal-training .section-title {
  color: #ffffff;
}
section.personal-training a {
  color: #ffffff;
  text-decoration: underline;
}

@media (min-width: 1400px) {
  section.nutritions:before {
    width: 1318px;
  }

  section.personal-training:before {
    width: 1318px;
  }
}
div#chartjs-tooltip {
  background-color: #343434;
  border-radius: 14px;
  color: #ffff;
  z-index: 999999 !important;
}

section.nutritions:before {
  background: url("/img/nutrition_home.png");
  background-size: cover;
}

.b-video {
  margin-bottom: 12px;
  margin-right: 12px;
}
.b-video:hover {
  text-decoration: none;
}
.b-video .img {
  position: relative;
  width: 270px;
  height: 150px;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #d8d8d8;
}
@media (max-width: 767.98px) {
  .b-video .img {
    width: 100%;
  }
}
.b-video .img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, #000000 84%);
  border-radius: 5px;
}
.b-video .img:after {
  content: "";
  position: absolute;
  left: 8px;
  bottom: 6px;
  width: 38px;
  height: 38px;
  background: url(../../../img/player2.svg) no-repeat;
  background-size: contain;
  z-index: 2;
}
.b-video .img .time {
  content: "";
  position: absolute;
  right: 6px;
  bottom: 11px;
  padding: 2px 5px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.b-video .img img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.b-video .info {
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  padding-top: 0.875rem;
}
.b-video .info .title {
  font-weight: 600;
  color: #343434;
  position: relative;
  padding-right: 20px;
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
}
.b-video .info .category {
  font-weight: 500;
  color: #a9a9a9;
}

.videos-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.videos-container .b-video {
  margin-right: 17px;
  width: 257px;
}
.videos-container .b-video.details {
  position: relative;
}
.videos-container .b-video .img {
  width: 260px;
}

.video-details {
  margin-bottom: 2rem;
}
.video-details .video-player {
  width: 100%;
  height: 566px;
  margin-bottom: 32px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.38);
  background-color: #383838;
  padding-bottom: 0;
}
.video-details .video-player .video-js {
  width: 100%;
  height: 100%;
}
.video-details .video-player .video-js .vjs-big-play-button {
  margin-top: -0.75em;
  margin-left: -1.5em;
  height: 1.5em;
  width: 3em;
  top: 50%;
  left: 50%;
}
.video-details .video-player .video-js .vimeoFrame {
  height: 100% !important;
}
.video-details .video-player .video-js .vimeoFrame video {
  pointer-events: none;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
}
.video-details .details {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
}
.video-details .details .title {
  margin-bottom: 8px;
  font-size: 25px;
  font-weight: 600;
  color: #343434;
}
.video-details .details .category {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #a9a9a9;
}
.video-details .details .description {
  font-size: 13px;
  font-weight: normal;
  color: #343434;
}
.video-details .details .share span {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}
.video-details .details .share img {
  width: 37px;
  height: 37px;
  object-fit: contain;
  margin-right: 8px;
}
.video-details .other-videos {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .video-details .other-videos {
    border-left: 1px solid #dedede;
    padding-left: 25px;
  }
}
.video-details .other-videos .title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}
.video-details .other-videos .b-video {
  display: flex;
  align-items: flex-start;
}
.video-details .other-videos .b-video .img {
  width: 167px;
  height: 99px;
  margin-right: 8px;
}

.page-load-status {
  display: none;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  text-align: center;
  color: #777;
}

.embed-container {
  --video--width: 1296;
  --video--height: 540;
  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
  /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
  background: black;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a.link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.rating-modal .modal-lg {
  width: 552px;
}
.rating-modal .modal-content {
  background: transparent;
  border: none;
  border-radius: 13px;
  height: 60vh;
}
.rating-modal .modal-body {
  padding: 32px;
  text-align: center;
  border: none;
  border-radius: 13px;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.33);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  color: #4c4c4c;
}
.rating-modal .modal-header {
  justify-content: center;
  padding-bottom: 32px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 600;
  color: #ffffff;
}
.rating-modal .note-text {
  display: block;
  font-size: 22px;
  color: #6b9be8;
  line-height: 1.36;
}
.rating-modal .coach {
  margin-top: 42px;
  margin-bottom: 42px;
  font-size: 18px;
  font-weight: bold;
}
.rating-modal .coach .avatar {
  display: block;
  width: 70px;
  height: 70px;
  border: none;
  margin: 12px auto;
}
.rating-modal .form-control {
  margin-top: 32px;
  margin-bottom: 34px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;
  border: none;
}
.rating-modal .form-control::placeholder {
  color: rgba(59, 59, 59, 0.52);
}
.rating-modal .actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rating-modal .btn-primary {
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 4px 10px 0 rgba(3, 109, 173, 0.3);
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}
.rating-modal .btn-link {
  margin-top: 4px;
  font-size: 13px;
  font-weight: 500;
  color: #a4a4a4;
  text-transform: uppercase;
}

.col-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991.98px) {
  .col-filter {
    flex-direction: column;
  }
}
.col-filter .count-video {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.nb-vues {
  font-size: 16px;
  font-weight: 500;
  float: right;
}

.activity-color {
  background-color: #0080ce;
}

.item-vod {
  width: 45%;
}

.top-10 {
  padding-top: 10px;
}

.btn-curser {
  cursor: pointer;
}

a.morelink {
  text-decoration: none;
  outline: none;
}

.morecontent span {
  display: none;
}

.number-view {
  content: "";
  position: absolute;
  right: 6px;
  top: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.number-view .color-b {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 5px;
  margin: 5px 0px;
  border-radius: 3px;
}
.number-view .nb-vues {
  font-size: 11px;
  font-weight: 500;
}
.number-view .nb-vues .badge {
  margin: 5px 0px;
  font-size: 10px;
  font-weight: 400;
}
.number-view .icon-eye-gray {
  background-image: url(../../images/icons/view-1.svg);
}

.coach-header {
  height: auto;
  min-height: 624px;
  padding-top: 4rem;
  margin-bottom: 5rem;
  background: url("../../../img/motif2.png"), #f6f5fa;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
}
.coach-header .job {
  display: block;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 1.875rem;
  font-style: italic;
  line-height: 1.67;
  color: rgba(0, 128, 206, 0.5);
  text-transform: uppercase;
}
.coach-header .name {
  display: block;
  margin-bottom: 1rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 2.5rem;
  font-style: italic;
  line-height: 1.25;
  color: #343434;
  text-transform: uppercase;
}
@media (max-width: 575.98px) {
  .coach-header .name {
    font-size: 2rem;
  }
}
.coach-header .btn {
  padding: 16px;
  border-radius: 24px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
}
.coach-details .title {
  margin-bottom: 30px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: bold;
  color: #343434;
}
.coach-details .title .votes {
  margin-top: 1rem;
  font-family: "Montserrat", sans-serif;
}
.coach-details .row-spacing {
  margin-bottom: 3.5rem;
}
.coach-details ul.specialities {
  list-style: none;
  padding-left: 0;
}
.coach-details ul.specialities li {
  display: inline-block;
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1875rem;
  font-weight: 600;
  color: #343434;
  border-right: solid 1px #e0e0e0;
}
@media (max-width: 991.98px) {
  .coach-details ul.specialities li {
    border: none;
    width: 100%;
  }
}
.coach-details ul.languages {
  list-style: none;
  padding-left: 0;
}
.coach-details ul.languages li {
  display: inline-block;
  padding-left: 26px;
  padding-right: 26px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.0625rem;
  font-weight: 500;
  color: #343434;
}

.comment {
  margin-bottom: 42px;
}
.comment:not(:last-child) {
  border-bottom: solid 1px #e4e4e4;
}
.comment .media {
  margin-bottom: 12px;
}
.comment .media img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 50%;
}
.comment .media .name {
  font-size: 1.125rem;
  font-weight: bold;
}
.comment .media .name .date {
  font-size: 0.875rem;
  font-weight: normal;
  color: rgba(52, 52, 52, 0.6);
}
.comment .media .name .badge {
  font-weight: 600;
  padding: 4px 8px;
}
.comment p {
  margin-bottom: 36px;
}

.available-box {
  width: 100%;
  height: 159px;
  padding: 24px 32px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.available-box .day {
  font-size: 1.125rem;
  font-weight: bold;
  color: #0080ce;
  margin-bottom: 18px;
}
.available-box .date {
  font-weight: 500;
  margin-bottom: 15px;
}
.available-box .time .badge {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  font-size: 1.1875rem;
  font-weight: 600;
}

.time-price-tabs {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.time-price-tabs .nav-link {
  margin-right: 0.625rem;
  border-radius: 1.25rem;
  border: solid 2px #9b9b9b;
  background: transparent;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 0.9375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
}
.time-price-tabs .nav-link .icon {
  filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(187deg) brightness(93%) contrast(90%);
}
.time-price-tabs.nav-pills .nav-link.active, .time-price-tabs.nav-pills .show > .nav-link {
  background-color: transparent;
  border-color: #0080ce;
  color: #0080ce;
}
.time-price-tabs.nav-pills .nav-link.active .icon, .time-price-tabs.nav-pills .show > .nav-link .icon {
  filter: invert(32%) sepia(98%) saturate(2695%) hue-rotate(183deg) brightness(93%) contrast(104%);
}

.coachs-container,
.nutritions-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

span#showMore {
  cursor: pointer;
  color: #0080cf;
  font-weight: 500;
}

.modal-open .modal.modal-disponibilities {
  padding-right: 0 !important;
}

.modal-disponibilities.has-stiked-footer .modal-body {
  margin-bottom: 115px;
}
.modal-disponibilities.has-stiked-footer .modal-footer {
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.modal-disponibilities .modal-header .close {
  margin: -1rem auto -1rem -1rem;
  padding: 0.5rem;
  float: left;
  font-size: 2.5rem;
}
.modal-disponibilities .modal-dialog {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.modal-disponibilities .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}
.modal-disponibilities .pack {
  display: block;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.4375rem;
  font-style: italic;
  color: #343434;
  margin-bottom: 32px;
  padding-left: 20px;
}
.modal-disponibilities .pack .number {
  color: #0080ce;
}
.modal-disponibilities .pack-details {
  min-height: 110px;
}
.modal-disponibilities .pack-count {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 106px;
  height: 79px;
  border-radius: 6px;
  background-color: #343434;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}
.modal-disponibilities .pack-count .count {
  font-size: 1.25rem;
  line-height: 1.2;
}
.modal-disponibilities .pack-count .count .number {
  font-size: 1.9375rem;
}
.modal-disponibilities ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  text-align: center;
}
.modal-disponibilities ul .btn {
  font-weight: bold;
}
.modal-disponibilities ul .btn.btn-primary {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.8125rem;
  font-style: italic;
  text-transform: uppercase;
}

.pack-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 15px;
}

.b-sessions {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 300px);
}

.b-session {
  max-width: 230px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  font-size: 0.8125rem;
  border-left: 1px solid #e3e3e3;
}
.b-session .date {
  font-size: 13px;
  font-weight: 600;
  color: #0080ce;
}
.b-session .times {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.b-session .times .time {
  padding: 8px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}
.b-session .times .time .icon {
  width: 20px;
  height: 20px;
}

.b-disabled {
  opacity: 0.65;
  pointer-events: none;
}

.selectedButton {
  background-color: #0080ce;
}
.selectedButton span {
  display: none;
}
.selectedButton img {
  display: block;
  height: 20px;
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .pack-details {
    justify-content: center;
    flex-wrap: wrap;
  }

  .modal-disponibilities .pack-count .count {
    margin-left: auto;
    margin-right: auto;
  }

  .b-sessions {
    width: 100%;
    max-width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    justify-content: flex-start;
  }

  .modal-disponibilities .pack {
    font-size: 1rem;
  }
}
.b-disponibilities {
  display: none;
}
.b-disponibilities .title {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 2.125rem;
}
.b-disponibilities .date {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-radius: 0.1875rem;
  background-color: #0080ce;
  color: #ffffff;
  font-size: 0.9375rem;
  font-weight: 600;
}
.b-disponibilities ul {
  list-style: none;
  padding-left: 0;
}
.b-disponibilities ul li {
  margin-bottom: 1rem;
}
.b-disponibilities .no-dispo {
  margin-top: 2rem;
  text-align: center;
  font-weight: bold;
}

.b-dispo-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  min-height: 74px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.b-dispo-time span,
.b-dispo-time .select-time {
  font-weight: bold;
}
.b-dispo-time .select-time {
  width: 170px;
  height: 39px;
  text-align: center;
}
.b-dispo-time .select-time img {
  display: none;
}
.b-dispo-time .select-time.selected {
  background-color: #0080ce;
}
.b-dispo-time .select-time.selected span {
  display: none;
}
.b-dispo-time .select-time.selected img {
  display: block;
  height: 20px;
  margin: 0 auto;
}

.modal-disponibilities .fc-toolbar h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.35rem;
  font-weight: 600;
  text-transform: capitalize;
}
.modal-disponibilities .fc-button-primary {
  color: #979797;
  background-color: #ffffff;
  border-color: #ffffff;
}
.modal-disponibilities .fc-unthemed th {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  color: rgba(52, 52, 52, 0.5);
}
.modal-disponibilities .fc-unthemed th,
.modal-disponibilities .fc-unthemed td,
.modal-disponibilities .fc-unthemed thead,
.modal-disponibilities .fc-unthemed tbody,
.modal-disponibilities .fc-unthemed .fc-divider,
.modal-disponibilities .fc-unthemed .fc-row,
.modal-disponibilities .fc-unthemed .fc-content,
.modal-disponibilities .fc-unthemed .fc-popover,
.modal-disponibilities .fc-unthemed .fc-list-view,
.modal-disponibilities .fc-unthemed .fc-list-heading td {
  border-color: white;
}
.modal-disponibilities .fc-content-skeleton td {
  position: relative;
}
.modal-disponibilities .fc-unthemed tbody td span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 40px;
  border-radius: 3.6px;
  background-color: #dee1e3;
  font-family: "Montserrat", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
}
.modal-disponibilities .fc-unthemed tbody td.fc-state-highlight span {
  color: #ffffff;
  background-color: #0080ce;
}
.modal-disponibilities .fc-unthemed td.fc-today {
  background-color: #ffffff;
}
.modal-disponibilities .fc-unthemed td.fc-today span {
  color: #ffffff;
  background-color: rgba(0, 128, 206, 0.5);
}
.modal-disponibilities .fc-dayGrid-view .fc-body .fc-row {
  min-height: 3em;
}
.modal-disponibilities .fc-dayGrid-view .fc-body .fc-row {
  height: 48px !important;
}
.modal-disponibilities .fc-widget-header {
  margin-bottom: 13px;
}
@media (min-width: 768px) {
  .modal-disponibilities .fc-unthemed tbody td span {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  .modal-disponibilities .fc-dayGrid-view .fc-body .fc-row {
    height: 75px !important;
  }
}
.modal-disponibilities .fc-scroller.fc-day-grid-container {
  height: auto !important;
}
.modal-disponibilities .fc-day-top.disable-day {
  position: relative;
  color: #bbbbbb;
}
.modal-disponibilities .fc-day-top.disable-day span {
  background-color: rgba(222, 225, 227, 0.3);
}
.modal-disponibilities .fc-day-top.disable-day:after {
  content: "";
  position: absolute;
  bottom: 32px;
  left: 12px;
  transform: rotate(-45deg);
  height: 1px;
  width: 80%;
  background-color: #bbbbbb;
}

.list-dispo {
  overflow-y: auto;
  max-height: 436px;
}

.baseline-dispo {
  align-items: baseline;
}

td.td-coach.date-coach.live-past-session {
  position: absolute;
  right: 172px;
}

.error-time {
  margin-top: 1rem;
  font-size: 1.1875rem;
  text-align: center;
  color: #161d39;
}

i.icon.icon-wifi-black.i-xl.live-past-icon {
  position: absolute;
  left: 15px;
  top: 16px;
}

.date-availability {
  float: right;
  font-size: 0.875rem;
  font-weight: 600;
  color: #040404;
  text-transform: capitalize;
}

ul#dataList li:nth-child(n+3) {
  display: none;
}

.hero-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 364px;
  background-color: rgba(52, 52, 52, 0.57);
  background-size: cover;
  background-position: center;
}
@media (max-width: 991.98px) {
  .hero-header {
    background-position: center right;
  }
}
.hero-header:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 0;
}
.hero-header .title {
  position: relative;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 2.1875rem;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 0.5rem;
  margin-bottom: 1.75rem;
  color: #ffffff;
}
.hero-header .title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 4px solid #0080ce;
}
@media (max-width: 991.98px) {
  .hero-header .title {
    font-size: 1.5rem;
  }
}

.filters {
  margin: 2.625rem 0;
  text-align: center;
}
.filters .section-title {
  font-size: 1.0625rem;
  line-height: 1.71;
}
.filters .section-title:after {
  border-color: #343434;
}

.days-plans {
  margin-top: 3.125rem;
  margin-bottom: 1.875rem;
}

.day-plan {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 2.125rem;
  padding-left: 1rem;
  margin-bottom: 2.5rem;
  flex-wrap: wrap;
}
.day-plan .planing-live-corse {
  margin-bottom: 5px;
  overflow: hidden;
  width: 80%;
}
@media (max-width: 575.98px) {
  .day-plan .planing-live-corse {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .day-plan .planing-live-corse .owl-stage-outer {
    padding: 0px 0px;
  }
}
.day-plan .customNextBtn {
  width: 32px;
  height: 37px;
  display: inline-block;
  cursor: pointer;
}
.day-plan .customNextBtn.disabled {
  opacity: 0.5;
  cursor: default;
}
.day-plan .customPrevBtn {
  width: 32px;
  height: 37px;
  display: inline-block;
  cursor: pointer;
}
.day-plan .customPrevBtn.disabled {
  opacity: 0.5;
  cursor: default;
}
.day-plan::before {
  content: "";
  position: absolute;
  top: 0;
  left: -5%;
  width: 110%;
  height: 250px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  background-image: linear-gradient(to bottom right, #dee6ec 60%, #f3f6f8 85%);
  transform: skewX(24deg);
  z-index: -1;
}
.day-plan .course-card {
  height: auto;
}
.day-plan .day {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  width: 168px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.1875rem;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #343434;
}
.day-plan .day i {
  margin-bottom: 0.5rem;
}
.day-plan .day .date {
  display: block;
  padding-top: 0.625rem;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 1.125rem;
  font-weight: normal;
  text-transform: initial;
  font-style: italic;
  color: #0080ce;
}
.day-plan .b-info {
  margin-top: 0.625rem;
}
.day-plan .media {
  align-items: center;
  margin-right: 0;
  font-size: 15px;
  text-align: left;
}
.day-plan .media .avatar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.day-plan.coming-soon:before {
  background-image: linear-gradient(to bottom right, #efefef 60%, #f6f6f6 85%);
}
.day-plan.coming-soon .text {
  position: absolute;
  top: 100px;
  left: 50%;
  width: 100%;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  transform: translateX(-50%);
}

@media (max-width: 1199.98px) {
  .day-plan {
    flex-direction: column;
    align-items: center;
  }
  .day-plan .day {
    margin-bottom: 20px;
  }
}
@media (max-width: 991.98px) {
  .day-plan {
    padding-left: 0;
  }
  .day-plan::before {
    left: -15px;
    width: calc(100% + 30px);
    transform: skewX(0deg);
    border-radius: 0;
  }
}
.live-course-title::after {
  border-bottom: 4px solid #ff0000 !important;
}

.small-group-title::after {
  border-bottom: 4px solid #ff8f00 !important;
}

.personal-training-title::after {
  border-bottom: 4px solid #5b5795 !important;
}

.vod-title::after {
  border-bottom: 4px solid #ffc200 !important;
}

.nutrition-title::after {
  border-bottom: 4px solid #00b915 !important;
}

.group-details {
  margin-bottom: 3.125rem;
}
.group-details section {
  margin-bottom: 3.75rem;
}
.group-details .section-title {
  font-size: 1.125rem;
  margin-bottom: 1.75rem;
  text-transform: inherit;
}
.group-details .comment {
  border: none;
  margin-left: 1.5rem;
  margin-top: 0.75rem;
}
.group-details .comment .display-none {
  display: none;
}
.group-details .comment .media img {
  width: 70px;
  height: 70px;
  border: solid 1px #dddddd;
}
.group-details .comment p {
  margin-bottom: 0;
}
.group-details .checked-list {
  padding-left: 2rem;
  margin-top: 1rem;
}
.group-details .numbered-list {
  padding-left: 2rem;
}
.group-details .detail-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}
.group-details .detail-items .item {
  text-align: center;
  margin: 0.5rem;
}
.group-details .detail-items .item .label {
  display: block;
  margin-top: 19px;
  font-size: 15px;
  color: #343434;
}
.group-details .detail-items .item .info {
  display: block;
  margin: 0.5rem 0 1rem;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 1.125rem;
  font-style: italic;
  color: #0080ce;
}

.basket-modal .modal-content {
  width: 100%;
  border-radius: 13px;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.33);
  background-color: var(--white);
}
.basket-modal .text-primary-basket {
  width: 100%;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: center;
  color: var(--blue);
}

.basket-modal .modal-content {
  width: 100%;
  border-radius: 13px;
  box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.33);
  background-color: var(--white);
}
.basket-modal .text-primary-basket {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: center;
  color: var(--blue);
}
.basket-modal p.dont-worry {
  opacity: 0.53;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: var(--gray-900);
  text-align: revert;
  margin: 24px;
}
.basket-modal p.text-basket-process {
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-900);
}
.basket-modal .btn {
  padding: 0.75rem 2rem;
  margin-bottom: 0.5rem;
  border-radius: 1.5rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  text-transform: uppercase;
}
.basket-modal .btn-process {
  text-transform: uppercase;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-900);
  background: none;
  border: none;
}
.basket-modal .btn-plus-tard {
  border-radius: 24px;
  border: solid 2px var(--blue);
  padding: 0.75rem 2rem;
  margin-bottom: 0.5rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  text-transform: uppercase;
  color: var(--blue);
}

@media (max-width: 991.98px) {
  .group-details {
    margin-bottom: 0;
  }
  .group-details section {
    margin-bottom: 40px;
  }
  .group-details .btn {
    padding: 0.75rem 2rem;
    margin-bottom: 0.5rem;
    border-radius: 1.5rem;
    font-family: "AntiqueOliveStd-Nord", sans-serif;
    font-size: 0.75rem;
    font-style: italic;
    text-transform: uppercase;
  }
  .group-details .btn-process {
    text-transform: uppercase;
    opacity: 0.5;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: var(--gray-900);
    background: none;
    border: none;
  }
  .group-details .btn-plus-tard {
    border-radius: 24px;
    border: solid 2px var(--blue);
    padding: 0.75rem 2rem;
    margin-bottom: 0.5rem;
    font-family: "AntiqueOliveStd-Nord", sans-serif;
    font-size: 0.75rem;
    font-style: italic;
    text-transform: uppercase;
    color: var(--blue);
  }
  .group-details .modal-footer.disponiblity {
    border-top: 0px;
  }
}
@media (max-width: 991.98px) {
  .group-details {
    margin-bottom: 0;
  }
  .group-details section {
    margin-bottom: 40px;
  }
  .group-details .checked-list,
.group-details .numbered-list {
    padding-left: 0;
  }
  .group-details .comment {
    margin-left: 0;
  }
}
.login-page,
.login-page body {
  height: 100%;
}
.login-page .navbar {
  justify-content: center !important;
  height: 80px;
}
.login-page .form-signin {
  width: 100%;
  max-width: 420px;
  padding: 5.3125rem 15px 0.9375rem;
  margin: auto;
  text-align: center;
}
.login-page .section-title {
  font-size: 1.25rem;
}
.login-page .description {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: normal;
}
.login-page .form-control {
  font-size: 0.8125rem;
  padding: 0.75rem;
  border-radius: 1.375rem;
  height: auto;
  border-left: none;
  padding-left: 0;
}
.login-page .form-control:focus {
  border-color: #ced4da;
  box-shadow: none;
}
.login-page .input-group-text {
  font-size: 0.8125rem;
  border-radius: 1.375rem;
  background-color: #ffffff;
}
.login-page .form-check {
  font-size: 0.875rem;
  line-height: 1.8;
  font-weight: 600;
  text-align: left;
  padding-left: 3.625rem;
}
.login-page .forgot-link {
  display: block;
  margin-bottom: 1rem;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #343434;
}
.login-page .submit-btn,
.login-page .account-btn {
  display: block;
  width: 80%;
  margin: 0 auto 2rem;
  padding: 0.875rem;
  border-radius: 1.5rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.8125rem;
  font-style: italic;
  text-transform: uppercase;
}
.login-page .account-link {
  font-size: 0.9375rem;
  font-weight: 600;
  color: #0080ce;
  margin-bottom: 1rem;
}

.dashboard {
  position: relative;
  background-color: #f5f6fd;
}
.dashboard:not(.has-sideabar) .toggler-navbar {
  display: none !important;
}
.dashboard.has-sideabar .main {
  margin-left: 264px;
  transition: all 0.25s ease-out;
}
@media (max-width: 991.98px) {
  .dashboard.has-sideabar .main {
    margin-left: 0;
  }
}
.dashboard header .logged-user {
  border: none;
}
.dashboard .main {
  padding: 20px 0;
}
.dashboard .main .dropdown-toggle {
  position: relative;
  text-align: left;
  padding: 6px 40px 6px 12px;
  font-size: 0.8125rem;
  font-weight: 500;
}
.dashboard .main .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  right: 0;
  border: none;
  width: 10px;
  height: 5px;
  background: url(../../../img/icons/dropdown-arrow.svg) no-repeat;
  background-size: contain;
  transform: translateY(-50%);
}
.dashboard .main .dropdown-item.active {
  color: #0080ce;
  background-color: #ffffff;
}
.dashboard .main .dropdown-item:hover {
  color: #272727;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dashboard .no-member-ship-dashboard {
  padding-top: 3rem !important;
}
.dashboard .section-title {
  font-size: 1.25rem;
}

.box {
  padding: 16px;
  margin-bottom: 30px;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}
.box .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.125rem;
  font-size: 0.8125rem;
  font-weight: 500;
}

.b-stats {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 160px;
  height: calc(100% - 30px);
  padding: 16px 96px 16px 24px;
}
.b-stats:before {
  content: "";
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 60px;
  height: 83px;
  background-size: contain;
}
.b-stats.flame:before {
  background: url(../../../img/icons/flame-alternate.svg) no-repeat;
}
.b-stats.stopwatch:before {
  background: url(../../../img/icons/stopwatch-alternate.svg) no-repeat;
}
.b-stats.sessions:before {
  background: url(../../../img/icons/sessions-alternate.svg) no-repeat;
}
.b-stats.live:before {
  background-image: url(../../img/icons/wifi-black.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: none;
  opacity: 0.14674;
}
.b-stats.small-group:before {
  background-image: url(../../../img/icons/small-group.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: none;
  opacity: 0.14674;
}
.b-stats.p-t:before {
  background-image: url(../../images/icons/users1x.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: none;
  opacity: 0.14674;
}
.b-stats.total:before {
  background-image: url(../../img/icons/yoga-arm.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: none;
  opacity: 0.14674;
}
.b-stats.totale:before {
  background-image: url(../../img/icons/nutrition-dark-grey.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: none;
  opacity: 0.14674;
}
.b-stats .title {
  display: block;
  font-size: 0.9375rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}
.b-stats .dropdown-toggle {
  display: block;
  width: 100%;
  max-width: 156px;
}
.b-stats .result {
  font-size: 2rem;
  font-weight: bold;
  color: #0080ce;
}
.b-stats .result .unit {
  font-size: 0.75rem;
  font-weight: 500;
}
.b-stats.coach {
  min-height: 120px;
}
.b-stats.coach .result {
  font-size: 1.5625rem;
}

.session-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: #f5f6f6;
}
@media (max-width: 767.98px) {
  .session-box {
    flex-wrap: wrap;
  }
}
.session-box.remaining {
  justify-content: space-between;
}
.session-box .date {
  width: 105px;
  height: 118px;
  border-radius: 11px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 767.98px) {
  .session-box .date {
    width: 50%;
  }
}
.session-box .date .day {
  font-size: 1.5625rem;
  font-weight: 600;
  color: #0080ce;
}
.session-box .date .month {
  margin-bottom: 0.5rem;
  font-size: 1.0625rem;
  font-weight: 600;
  color: #0080ce;
  font-size: 0.8125rem;
  line-height: 1rem;
}
.session-box .session-time {
  position: relative;
  width: 175px;
  height: 120px;
  border-radius: 15px;
  margin-right: 0.9375rem;
  margin-left: 0.9375rem;
  overflow: hidden;
}
.session-box .session-time:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
  background: linear-gradient(0deg, black 0%, rgba(255, 255, 255, 0) 60%);
  border-radius: 13px;
}
@media (max-width: 767.98px) {
  .session-box .session-time {
    margin-right: 0;
    width: calc(50% - 0.9375rem);
  }
}
.session-box .session-time .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
}
.session-box .session-time .content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-style: italic;
  color: #ffffff;
  z-index: 1;
}
.session-box .session-desc {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1 0 auto;
}
@media (max-width: 767.98px) {
  .session-box .session-desc {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}
.session-box .rejoindre-session {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  flex-basis: auto;
}
@media (max-width: 575.98px) {
  .session-box .rejoindre-session {
    width: 100%;
  }
}
.session-box .rejoindre-session .btn .waiting {
  opacity: 0.3;
}
.session-box .remaining-session {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 105px;
  height: 118px;
  border-radius: 11px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  text-align: center;
  margin-left: auto;
  margin-right: 50px;
}
@media (max-width: 767.98px) {
  .session-box .remaining-session {
    width: 100%;
    margin-right: auto;
    margin-top: 15px;
  }
}
.session-box .remaining-session span {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: var(--blue);
}
.session-box .remaining-session p {
  font-size: 0.8125rem;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}
.session-box .category {
  opacity: 0.6;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 0.75rem;
  font-style: italic;
  margin-bottom: 0.9375rem;
  color: #0080ce;
  font-weight: 600;
}
.session-box .activity {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1.125rem;
}
.session-box .coach {
  font-size: 0.875rem;
  font-weight: 600;
}
.session-box .coach .avatar {
  width: 24px;
  height: 24px;
}
.session-box .badge {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background: #fff;
  color: #343434;
}
.session-box .drop-actions {
  position: absolute;
  top: 10px;
  right: 0;
}
.session-box .drop-actions .dropdown-toggle {
  padding: 6px;
}
.session-box .drop-actions .dropdown-toggle:after {
  display: none;
}
.session-box .session-count {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90px;
  height: 68px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: normal;
}
.session-box .session-count span {
  font-size: 1rem;
}
.session-box .session-count .nb {
  font-size: 1.75rem;
}

.b-nb-session {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 26px;
  margin-bottom: 10px;
  width: 100%;
  height: 53px;
  border-radius: 12px;
  background-color: #f5f6f6;
  font-size: 0.9375rem;
  font-weight: 600;
}
.b-nb-session:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 4px;
  height: 36px;
  border-radius: 4.5px;
  background-color: #61c5ea;
}

.activity-chart {
  position: relative;
  padding: 20%;
}
.activity-chart .canvas-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.0625rem;
  font-weight: 600;
  text-align: center;
}
.activity-chart .canvas-inner span {
  display: block;
  font-size: 1.9375rem;
  font-weight: 600;
}

.zones-chart {
  align-items: center;
}
.zones-chart .legend-chard {
  list-style: none;
  padding-left: 0;
}
.zones-chart .legend-chard li {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  font-size: 0.8125rem;
  font-weight: 500;
}
.zones-chart .legend-chard li .color-box {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 3px;
  margin-right: 16px;
}

.solde-box {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #343434;
  color: #ffffff;
}
.solde-box .solde-info {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.solde-box .text {
  display: block;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.125rem;
  font-style: italic;
  text-transform: uppercase;
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
}
.solde-box .text:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 10px;
  width: 20px;
  height: 20px;
  background-image: url(../../../img/icons/cash.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.solde-box a {
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  border-radius: 13.5px;
  background-color: rgba(255, 255, 255, 0.23);
}
.solde-box .solde {
  padding: 0.1875rem 0.875rem;
  margin-left: 0.5rem;
  border-radius: 4px;
  color: #0080ce;
  background-color: #ffffff;
}
.solde-box .solde span {
  font-size: 1.375rem;
  font-weight: bold;
}
.solde-box .solde sup {
  font-size: 0.6875rem;
  font-weight: 600;
}
.solde-box.command {
  margin-bottom: 0;
}
.solde-box.command .text {
  padding-left: 0;
}
.solde-box.command .text:before {
  display: none;
}
.solde-box.command .solde {
  border-radius: 22px;
}

.planning-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  margin-bottom: 15px;
  background: #f5f6f6;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.planning-box .planning-time {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 180px;
  width: calc(20% - 8px);
  height: 70px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  border-radius: 7px;
  /*
  @include media-breakpoint-down(sm) {
      width: 33%;
      max-width: 100%;
  }
  */
}
.planning-box .planning-time:last-child {
  margin-right: 0;
}
@media (max-width: 767.98px) {
  .planning-box .planning-time {
    width: calc(50% - 5px);
  }
  .planning-box .planning-time:nth-child(even) {
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .planning-box .planning-time {
    width: 100%;
    margin-right: 0;
  }
}
.planning-box .planning-time .drop-actions {
  position: absolute;
  top: 0;
  right: 0;
}
.planning-box .planning-time .drop-actions .dropdown-toggle {
  padding: 6px;
}
.planning-box .planning-time .drop-actions .dropdown-toggle:after {
  display: none;
}
.planning-box .planning-time.finish {
  border: solid 1.5px #d6d6d6;
  opacity: 0.5;
}
.planning-box .planning-time.empty {
  border: 1.5px dashed rgba(0, 128, 206, 0.39953);
  cursor: pointer;
}
.planning-box .planning-time.empty .number {
  background: #ffffff;
}
.planning-box .planning-time.plan {
  background: #ffffff;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.12);
}
.planning-box .planning-time.plan .number {
  background: #f6f6f6;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.375rem;
  text-align: center;
  color: #0080ce;
}
.planning-box .planning-time .text {
  color: #0080ce;
  top: calc(50% - 28px / 2 + 0.5px);
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.planning-box .planning-time .number {
  width: 46px;
  height: 54px;
  border-radius: 8px;
  background-color: #ebebeb;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 10px;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.375rem;
  color: #343434;
  mix-blend-mode: normal;
}
.planning-box .planning-time .date {
  width: 100px;
  opacity: 0.6;
  font-size: 0.8125rem;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gray-900);
}

.coach-avatar {
  width: 19px;
  height: 19px;
  border: solid 1px #dddddd;
  border-radius: 15px;
  margin-right: 0.5rem;
}

.pack-session .five-session-pack {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.375rem;
  font-style: italic;
  color: #0080ce;
}
.pack-session .name-coach {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-style: italic;
  font-size: 1rem;
  color: #000000;
}
.pack-session .modal-footer {
  border: none;
}

.date-range {
  background: #ffffff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
  height: auto;
  font-size: 0.75rem;
}
.date-range span {
  font-size: 0.75rem;
}

.training-date {
  width: 120px;
}

section.payment .mes-achats {
  padding: 1rem;
  margin-top: 2rem;
  padding-top: 66px;
}
section.payment .mes-achats #payment-table_filter,
section.payment .mes-achats #transaction-table_filter {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
section.payment .mes-achats #payment-table_wrapper,
section.payment .mes-achats #transaction-table_wrapper {
  overflow-x: auto;
  margin-bottom: 50px;
}
section.payment .mes-achats #payment-table_paginate,
section.payment .mes-achats #transaction-table_paginate {
  height: 35px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 15px;
}
section.payment .mes-achats .table-checkout {
  margin-bottom: 0;
  padding: 5px;
}
section.payment .table-checkout {
  border-collapse: separate;
  border-spacing: 0 15px;
}
section.payment button.recharge-button {
  background-color: rgba(255, 255, 255, 0.23);
  border-radius: 25px;
  border: none;
  padding: 3px 9px 3px 10px;
  font-size: 0.875rem;
  color: #ffff;
}
section.payment div#payment-table_filter {
  float: right;
}
section.payment div#transaction-table_filter {
  float: right;
}
section.payment input[type=search] {
  height: 39px;
  border: none;
  background: no-repeat url(../../img/icons/search.svg);
  background-repeat: no-repeat;
  background-position: 10px 7px;
  padding-left: 38px;
  font-size: 0.875rem;
  border-radius: 19.5px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--white);
}
section.payment .table th {
  padding: 0.65rem;
  vertical-align: top;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 0.8125rem;
  color: #9ba0a3;
}
section.payment .table tbody tr.odd {
  width: 100%;
  height: 50px;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(161, 161, 161, 0.11);
  background-color: var(--white);
  border-spacing: 5em;
}
section.payment .table tbody tr.even {
  width: 100%;
  height: 50px;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(161, 161, 161, 0.11);
  background-color: var(--white);
  border-spacing: 5em;
}
section.payment .table tbody tr.tr-odd {
  border-radius: 0px;
  background-color: var(--white);
  box-shadow: none !important;
}
section.payment .table tbody td {
  border: none;
}
section.payment .table tbody td:first-child {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}
section.payment .table tbody td:last-child {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}
section.payment .nav-tabs {
  border-bottom: 1px solid #f5f6fd;
}
section.payment .nav-tabs .nav-link.active,
section.payment .nav-tabs .nav-item.show .nav-link {
  color: #000;
  background-color: #ffffff;
  border: none;
  font-weight: 600;
  opacity: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.122842);
  border-radius: 20px;
}
section.payment section.payment .nav-tabs .nav-link:focus {
  border-color: #f5f6fd;
}
section.payment .nav-tabs .nav-link:hover,
section.payment .nav-tabs .nav-link:focus {
  border: none;
}
section.payment a {
  color: #b2a8c3;
  text-decoration: none;
  background-color: transparent;
  font-size: 0.875rem;
}
section.payment .nav-tabs .nav-link {
  border: none;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

section.edit-profil {
  /*
  .nav-tabs {
      .nav-link {
          .active {
              color: #000;
              opacity: 1;
              background-color: #ffffff;
              border-radius: 25px;
              font-weight: 600;
              font-stretch: normal;
              border: none;
              font-style: normal;
              letter-spacing: normal;
          }
      }
  }
  */
}
section.edit-profil .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}
section.edit-profil input#date_of_birth {
  background-color: #ffffff;
}
section.edit-profil label {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gray-900);
}
section.edit-profil .nav-tabs {
  border: none;
}
section.edit-profil .nav {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  /*
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  */
}
@media (max-width: 767.98px) {
  section.edit-profil .nav {
    grid-template-columns: auto;
  }
}
section.edit-profil .nav .nav-link {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #343434;
  border-radius: 20px;
  opacity: 0.5;
  border: none;
}
section.edit-profil .nav .nav-link.active {
  font-weight: 600;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.122842);
}
section.edit-profil span.unit-cut {
  position: absolute;
  top: 37px;
  right: 28px;
}
section.edit-profil input::-webkit-outer-spin-button,
section.edit-profil input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
section.edit-profil a {
  opacity: 0.5;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-900);
}
section.edit-profil .nav-tabs .nav-link:hover {
  font-size: 0.875rem;
}
section.edit-profil .invalid-feedback.error {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  margin-top: 4px;
  padding-left: 8px;
  font-style: normal;
  font-weight: 500;
  color: red;
  text-align: left;
}
section.edit-profil button.btn.btn-primary.edit-profile {
  font-family: AntiqueOliveStd-Nord;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  text-align: center;
  color: var(--white);
}
section.edit-profil .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}
section.edit-profil .avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 146px;
}
section.edit-profil .avatar-upload .avatar-edit input {
  display: none;
}
section.edit-profil .avatar-upload .avatar-edit input + label {
  content: url(../../img/icons/taking-pictures-circle-alternate.svg);
  object-fit: none;
  display: inline-block;
  width: 42px;
  height: 42px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
section.edit-profil .avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
section.edit-profil .avatar-upload .avatar-edit input + label:after {
  content: "";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
section.edit-profil .avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
section.edit-profil .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

section.session {
  /*
  .session-box {
      .date {
          @include media-breakpoint-down(lg) {
              width: 105px;
              flex-basis: auto;
          }
          @include media-breakpoint-down(md) {
              width: 50%;
              flex-basis: 50%;
          }
      }
      .session-time {
          @include media-breakpoint-down(lg) {
              width: 174px;
              flex-basis: auto;
              padding-right: 0.9375rem;
              &:before {
                  width: calc(100% - 0.9375rem);
              }
          }

          @include media-breakpoint-down(md) {
              width: 50%;
              flex-basis: 50%;
          }
      }
  }
    */
}
section.session .nav {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  align-items: center;
}
@media (max-width: 767.98px) {
  section.session .nav.adherent-profile {
    grid-template-columns: auto;
  }
}
section.session .nav .nav-item {
  text-align: center;
}
section.session .nav .nav-item .nav-link {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #343434;
  mix-blend-mode: normal;
  opacity: 0.5;
  padding: 0.5rem 0;
}
section.session .nav .nav-item .nav-link.active {
  font-weight: 600;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.122842);
  border-radius: 20px;
}
section.session .nav-tabs .nav-link.active,
section.session .nav-tabs .nav-item.show .nav-link {
  background-color: #ffffff;
  border-color: #ffffff #ffffff #ffffff;
  border-radius: 25px;
  font-size: 0.875rem;
  color: #000;
  font-weight: 600;
}
section.session .nav-tabs {
  border-bottom: 1px solid #f5f6fd;
}
section.session span.badge.badge-pill.badge-white {
  background-color: #fff;
  color: #0080cf;
}
section.session .nav-tabs .nav-link:hover,
section.session .nav-tabs .nav-link:focus {
  border-color: #f5f6fd #f5f6fd #f5f6fd;
}
section.session a {
  font-size: 0.875rem;
  text-decoration: none;
}
section.session .tab-pane#pack-session .session-box .session-time {
  margin-left: 0;
}
@media (max-width: 767.98px) {
  section.session .tab-pane#pack-session .session-box .session-desc {
    width: 50%;
  }
}

a.mr-1.reserve-persoanl-training {
  padding-left: 2.4rem;
}

.empty-basket {
  font-size: 1rem;
  font-weight: bold;
  color: #000000;
}

.title.empty-basket-title {
  border-bottom: 1px solid #bbbbbb52;
  width: 100%;
  padding-bottom: 7px;
}

a.btn.btn-secondary.account-btn.m-3 {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 0.8125rem;
  font-style: italic;
  text-transform: uppercase;
}

.d-flex.flex-row-reverse.bd-highlight.line-total {
  border-top: 1px solid #dedede;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.d-flex.flex-row-reverse.bd-highlight.line-total .p-2.bd-highlight.price {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: end;
  font-size: 1.1875rem;
  line-height: 1.4375rem;
}
.d-flex.flex-row-reverse.bd-highlight.line-total .p-2.bd-highlight.total-cart {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1875rem;
  line-height: 1.4375rem;
  color: #343434;
}

.table .table {
  margin-bottom: 0;
}

table.plan-nutrition th {
  height: 15px;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9ba0a3;
}
table.plan-nutrition .coach {
  font-size: 0.875rem;
  font-weight: 600;
}

section.food_survey {
  /* Firefox */
}
section.food_survey input::-webkit-outer-spin-button,
section.food_survey input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
section.food_survey input[type=number] {
  -moz-appearance: textfield;
}
section.food_survey .wrapper {
  position: relative;
}
section.food_survey .wrapper span {
  position: absolute;
  right: 14px;
  top: 41px;
  font-weight: 700;
}
section.food_survey b {
  position: absolute;
  top: 39px;
  right: 38px;
}
section.food_survey .cancel_food_survey {
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;
  border: none;
  background: none;
  text-align: center;
  cursor: pointer;
}
section.food_survey .visible {
  visibility: visible;
}
section.food_survey .invisible {
  display: none;
}
section.food_survey label {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}
section.food_survey p.purchase {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 20px;
  font-style: italic;
}
section.food_survey p.purchase:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 4px solid #0080ce;
}
section.food_survey span.text-danger {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  margin-top: 4px;
  padding-left: 8px;
  font-style: normal;
  font-weight: 500;
  color: red;
  text-align: left;
}

.recharge-link {
  font-size: 1.2rem !important;
  color: #0080ce !important;
  text-decoration: underline !important;
  background-color: unset !important;
}

.display-in-header {
  display: none;
}

.payment-membership th.bg-purple {
  text-align: center;
  border-top: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
}
.payment-membership td.plan-text {
  text-align: end;
  font-weight: 600;
}
.payment-membership td.standard {
  text-align: center;
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
}
.payment-membership td.last-row {
  border-bottom: 1px solid #c9c9c9;
}
.payment-membership td.first-row {
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
}

.modal .double-column > div {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

/*register*/
.login-page .form-register {
  text-align: center;
}

.login-page {
  /*@media (min-width: 991.98px) {*/
  /*article {*/
  /*width: 100%;*/
  /*}*/
  /*}*/
  /* Firefox */
}
.login-page .invalid-feedback-register {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  font-size: 14px;
  text-align: start;
  font-weight: 600;
  padding-left: 20px;
}
.login-page .valid-feedback-register {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  color: green;
  font-size: 14px;
  text-align: start;
  font-weight: 600;
  padding-left: 20px;
}
.login-page input#email {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.login-page .input-group > .form-control:not(:last-child),
.login-page .input-group > .custom-select:not(:last-child) {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.login-page .hide {
  display: none;
}
.login-page .hide_th {
  border: 0;
  background: none;
}
.login-page #valid-msg {
  color: #00c900;
}
.login-page .invalid {
  width: 100%;
  margin-top: 0.25rem;
  color: #343434;
  font-size: 14px;
  text-align: start;
  font-weight: 400;
}
.login-page .list-group {
  padding-left: 0;
  padding-right: 0;
}
.login-page .list-group-item {
  position: relative;
  display: block;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center;
}
.login-page .list-group-item.active {
  z-index: 2;
  color: #000000;
  background-color: #ffffff;
  border-color: #0080ce !important;
}
.login-page .radio {
  display: flex;
  margin: 0;
  position: relative;
}
.login-page .radio input[type=checkbox] {
  margin-left: 0;
  top: 1px;
  position: absolute;
}
.login-page .multisteps-form__progress {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}
.login-page .d-flex.justify-content-center.multisteps-form__content {
  padding-top: 58px;
  /* padding: 24px 41px; */
}
.login-page .js-active-ins {
  color: #0080ce;
}
.login-page .multisteps-form__title {
  text-align: center;
  font-size: 20px;
  font-style: italic;
  text-transform: uppercase;
}
.login-page .list-group-flush .list-group-item:first-child {
  border-top-width: 2px;
}
.login-page .list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 2px;
}
.login-page .list-group-item + .list-group-item.active {
  margin-top: 0px;
  border-top-width: 2px;
}
.login-page .list-group-flush .list-group-item {
  /*border-top-width: 1px; */
  border-radius: 7px;
  border: solid 2px #c0cbd2;
  width: 100%;
  height: 70px;
  margin-bottom: 15px;
}
.login-page .multisteps-form__progress-btn {
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  position: relative;
  color: #dce2e6;
  padding-top: 20px;
  text-indent: -9999px;
  border: none;
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
}
.login-page .multisteps-form__progress-btn-ins {
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  position: relative;
  color: #dce2e6;
  padding-top: 20px;
  text-indent: -9999px;
  border: none;
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
}
.login-page label.radio-label {
  font-size: 1rem;
  font-weight: 600;
  padding-left: 1.875rem;
  display: flex;
  position: relative;
  margin-bottom: 0;
}
.login-page label.radio-label i {
  position: absolute;
  top: 0;
  left: 0;
}
.login-page label.radio-label.gender {
  margin-bottom: 0;
}
.login-page label.radio-label.gender i {
  position: relative;
}
@media (min-width: 500px) {
  .login-page .multisteps-form__progress-btn {
    text-indent: 0;
  }
  .login-page .multisteps-form__progress-btn-ins {
    text-indent: 0;
  }
}
.login-page .multisteps-form__progress-btn:before {
  position: absolute;
  top: 30%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  /*box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);*/
  transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  border: 2px solid currentColor;
  border-radius: 50%;
  background-color: #dce2e6;
  box-shadow: none;
  box-sizing: border-box;
  z-index: 3;
}
.login-page .multisteps-form__progress-btn-ins:before {
  position: absolute;
  top: 30%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  /*box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);*/
  transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  border: 2px solid currentColor;
  border-radius: 50%;
  background-color: #dce2e6;
  box-shadow: none;
  box-sizing: border-box;
  z-index: 3;
}
.login-page .multisteps-form__progress-btn:after {
  position: absolute;
  top: calc(30% + 5px);
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 4px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
.login-page .multisteps-form__progress-btn-ins:after {
  position: absolute;
  top: calc(30% + 5px);
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 4px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}
.login-page .multisteps-form__progress-btn:first-child:after {
  display: none;
}
.login-page .multisteps-form__progress-btn-ins:first-child:after {
  display: none;
}
.login-page .multisteps-form__progress-btn.js-active {
  color: #0080ce;
}
.login-page .multisteps-form__progress-btn-ins.js-active-ins {
  color: #0080ce;
}
.login-page .multisteps-form__progress-btn.js-active:before {
  -webkit-transform: translateX(-50%) scale(1.2);
  transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
}
.login-page .multisteps-form__progress-btn-ins.js-active-ins:before {
  -webkit-transform: translateX(-50%) scale(1.2);
  transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
}
.login-page .multisteps-form__form {
  position: relative;
  height: auto !important;
  margin-bottom: 2rem;
  overflow: hidden;
}
.login-page .multisteps-form__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.login-page .multisteps-form__panel.js-active {
  height: auto;
  opacity: 1;
  visibility: visible;
  position: relative;
}
.login-page .multisteps-form__panel[data-animation=scaleIn] {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.login-page .multisteps-form__panel[data-animation=scaleIn].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.login-page input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.login-page input[type=checkbox] {
  height: 21px;
  width: 21px;
  background: #fff;
  border-radius: 2px;
  border: 2px solid #838d93b8;
  margin-left: -25px;
  top: -3px;
  position: relative;
  z-index: 22;
  vertical-align: top;
}
.login-page input[type=checkbox]:checked {
  background: #ffffff;
}
.login-page input[type=checkbox]:checked:before {
  font-family: FontAwesome;
  content: "";
  display: block;
  color: #0080ce;
  font-size: 17px;
  position: absolute;
  top: -6px;
  height: 21px;
  width: 21px;
}
.login-page .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 26px auto;
}
.login-page .avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 146px;
}
.login-page .avatar-upload .avatar-edit input {
  display: none;
}
.login-page .avatar-upload .avatar-edit input + label {
  content: url(../../img/icons/taking-pictures-circle-alternate.svg);
  object-fit: none;
  display: inline-block;
  width: 42px;
  height: 42px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.login-page .avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.login-page .avatar-upload .avatar-edit input + label:after {
  content: "";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.login-page .avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.login-page .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.login-page ul.planlist {
  display: flex;
  padding: 0px 14px;
}
.login-page ul.planlist li {
  list-style: none;
  flex: 1;
}
.login-page ul.planlist li:last-child {
  border-right: 1px solid #ddd;
}
.login-page ul.planlist li.active button {
  background: #f5f5f5;
  color: #000;
}
.login-page ul.planlist button {
  width: 100%;
  border: 1px solid #ddd;
  border-right: 0;
  border-top: 0;
  padding: 0.625rem;
  background: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  color: #999;
}
.login-page table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}
.login-page .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #343434;
  border-collapse: separate;
  border-spacing: 17px 0px;
}
.login-page th {
  background: #f5f5f5;
  display: none;
}
.login-page td,
.login-page th {
  height: 53px;
}
.login-page td,
.login-page th {
  padding: 10px;
  empty-cells: show;
}
.login-page td,
.login-page th {
  text-align: left;
}
.login-page td + td,
.login-page th + th {
  text-align: center;
  display: none;
}
.login-page td.default {
  display: table-cell;
}
.login-page .sep {
  background: #f5f5f5;
  font-weight: bold;
}
.login-page .txt-l {
  font-size: 28px;
  font-weight: bold;
}
.login-page .txt-top {
  position: relative;
  top: -9px;
  left: -2px;
}
.login-page .tick {
  font-size: 18px;
  color: #2ca01c;
}
.login-page button#plan-standard {
  color: #000;
  font-size: 0.9rem;
}
.login-page .btn-outline-dark:hover {
  color: #ffffff !important;
  background-color: #343434;
  border-color: #343434;
}
.login-page .standard-heitz {
  border-top: solid 1px #e6e6e6;
}
.login-page .plan-heitz {
  font-size: 13px !important;
}
@media (min-width: 991px) {
  .login-page article {
    width: 79% !important;
  }
  .login-page .login-page .submit-btn-plan {
    font-size: 10px;
  }
}
@media (max-width: 767px) {
  .login-page .login-page .submit-btn-plan {
    font-size: 10px !important;
  }
  .login-page article {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .login-page article {
    width: 100% !important;
  }
  .login-page .login-page .submit-btn-plan {
    font-size: 10px !important;
  }
}
@media (min-width: 768px) {
  .login-page ul.planlist {
    display: none;
  }
  .login-page td,
.login-page th {
    display: table-cell !important;
  }
  .login-page td,
.login-page th {
    width: 330px;
  }
  .login-page td + td,
.login-page th + th {
    width: auto;
  }
}
.login-page th.bg-purple {
  background: none;
}
.login-page td.default {
  /* border-radius: 25px; */
  background-color: #f5f5f5;
  font-size: 17px;
  font-weight: 500;
}
.login-page td.default.price-plan {
  padding-top: 27px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.login-page td.default.last-default-plan {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.login-page td.plan-text {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
}
.login-page article {
  width: 79%;
  max-width: 1000px;
  margin: 83px 0px 3px 0px;
  height: 1000px;
  position: relative;
}
.login-page th.bg-purple {
  font-family: AntiqueOliveStd-Nord;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
}
.login-page th.bg-blue.default {
  font-family: AntiqueOliveStd-Nord;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  color: var(--blue);
  font-style: italic;
}
.login-page .txt-top {
  position: relative;
  top: -14px;
  left: 2px;
  font-family: AntiqueOliveStd-Nord;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
}
.login-page .txt-l {
  font-size: 28px;
  font-weight: bold;
  font-family: AntiqueOliveStd-Nord;
  font-size: 31px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
}
.login-page span.month-plan {
  font-family: AntiqueOliveStd;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
}
.login-page .premium {
  background-color: #ffff;
}
.login-page .login-page .submit-btn-plan {
  display: block;
  width: 100%;
  margin: 0 auto 1rem;
  height: 52px;
  border-radius: 1.5rem;
  font-family: AntiqueOliveStd-Nord;
  font-size: 0.8125rem;
  font-style: italic;
  text-transform: uppercase;
}
.login-page input#num_tel {
  padding-left: 91px !important;
  border: 1.5px solid #cacaca;
  border-radius: 22px;
  height: 45px;
}
.login-page span#error-msg {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  font-size: 14px;
  text-align: start;
  font-weight: 600;
}
.login-page span#error-msg-check-phone {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  font-size: 14px;
  text-align: start;
  font-weight: 600;
}
.login-page span#error-msg-valid {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  font-size: 14px;
  text-align: start;
  font-weight: 600;
}
.login-page #valid-msg {
  color: #00c900;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  text-align: start;
  font-weight: 600;
}
.login-page td.standard {
  border-right: solid 1px #e6e6e6;
  border-left: solid 1px #e6e6e6;
  font-size: 17px;
  font-weight: 500;
}
.login-page td.standard.standard-btn {
  border-bottom: solid 1px #e6e6e6;
}
.login-page td.standard.price-standard {
  padding-top: 27px;
  border-top: solid 1px #e6e6e6;
}
.login-page .iti.iti--allow-dropdown.iti--separate-dial-code {
  width: 100%;
}
.login-page .iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
.login-page [data-toggle=collapse] .fa:before {
  content: "";
  font-size: 24px;
  margin-right: 11px;
  color: #0080ce;
}
.login-page [data-toggle=collapse].collapsed .fa:before {
  content: "";
  font-size: 24px;
  margin-right: 11px;
  color: #0080ce;
}
.login-page input::-webkit-outer-spin-button,
.login-page input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login-page .iti__country-list {
  position: absolute;
  z-index: 25 !important;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #ccc;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.login-page input[type=number] {
  -moz-appearance: textfield;
}
.login-page span.unit-cut {
  position: absolute;
  right: 17px;
  top: 11px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  z-index: 4;
}
.login-page .form-check.text-center.checkCondition {
  position: relative;
  left: 87px;
}
.login-page .form-check.text-center.checkConfidentiality {
  position: relative;
  left: 41px;
}
.login-page input[name=politique]:checked:before {
  font-family: FontAwesome;
  content: "";
  display: block;
  color: #0080ce;
  font-size: 17px;
  position: absolute;
  top: -6px;
}

.card-wallet {
  border: none;
  border-radius: 6px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.12);
  background-color: #343434;
}
.card-wallet .text {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.card-wallet .solde {
  width: 129px;
  height: 40px;
  border-radius: 22.5px;
  color: #ffffff;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #ffffff;
}
.card-wallet .solde span.balance-wallet {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  color: #0080ce;
}
.card-wallet .solde sup {
  top: 0px;
  color: #0080ce;
  font-size: 18px;
  font-weight: 600;
}

.card-wallet-body {
  width: 100%;
  height: auto;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}
.card-wallet-body .form-check {
  padding: 0;
}
.card-wallet-body .wallet-box:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url(../../../img/icons/cash.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card-wallet-body .text-body-wallet {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.375rem;
  font-style: italic;
  letter-spacing: normal;
  text-transform: uppercase;
  color: var(--gray-900);
}
.card-wallet-body hr.line-wallet {
  width: 310px;
  max-width: 100%;
  border: solid 2px var(--blue);
}
.card-wallet-body .text-body-wallet-recharge {
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #343434;
}
.card-wallet-body button.btn.btn-primary {
  height: 50px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  border-radius: 24px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}
.card-wallet-body button.btn.btn-outline-primary {
  min-height: 50px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  border-radius: 24px;
  font-size: 15px;
  font-style: italic;
  text-align: center;
  color: #0080ce;
}
.card-wallet-body button.btn.btn-outline-primary:hover {
  height: 50px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  border-radius: 24px;
  font-size: 15px;
  font-style: italic;
  text-align: center;
  color: #ffff;
}
.card-wallet-body .amount-wallet {
  max-width: 269px;
  position: relative;
}
.card-wallet-body .amount-wallet .form-control {
  padding: 0.375rem 1.5rem;
}
.card-wallet-body .amount-wallet .currency-amount-wallet {
  bottom: 10px;
  right: 18px;
  z-index: 1;
  position: absolute;
  opacity: 0.5;
  font-size: 16px;
}
.card-wallet-body .amount-wallet .amount-wallet {
  border: none;
  border-radius: 22.5px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}
.card-wallet-body input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.checkout {
  margin: auto;
}
.checkout img.activity-checkout {
  margin: 4.2px;
  width: 18px;
  height: 13px;
}
.checkout td.name-checkout.roundleft {
  font-size: 13px;
}
.checkout button.btn.btn-primary.btn-block.paiement-checkout {
  height: 48px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 13px;
  border-radius: 25px;
  font-style: italic;
  text-align: center;
  color: var(--white);
}
.checkout img.coach-checkout {
  border-radius: 25px;
  height: 25px;
  width: 25px;
  border: solid 1px #dddddd;
  object-fit: cover;
}
.checkout span.name-coach {
  font-size: 14px;
  font-weight: 500;
  margin-left: 0.2rem;
}
.checkout .type-checkout-payment li {
  position: relative;
  list-style-type: none;
}
.checkout .check {
  position: absolute;
  top: 28px;
  left: 18px;
}
.checkout .cart {
  position: absolute;
  top: 23px;
  left: 203px;
}
.checkout a.recharge-wallet {
  font-size: 13px;
  font-weight: 500;
  color: var(--gray-900);
}
.checkout .badge-wallet {
  background-color: #e6f3fa;
  /* opacity: 0.1; */
  min-width: 84px;
  height: 25px;
  border-radius: 12.5px;
  color: #0080ce;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  top: 27px;
  right: 1px;
}
.checkout ul.type-checkout-payment {
  padding: 0;
  width: 100%;
  margin: 0;
}
.checkout ul.type-checkout-payment li.active {
  border-radius: 7px;
  height: 80px;
  border: solid 2px var(--blue);
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: var(--blue);
}
.checkout ul.type-checkout-payment li.inactive {
  border-radius: 7px;
  height: 80px;
  border: solid 2px #c0cbd2;
  font-size: 15px;
  font-weight: 600;
  color: var(--gray-900);
}
.checkout .purchase {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 20px;
  font-style: italic;
  color: var(--gray-900);
  margin-bottom: 7px;
}
.checkout p.checkout-purchase-card {
  font-size: 15px;
  font-weight: 600;
  margin-top: 0.5rem;
  color: var(--blue);
}
.checkout hr.purchase-hr {
  margin: -4px;
  width: 161px;
  border: solid 2px var(--blue);
}
.checkout td.roundleft {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  -moz-border-radius-topleft: 11px;
  /* Firefox top left corner */
  -moz-border-radius-bottomleft: 11px;
  /* Firefox bottom left corner */
}
.checkout td.roundright {
  -moz-border-radius-topright: 11px;
  /* Firefox top right corner */
  -moz-border-radius-bottomright: 11px;
  /* Firefox bottom right corner */
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}
.checkout .card.checkout-card {
  border-radius: 11px;
  padding: 1rem;
}
.checkout .remise-checkout {
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);
}
.checkout .total-checkout {
  font-size: 14px;
  font-weight: bold;
  color: var(--gray-900);
}
.checkout .checkout-total {
  font-size: 19px;
  font-weight: bold;
  color: var(--gray-900);
}
.checkout .table-checkout {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.checkout .table-checkout thead {
  height: 14px;
}
.checkout .table-checkout thead tr th.text-checkout {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-bottom: 0;
  color: #9ba0a3;
}
.checkout .table-checkout tr.data-checkout {
  height: 71px;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white);
}
.checkout .table-checkout tr.data-checkout td {
  vertical-align: middle;
}
.checkout .table-checkout tr.data-checkout td.name-checkout.sorting_1 {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray-900);
}
.checkout .table-checkout tr.data-checkout td.date-checkout {
  font-size: 12px;
  font-weight: 500;
  color: var(--gray-900);
}
.checkout .table-checkout tr.data-checkout .info {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-900);
}
.checkout .table-checkout tr.data-checkout td.type-checkout {
  font-size: 13px;
  font-weight: 500;
  color: var(--gray-900);
}
.checkout .table-checkout tr.data-checkout p.price {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  color: #0080ce;
  white-space: nowrap;
}
.checkout .table-checkout tr.data-checkout span.price {
  font-size: 11px;
  font-weight: bold;
  color: #0080ce;
}
.checkout .five-session-pack {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1.375rem;
  font-style: italic;
  color: #0080ce;
}
.checkout .text-basket-process.text-center {
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;
  text-align: center;
}

#dropdownBasket:hover,
#loggedUser:hover {
  cursor: pointer;
}

img.activity-checkout {
  margin: 4.2px;
  width: 22px !important;
  height: 20px;
}

section .payment .table th {
  border: none;
}
section .payment .table td {
  border: none;
}
section .payment .table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
section .payment .odd {
  width: 100%;
  height: 50px;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(161, 161, 161, 0.11);
  background-color: var(--white);
  border-spacing: 5em;
}
section .payment .event {
  width: 100%;
  height: 50px;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(161, 161, 161, 0.11);
  background-color: var(--white);
  border-spacing: 5em;
}
section .payment .tr-odd {
  box-shadow: none !important;
}
section .payment .table th, section .payment .table td {
  border-top: none;
}
section .payment .table thead th {
  vertical-align: bottom;
  border: none;
}
section .payment tr td:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
section .payment tr td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
section .payment div#payment-table_filter {
  float: right;
}
section .payment div#payment-table_filter input[type=search] {
  padding-left: 2rem;
  width: 223px;
  height: 39px;
  border-radius: 19.5px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
  border: #ffff;
  background-color: var(--white);
}

section.dashboard-coach .dataTables_wrapper {
  overflow-x: auto;
  padding: 5px;
}
section.dashboard-coach pre.text-plan {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px !important;
  color: #343434 !important;
}
section.dashboard-coach span.session-coach-upcoming {
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #0080ce;
}
section.dashboard-coach .availability-coach .fc-unthemed tbody td span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  float: none !important;
  height: 40px;
  border-radius: 3.6px;
  background: none;
  font-size: 1rem;
  font-weight: 600;
}
section.dashboard-coach .availability-coach .fc-unthemed td.fc-today span {
  color: #ffffff;
  height: 36px;
  background-color: #0080ce;
  border-radius: 10px;
}
section.dashboard-coach .availability-coach .fc-dayGrid-view .fc-body .fc-row {
  height: 53px !important;
}
section.dashboard-coach .availability-coach span.fc-day-number {
  color: rgba(52, 52, 52, 0.5);
}
section.dashboard-coach .availability-coach .fc-button-primary {
  color: #0080ce;
  background-color: #ffffff;
  border-color: #ffffff;
}
section.dashboard-coach .availability-coach .fc-toolbar h2 {
  font-size: 1.0375rem;
  font-weight: 600;
  text-transform: capitalize;
}
section.dashboard-coach .availability-coach .fc-button-primary:hover {
  color: #0080ce;
  background-color: #fff;
  border-color: #fff;
}
section.dashboard-coach .availability-coach td.fc-widget-content {
  position: relative;
  right: 6px;
}
section.dashboard-coach .list-payment-coach p.gain-coach {
  height: 18px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #0080ce;
}
section.dashboard-coach .list-payment-coach .gain {
  border-radius: 9px;
  background-color: #343434;
  height: 61px;
  color: #ffff;
  margin-top: 26px;
}
section.dashboard-coach .list-payment-coach .month-gain {
  line-height: 48px;
  padding-right: 14px;
  padding-left: 14px;
}
section.dashboard-coach .list-payment-coach .month-gain .month {
  font-size: 1.125rem;
  font-weight: 600;
}
section.dashboard-coach .list-payment-coach .month-gain .cg-month-gain {
  font-size: 1.5rem;
  font-weight: bold;
}
section.dashboard-coach .list-payment-coach .price {
  font-size: 1rem;
  font-weight: 600;
}
section.dashboard-coach .list-payment-coach .price .DT {
  font-size: 0.875rem;
  font-weight: 500;
}
section.dashboard-coach .card.past-session, section.dashboard-coach .card.future-session {
  padding: 1rem;
}
section.dashboard-coach .card.past-session .list-session td,
section.dashboard-coach .card.past-session .list-session th, section.dashboard-coach .card.future-session .list-session td,
section.dashboard-coach .card.future-session .list-session th {
  border-collapse: separate;
  border: none;
}
section.dashboard-coach .card.past-session span.type-session, section.dashboard-coach .card.future-session span.type-session {
  position: relative;
  bottom: 5px;
  margin-left: 0.2rem;
}
section.dashboard-coach .card.past-session p.session-coach-past, section.dashboard-coach .card.future-session p.session-coach-past {
  height: 18px;
  font-size: 0.9375rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--blue);
}
section.dashboard-coach .card.past-session table.table.list-session, section.dashboard-coach .card.future-session table.table.list-session {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 0.45rem;
}
section.dashboard-coach .card.past-session table.table.list-session .th-coach, section.dashboard-coach .card.future-session table.table.list-session .th-coach {
  height: 15px;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9ba0a3;
  padding: 0.75rem 5px 0.75rem 2rem;
}
@media (max-width: 767.98px) {
  section.dashboard-coach .card.past-session table.table.list-session .th-coach, section.dashboard-coach .card.future-session table.table.list-session .th-coach {
    padding: 0.75rem 2px 0.75rem 2px;
  }
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type, section.dashboard-coach .card.future-session table.table.list-session tr.session-type {
  height: 50px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  border: solid 1px rgba(0, 128, 206, 0.11);
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach, section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach {
  padding-left: 2rem;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach.date-coach, section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach.date-coach {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: normal;
  color: var(--gray-900);
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(1), section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(3), section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(5), section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(1), section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(3), section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(5) {
  white-space: nowrap;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(1) span, section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(3) span, section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(5) span, section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(1) span, section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(3) span, section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach.date-coach:nth-of-type(5) span {
  white-space: normal;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach .avatars, section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach .avatars {
  display: inline-block;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach .avatars img.avatar, section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach .avatars img.avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #ffffff;
  margin-left: -12px;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type .td-coach span.badge.more, section.dashboard-coach .card.future-session table.table.list-session tr.session-type .td-coach span.badge.more {
  width: 30px;
  height: 30px;
  border-radius: 25px;
  margin-left: -12px;
  border: solid 2px var(--white);
  background-color: #c4d3dc;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type td:first-child, section.dashboard-coach .card.future-session table.table.list-session tr.session-type td:first-child {
  border-top-left-radius: 10px;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type td:last-child, section.dashboard-coach .card.future-session table.table.list-session tr.session-type td:last-child {
  border-top-right-radius: 10px;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type td:first-child, section.dashboard-coach .card.future-session table.table.list-session tr.session-type td:first-child {
  border-bottom-left-radius: 10px;
}
section.dashboard-coach .card.past-session table.table.list-session tr.session-type td:last-child, section.dashboard-coach .card.future-session table.table.list-session tr.session-type td:last-child {
  border-bottom-right-radius: 10px;
}
section.dashboard-coach .coachCalendar tr > td > .fc-day-grid-event {
  z-index: -1;
  height: 6px;
  width: 4px;
  display: inline-block;
  margin: 0 0 0 -2px;
  position: absolute;
  bottom: 8px;
  left: 50%;
  border: 0;
}
section.dashboard-coach img.coach-activity-dashboard {
  width: 25px;
  height: 20px;
}
section.dashboard-coach ul.pagination {
  margin: 0;
}
section.dashboard-coach div#list-session-past_paginate {
  text-align: center;
  margin-top: 0rem;
}
section.dashboard-coach a.paginate_button {
  margin: 1rem;
  cursor: pointer;
}
section.dashboard-coach a.paginate_button.current {
  display: inline-grid;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 43px;
  height: 43px;
  border: none;
  border-radius: 50%;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1875rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #0080ce;
  border-color: #0080ce;
}
section.dashboard-coach a#list-session-past_previous {
  display: inline-flex;
}
section.dashboard-coach a.paginate_button {
  font-size: 16.6px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--blue);
}
section.dashboard-coach a#list-session-past_next {
  border: solid 0.8px var(--blue);
  display: inline-grid;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
}

.card.list-coach-dispo {
  min-height: 100px;
}

.card.list-coach-dispo .heure {
  width: 44px;
  height: 19px;
  opacity: 0.5;
  font-size: 0.9375rem;
  font-weight: 500;
  color: #434343;
}
.card.list-coach-dispo p.list-event-coach {
  padding: 25px;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--blue);
}
.card.list-coach-dispo .title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #040404;
}
.card.list-coach-dispo .color-list-coach {
  position: absolute;
  bottom: 1px;
  width: 12px;
  left: 1px;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  height: 100%;
}
.card.list-coach-dispo .activity-name {
  margin-left: 30px;
  font-family: AntiqueOliveStd;
  text-transform: uppercase;
  font-weight: 700;
}
.card.list-coach-dispo .activity-name-adherent {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}
.card.list-coach-dispo .availability-icon-coach {
  position: absolute;
  right: 25px;
}

p.list-event-coach {
  font-size: 1.0625rem;
  font-weight: 500;
  padding: 6px;
  letter-spacing: normal;
  color: var(--blue);
}

p.no-disponiblity-coach {
  /*padding: 10rem;*/
  font-size: 1.0625rem;
  font-weight: 600;
  color: #040404;
}

.calendar.calendar-coach .fc-unthemed tbody td span {
  width: 100%;
  height: 28px;
}
.calendar.calendar-coach .fc-unthemed tbody td span {
  font-size: 0.85rem;
  color: #ffffff;
  margin-left: 7px;
  line-height: 30px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: #343434;
}
.calendar.calendar-coach span.fc-title {
  justify-content: stretch;
  padding-left: 6px;
}
.calendar.calendar-coach .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
  justify-content: space-evenly;
}
.calendar.calendar-coach th.fc-day-header.fc-widget-header span {
  opacity: 0.8;
  font-size: 0.7688rem;
  font-weight: 500;
  text-align: center;
  color: var(--gray-900);
}
.calendar.calendar-coach .fc-button-primary {
  color: #fff;
  background-color: #ffff;
  border-color: #ffff;
}

.pos-icon-coach.mr-1 {
  position: relative;
  top: 3px;
}

.calendar-coach-dashboard button.fc-addEventButton-button.fc-button.fc-button-primary {
  padding: 0.75rem 2rem;
  margin-bottom: 0.5rem;
  border-radius: 1.5rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif !important;
  font-size: 0.75rem !important;
  font-style: italic;
  text-transform: uppercase;
}
.calendar-coach-dashboard .fc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendar-coach-dashboard .fc-toolbar h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1687rem;
  font-weight: 600;
  color: #343434;
  margin-right: 1.2rem;
}
.calendar-coach-dashboard .fc-toolbar .fc-button-primary {
  color: #0080ce;
  background-color: #ffff;
  border-color: #ffff;
}
.calendar-coach-dashboard th.fc-day-header.fc-widget-header {
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
  font-size: 1.0813rem;
  font-weight: 500;
}
.calendar-coach-dashboard .fc-dayGrid-view .fc-week-number,
.calendar-coach-dashboard .fc-dayGrid-view .fc-day-number {
  padding: 20px;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9875rem;
  font-weight: 700;
  color: #343434;
}
.calendar-coach-dashboard .fc-other-month {
  background-color: #f7f7f7;
}
.calendar-coach-dashboard td.fc-day-top.fc-tue.fc-today .fc-day-number {
  background: #0080ce;
  opacity: 1;
  color: #ffff;
  margin: 4px;
  width: 11px;
  padding: 8px 16px 19px 12px;
  position: relative;
  top: 9px;
  height: 11px;
  border-radius: 11px;
}
.calendar-coach-dashboard td.fc-today {
  background: #ffffff;
}
.calendar-coach-dashboard .fc th {
  border-style: none !important;
}
.calendar-coach-dashboard button.fc-addEventButton-button.fc-button.fc-button-primary {
  color: #ffff;
  background-color: #0080ce;
  border-color: #0080ce;
  border-radius: 25px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}
.calendar-coach-dashboard a.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end {
  height: 30px;
  margin: 1px 6px 2px 6px;
  border-radius: 3.4px;
}
.calendar-coach-dashboard .fc-day-grid-event .fc-time {
  font-weight: 700;
  color: #ffff;
  position: absolute;
  top: 5px;
  left: 7px;
}
.calendar-coach-dashboard .fc-title {
  position: relative;
  top: 5px;
  left: 35px;
  color: #ffff;
  font-weight: 600;
  font-size: 0.9375rem;
  cursor: pointer;
}
.calendar-coach-dashboard .fc-content {
  height: 34px;
  cursor: pointer;
}
.calendar-coach-dashboard .fc-unthemed .fc-disabled-day {
  background: #ffff;
  opacity: 0.3;
}

/*
.section-title {
    position: relative;
    display: inline-block;
    font-family: $antique;
    font-size: 1.5625rem;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;
    color: #343434;
    padding-bottom: 0.5rem;
    margin-bottom: 1.75rem;
}
*/
label.start {
  font-size: 1rem;
  margin-left: 0.9rem;
  color: var(--gray-900);
}

.error {
  margin-bottom: 1rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 1rem;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0080ce;
}

.multipe-selection {
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-900);
  cursor: pointer;
}

.config-week {
  margin: 25px 26px 21px 144px;
}

.week {
  margin: 1rem;
}

.flatpickr-calendar {
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-bottom: 4rem;
}

.card.plus {
  width: 44px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
  padding: 14px;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border: 1px solid #e6e6e6;
}

.checkbox {
  margin-left: 4rem;
}

.trash-time {
  position: relative;
  right: 16px;
  top: 5px;
}

.date-ranage-title {
  display: block;
  font-size: 0.9375rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.pagination li.page-item:first-child {
  display: none;
}

.title-calendar {
  display: inline-flex;
}
.title-calendar .grid-button {
  position: absolute;
  display: flex;
  align-items: center;
  right: 14px;
  width: 9.875rem;
  height: 39px;
  border: 1px solid #DADADA;
  border-radius: 19.5px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.title-calendar .grid-button .btn-active {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.116259);
  border-radius: 19.5px;
  color: var(--blue);
  width: 5.8125rem;
  height: 33px;
}
.title-calendar .grid-button .btn-desactive {
  background: none;
  color: var(--gray-900);
  opacity: 0.6;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  margin-left: 16px;
  width: 4.8125rem;
}

.calendar .sessions-container {
  max-height: 398px;
  overflow-y: auto;
}
.calendar .sessions-container .palinify-session {
  height: 60px;
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
  border-radius: 7px;
  cursor: pointer;
  border: solid 1.5px var(--blue);
  background-color: #f5f6f6;
}
.calendar .sessions-container .palinify-session .icon {
  position: absolute;
  margin-left: 22px;
}
.calendar .sessions-container .palinify-session::before {
  content: "";
  width: 2.625rem;
  height: 46px;
  border-radius: 8px;
  background-color: var(--white);
  margin: 8px 7px 6px 9px;
}
.calendar .sessions-container .palinify-session:hover {
  border-style: dashed;
}
.calendar .sessions-container .palinify-session .title {
  font-size: 13px;
  color: #5292e1;
}
.calendar .sessions-container ul {
  list-style: none;
  padding-left: 0;
  text-align: center;
}
.calendar .sessions-container ul li {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.calendar .sessions-container ul li .block-session {
  display: flex;
  height: 60px;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(161, 161, 161, 0.11);
  background-color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.calendar .sessions-container ul li .block-session .heure {
  opacity: 0.5;
  color: #434343;
  margin-left: 23px;
  margin-top: 11px;
}
.calendar .sessions-container ul li .block-session .title {
  font-size: 14px;
  font-weight: 600;
  color: #040404;
  margin-left: 14px;
  margin-top: 11px;
  text-align: initial;
}
.calendar .sessions-container ul li .block-session .title .coach {
  font-size: 12px;
  font-weight: normal;
  color: var(--gray-900);
  margin-top: 5px;
  text-align: initial;
}
.calendar .sessions-container ul li .block-session::before {
  content: "";
  width: 10px;
  height: 60px;
  border-radius: 43px 0px 0px 43px;
  background-color: var(--blue);
}
.calendar .fc-button-primary {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.calendar .p-calendar {
  position: relative;
  top: 3px;
}
.calendar .p-icon-calendar {
  position: relative;
  top: 13px;
  left: 8px;
}
.calendar div.bg-white.pt-3.fc.fc-ltr.fc-unthemed {
  border-radius: 13px;
}
.calendar .fc-dayGrid-view .fc-body .fc-row {
  min-height: 6.5em;
}
.calendar .fc-other-month {
  background-color: #f7f7f7;
}
.calendar .fc-day-top.disable-day-calendar {
  position: relative;
  color: #bbbbbb;
}
.calendar .fc-dayGrid-view .fc-week-number,
.calendar .fc-dayGrid-view .fc-day-number {
  padding: 13px;
  opacity: 0.5;
  font-size: 13.8px;
  font-weight: 600;
  color: var(--gray-900);
}
.calendar td.fc-more-cell {
  top: 80px;
  font-size: 14.7px;
  font-weight: 600;
}
.calendar .fc-header.fc-widget-header {
  border-radius: 14px;
}
.calendar .fc-popover.fc-more-popover {
  border-radius: 12px;
}
.calendar .sessions-container {
  max-height: 398px;
  overflow-y: auto;
}
.calendar .sessions-container .palinify-session {
  height: 60px;
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
  border-radius: 7px;
  border: solid 1.5px #0080ce;
  border-style: dashed;
  background-color: #f5f6f6;
}
.calendar .sessions-container .palinify-session .icon {
  position: absolute;
  margin-left: 22px;
}
.calendar .sessions-container .palinify-session::before {
  content: "";
  width: 42px;
  height: 46px;
  border-radius: 8px;
  background-color: var(--white);
  margin: 8px 7px 6px 9px;
}
.calendar .sessions-container .palinify-session .title {
  font-size: 13px;
  color: #5292e1;
}
.calendar .sessions-container ul {
  list-style: none;
  padding-left: 0;
  text-align: center;
}
.calendar .sessions-container ul li {
  margin-bottom: 1rem;
}
.calendar .sessions-container ul li .block-session {
  display: flex;
  height: 80px;
  border-radius: 11px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(161, 161, 161, 0.11);
  background-color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.calendar .sessions-container ul li .block-session .heure {
  opacity: 0.5;
  color: #434343;
  margin-left: 23px;
  margin-top: 11px;
}
.calendar .sessions-container ul li .block-session .title {
  font-size: 14px;
  font-weight: 600;
  color: #040404;
  margin-left: 14px;
  margin-top: 11px;
}
.calendar .sessions-container ul li .block-session .title .coach {
  font-size: 12px;
  font-weight: normal;
  color: var(--gray-900);
}
.calendar .sessions-container ul li .block-session::before {
  content: "";
  width: 10px;
  height: 80px;
  border-radius: 43px 0px 0px 43px;
  background-color: var(--blue);
}
.calendar .fc-day-top.disable-day-calendar {
  position: relative;
  color: #bbbbbb;
}
.calendar .fc-toolbar h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 18.6px;
  font-weight: 600;
  text-transform: capitalize;
}
.calendar tr:first-child > td > .fc-day-grid-event {
  position: relative;
  top: 0px;
}
.calendar button.fc-prev-button.fc-button.fc-button-primary {
  color: #0080ce;
}
.calendar button.fc-next-button.fc-button.fc-button-primary {
  color: #0080ce;
}
.calendar .fc-unthemed tbody td span {
  font-size: 0.85rem;
  color: #ffffff;
  background: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.calendar td {
  border-color: #ddd;
}
.calendar .fc-day-grid-event .fc-time {
  display: none;
}
.calendar .fc-toolbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.calendar .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}
.calendar .fc-event {
  background: #343434;
  color: #ffffff;
  border-radius: 5px;
  border: 0px solid #343434;
}
.calendar .fc-event:hover {
  color: #ffffff;
}
.calendar .fc-event .fc-content {
  display: flex;
  justify-content: center;
}
.calendar .fc-event .fc-content:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 6px;
  height: 23px;
  background: transparent;
  border-radius: 5px 0px 0px 5px;
}
.calendar .fc-dayGrid-view .fc-body .fc-row {
  min-height: 7rem;
}
@media (min-width: 768px) {
  .calendar .fc-unthemed tbody td span {
    width: 100%;
    height: 26px;
  }
}

.palinification-session-modal {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.palinification-session-modal .title-icon {
  width: 23px;
  height: 10px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 10px;
  opacity: 0.3;
  font-style: italic;
  color: var(--gray-900);
}
.palinification-session-modal .title {
  font-size: 18px;
  text-align: center;
  color: var(--gray-900);
}
.palinification-session-modal .title-day {
  font-size: 24px;
  text-align: center;
  color: var(--blue);
}
.palinification-session-modal .pack-card {
  width: 20.1875rem;
  height: 150px;
  border-radius: 9px;
  background-color: #f5f6f6;
  cursor: pointer;
}
.palinification-session-modal .pack-card.selected {
  box-shadow: 0 13px 28px 0 rgba(0, 0, 0, 0.12);
  border: solid 3px var(--blue);
}
.palinification-session-modal .icon {
  width: 22px;
  height: 13px;
  object-fit: contain;
  opacity: 0.3;
}
.palinification-session-modal .pack-title {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 13px;
  font-style: italic;
  line-height: 1.62;
  letter-spacing: normal;
  font-weight: 500;
  color: var(--gray-900);
}
.palinification-session-modal .coach {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-900);
}
.palinification-session-modal .coach img {
  width: 24px;
  height: 24px;
  border: solid 1px #dddddd;
}
.palinification-session-modal .remaining-session {
  width: 6.6875rem;
  height: 91px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  font-size: 12px;
  margin: auto;
  text-align: center;
}
.palinification-session-modal .remaining-session .number {
  font-size: 28px;
  font-weight: 600;
  color: var(--blue);
}
.palinification-session-modal .pack-session-slider.owl-theme .owl-nav {
  display: none;
}
@media (min-width: 1100px) {
  .palinification-session-modal .pack-session-slider.owl-theme .owl-nav {
    display: block;
  }
}
.palinification-session-modal .pack-session-slider .owl-nav button.owl-prev {
  position: absolute;
  top: 62px;
  left: -56px;
  width: 20px;
}
.palinification-session-modal .pack-session-slider .owl-nav button.owl-next {
  position: absolute;
  top: 62px;
  right: -40px;
  width: 20px;
}
.palinification-session-modal .pack-session-slider .owl-nav button.owl-next:hover {
  background: transparent;
}
.palinification-session-modal .pack-session-slider .owl-nav .owl-prev.disabled,
.palinification-session-modal .pack-session-slider .owl-nav .owl-next.disabled {
  opacity: 0.6;
}
.palinification-session-modal .pack-session-slider .owl-nav button.owl-prev {
  position: absolute;
  top: 62px;
  right: -40px;
  width: 20px;
}
.palinification-session-modal .pack-session-slider .owl-nav button.owl-prev:hover {
  background: transparent;
}
.palinification-session-modal .pack-session-slider .nav-prev {
  width: 40px;
  height: 40px;
  opacity: 0.6;
}
.palinification-session-modal .pack-session-slider .nav-next {
  width: 40px;
  height: 40px;
}
.palinification-session-modal .times {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
}
.palinification-session-modal .times .time {
  padding: 8px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.palinification-session-modal .times .time.selected {
  box-shadow: 0 8px 9px 0 rgba(0, 0, 0, 0.14);
  border: solid 2px var(--blue);
  background-color: var(--white);
  color: var(--blue);
}
.palinification-session-modal .times .time .icon {
  width: 20px;
  height: 20px;
}
.palinification-session-modal .validation {
  width: 19.875rem;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 4px 10px 0 rgba(3, 109, 173, 0.3);
  background-color: var(--blue);
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 13px;
}

.message-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 18px;
  color: var(--gray-900);
}

.error-container {
  text-align: center;
}
.error-container h2 {
  font-size: 0.9375rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}
.error-container .error-icon {
  padding: 20px 40px;
}
@media (min-width: 576px) {
  .error-container .error-icon {
    width: 600px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 639px) {
  .error-container .error-message {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 518px) {
  .error-container .error-message {
    font-size: 1.5rem;
  }
}

.nutri-pack-badge {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.084599);
  border-radius: 8.5px;
  font-family: AntiqueOliveStd;
  font-size: 10px;
  line-height: 12px;
  margin-left: 13px;
  color: #0080ce;
  padding: 4px 0.4em !important;
  font-weight: 500;
}

.cn_text,
.cn_icon {
  opacity: 0.3;
}

.nutrition-bg {
  text-align: right;
}
@media (max-width: 991.98px) {
  .nutrition-bg {
    overflow-x: hidden;
  }
}
.nutrition-bg:before {
  content: "";
  position: absolute;
  top: 15px;
  left: -80px;
  width: 520px;
  height: 200px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  background: linear-gradient(224.19deg, #ecefff 14.13%, #f3f6f8 96.28%);
  transform: skewX(24deg);
}

.contact-description {
  position: relative;
  background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
  padding-top: 30px;
  padding-bottom: 84px;
}

span.help-block {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.cms-page .hero-header {
  margin-bottom: 60px;
}
.cms-page .hero-header h1.title {
  margin-top: 60px;
}

.cms-page * {
  font-family: unset;
}

.adherent-header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 5rem;
  background: url("../../../img/motif2.png"), #f6f5fa;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
}
.adherent-header .job {
  display: block;
  font-family: "AntiqueOliveStd", sans-serif;
  font-size: 1.875rem;
  font-style: italic;
  line-height: 1.67;
  color: rgba(0, 128, 206, 0.5);
  text-transform: uppercase;
}
.adherent-header .name {
  display: block;
  margin-bottom: 1rem;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 2.5rem;
  font-style: italic;
  line-height: 1.25;
  color: #343434;
  text-transform: uppercase;
}
@media (max-width: 575.98px) {
  .adherent-header .name {
    font-size: 2rem;
  }
}
.adherent-header .btn {
  padding: 16px;
  border-radius: 24px;
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
}
.adherent-bg {
  text-align: right;
}
@media (max-width: 1199.98px) {
  .adherent-bg {
    overflow-x: hidden;
  }
}
.adherent-bg:before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  width: 405px;
  height: 200px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  background: linear-gradient(224.19deg, #ecefff 14.13%, #f3f6f8 96.28%);
  transform: skewX(24deg);
}
.adherent-bg > .card.mt-4 {
  padding: 1rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 1.25rem;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}
.adherent-bg > .card.mt-4 .card-img {
  height: 195px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 13px;
  transition: all 0.3s ease-in-out;
}

.adherent-info-detail .main-card {
  background-color: #ffffff;
  color: #343434;
}

.new_payment {
  margin-bottom: 1.5rem;
  font-family: "Montserrat", sans-serif;
}
.new_payment .checked {
  border: 2px solid #0080ce;
}
.new_payment .member-extra {
  border-left: 1px solid #cecece;
  border-right: 1px solid #cecece;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
}
.new_payment .last-row {
  border-left: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
}
.new_payment .product {
  font-weight: 600;
}
.new_payment .member-ship-title {
  font-style: italic;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 4rem;
  text-transform: uppercase;
}
.new_payment .first-row {
  height: 135px;
  border-left: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-top: 1px solid #cecece;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  line-height: 100px;
}
.new_payment .amount {
  font-style: italic;
  font-size: 27px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 100px;
  border-bottom: 1px solid #cecece;
}
.new_payment input[type=radio] {
  width: 12%;
  height: 1.3rem;
  position: relative;
  top: 0;
}
.new_payment button#payMembership {
  height: 46px;
  font-size: 13px;
  font-weight: 100;
  font-style: italic;
  text-transform: uppercase;
}
.new_payment .small {
  font-size: 13px;
  font-weight: 400;
  font-family: "Montserrat";
  text-transform: lowercase;
}
@media (max-width: 414px) {
  .new_payment .member-ship-title {
    font-style: italic;
    font-size: 18px !important;
    font-weight: bold;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
  .new_payment .amount {
    font-style: italic;
    font-size: 14px !important;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 100px;
    border-bottom: 1px solid #cecece;
  }
}
.new_payment .section-icon2 {
  margin-right: 20px;
}
.new_payment .banner-payment {
  background: rgba(0, 128, 206, 0.05);
  margin-bottom: 2rem;
  padding: 1rem;
}
.new_payment .banner-payment .banner-content {
  max-width: 400px;
}
.new_payment .banner-payment p {
  margin-bottom: 0;
}
.new_payment .plan-text {
  background: #f7f8fa;
  border-radius: 13px;
  padding: 2rem;
  margin-bottom: 2rem;
}
.new_payment h3 {
  font-family: "AntiqueOliveStd-Nord", sans-serif;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: #343434;
}
.new_payment .btn {
  margin-bottom: 1rem;
}
.new_payment .description {
  display: block;
  max-width: 400px;
  margin: 15px auto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #343434;
}
.new_payment .in-checked {
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 80px;
}
.new_payment .in-checked.monthly, .new_payment .in-checked.daily {
  justify-content: center;
  align-items: center;
}
.new_payment .in-checked .txt-l {
  font-style: italic;
  font-weight: 800;
  font-size: 29px;
  line-height: 20px;
  text-align: center;
  color: #0080ce;
}
.new_payment .in-checked .txt-top {
  position: relative;
  top: -12px;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #0080ce;
}
.new_payment .in-checked .month-plan {
  font-style: italic;
  font-weight: normal;
  font-size: 17px;
  color: #343434;
}
.new_payment .box-info {
  background: rgba(0, 128, 206, 0.05);
  border-radius: 13px;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0080ce;
}
.new_payment .box-info p {
  margin-bottom: 0;
}
.new_payment .pay-info {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 49px;
  color: #343434;
  padding: 0 1rem;
}
.new_payment .default {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #0080ce;
}
.new_payment .bootm-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #343434;
}
.new_payment label {
  font-size: 13px;
  color: #424242;
  font-weight: 500;
}
.new_payment .btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
}
.new_payment .btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 100%;
  height: 58px;
  background: #e0e0e0;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
}
.new_payment .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 22px;
  font-weight: 500;
  top: 14px;
  right: 0;
  width: 50%;
  text-align: center;
}
.new_payment .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 50%;
  height: 50px;
  background: #0080ce;
  border-radius: 30px;
  position: absolute;
  left: 4px;
  top: 4px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 10px 0px;
  font-size: 22px;
  color: #fff;
}
.new_payment .btn-color-mode-switch > .alert {
  display: none;
  background: #ff9800;
  border: none;
  color: #fff;
}
.new_payment .btn-color-mode-switch input[type=checkbox] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}
.new_payment .btn-color-mode-switch input[type=checkbox]:checked + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: calc(50% - 4px);
}
.new_payment .btn-color-mode-switch input[type=checkbox]:checked + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  width: 50%;
  text-align: center;
}
.new_payment .btn-color-mode-switch input[type=checkbox]:checked + label.btn-color-mode-switch-inner {
  /*background: #66BB6A; */
  /*color: #fff;*/
}
.new_payment .btn-color-mode-switch input[type=checkbox]:checked ~ .alert {
  display: block;
}
@media (min-width: 768px) {
  .new_payment td,
.new_payment th {
    width: 60%;
  }
  .new_payment td + td,
.new_payment th + th {
    width: auto;
  }
}
.new_payment label.radio-label {
  padding-left: 0;
  padding-right: 0.5rem;
}

.just-deactived {
  display: none !important;
}

.bold_blue {
  color: #0080ce;
  font-weight: bolder;
}

/*mode preview*/
.dark-preview {
  background: #0d0d0d;
}

.dark-preview div.btn-container i.fa-sun-o {
  color: #777;
}

.dark-preview div.btn-container i.fa-moon-o {
  color: #fff;
  text-shadow: 0px 0px 11px #fff;
}

.white-preview {
  background: #fff;
}

.white-preview div.btn-container i.fa-sun-o {
  color: #ffa500;
  text-shadow: 0px 0px 16px #ffa500;
}

.white-preview div.btn-container i.fa-moon-o {
  color: #777;
}

p.by a {
  text-decoration: none;
  color: #000;
}

.dark-preview p.by a {
  color: #777;
}

.white-preview p.by a {
  color: #000;
}