.adherent-header {

    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 5rem;
    background: url("../../../img/motif2.png"), #f6f5fa;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
   
    .job {
        display: block;
        font-family: $antique-regular;
        font-size: 1.875rem;
        font-style: italic;
        line-height: 1.67;
        color: rgba($blue, 0.5);
        text-transform: uppercase;
    }
    .name {
        display: block;
        margin-bottom: 1rem;
        font-family: $antique;
        font-size: 2.5rem;
        font-style: italic;
        line-height: 1.25;
        color: $gray-900;
        text-transform: uppercase;
        @include media-breakpoint-down(xs) {
            font-size: 2rem;
        }
    }
    .btn {
        
        padding: 16px;
        border-radius: 24px;
        font-family: $antique;
        font-size: 13px;
        font-style: italic;
        font-weight: bold;
        text-transform: uppercase;
    }
    .coach-img {
        // margin-bottom: -100px;
    }
}



.adherent-bg {
    text-align: right;
    @include media-breakpoint-down(lg) {
        overflow-x: hidden;
    }
    &:before {
        content: "";
        position: absolute;
        top: 15px;
        left: 0px;
        width: 405px;
        height: 200px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        background: linear-gradient(224.19deg, #ecefff 14.13%, #f3f6f8 96.28%);
        transform: skewX(24deg);
    }
    > .card.mt-4{
        padding: 1rem;
        margin-bottom: 1rem;
        border: none;
        border-radius: 1.25rem;
        box-shadow: 0 15px 30px 0 rgb(0 0 0 / 12%);
        background-color: $white;
        .card-img {
            height: 195px;
            -o-object-fit: cover;
            object-fit: cover;
            border-radius: 13px;
            transition: all 0.3s ease-in-out;
        }
    }
}
.adherent-info-detail{
    .main-card{
        background-color: $white;
        color: $gray-900;
    }
}