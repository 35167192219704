.error-container {
    text-align: center;
    h2 {
        font-size: 0.9375rem;
        font-family: $montserrat;
        text-transform: uppercase;
    }
    .error-icon {
        padding: 20px 40px;
        @include media-breakpoint-up(sm) {
            width: 600px;
            margin: 0 auto;
        }
    }
    .error-message {
        @media only screen and (max-width: 639px) {
            font-size: 2rem;
        }
        @media only screen and (max-width: 518px) {
            font-size: 1.5rem;
        }
    }
}