.login-page {
    &,
    body {
        height: 100%;
    }
    .navbar {
        justify-content: center !important;
            height: 80px;
    }
    .form-signin {
        width: 100%;
        max-width: 420px;
        padding: 5.3125rem 15px 0.9375rem;
        margin: auto;
        text-align: center;
    }
    .section-title {
        font-size: 1.25rem;
    }
    .description {
        margin-bottom: 1rem;
        font-size: 0.875rem;
        line-height: normal;
    }
    .form-control {
        font-size: 0.8125rem;
        padding: 0.75rem;
        border-radius: 1.375rem;
        height: auto;
        border-left: none;
        padding-left: 0;
        &:focus {
            border-color: #ced4da;
            box-shadow: none;
        }
    }
    .input-group-text {
        font-size: 0.8125rem;
        border-radius: 1.375rem;
        background-color: $white;
    }
    .form-check {
        font-size: 0.875rem;
        line-height: 1.8;
        font-weight: 600;
        text-align: left;
        padding-left: 3.625rem;
    }
    .forgot-link {
        display: block;
        margin-bottom: 1rem;
        font-size: 0.8125rem;
        font-weight: 500;
        color: $gray-900;
    }
    .submit-btn,
    .account-btn {
        display: block;
        width: 80%;
        margin: 0 auto 2rem;
        padding: 0.875rem;
        border-radius: 1.5rem;
        font-family: $antique;
        font-size: 0.8125rem;
        font-style: italic;
        text-transform: uppercase;
    }
    .account-link {
        font-size: 0.9375rem;
        font-weight: 600;
        color: $blue;
        margin-bottom: 1rem;
    }    
}