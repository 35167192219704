.reset-page{
    margin-top: 2rem;
    .rest{
        position: relative;
        display: inline-block;
        font-family: "AntiqueOliveStd-Nord", sans-serif;
        font-weight: bold;
        font-style: italic;
        text-transform: uppercase;
        text-align: center;
        color: #323132;
        padding-bottom: 0.5rem;
        margin-bottom: 1.75rem;
        font-size: 25px;
        line-height: 29px;
        padding-left: 1rem;
        padding-right: 1rem;
        &:after{
            content: "";
            position: absolute;
            left: 26%;
            bottom: -5px;
            width: 47%;
            border-bottom: 3px solid #0080ce;
        }
    }

    .text-rest {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 27px;
        text-align: center;
        color: #343434;
        padding: 28px;
    }

    .btn-rest {
        font-family: "AntiqueOliveStd-Nord", sans-serif;
        font-size: 13px;
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        text-transform: uppercase;
        font-style: italic;
    }

    .link-rest {
        margin-top: 5rem;
        text-decoration: underline;
    }

}