.card-wallet {
    border: none;
    border-radius: 6px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.12);
    background-color: #343434;
    .text {
        font-family: $antique;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
    }
    .solde {
        width: 129px;
        height: 40px;
        border-radius: 22.5px;
        color: $white;
        display: inline-block;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 700;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        background-color: $white;
        span.balance-wallet {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.8;
            color: #0080ce;
        }
        sup {
            top: 0px;
            color: #0080ce;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.card-wallet-body {
    width: 100%;
    height: auto;
    border-radius: 11px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: $white;
    .form-check {
        padding: 0;
    }
    .wallet-box:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url(../../../img/icons/cash.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .text-body-wallet {
        font-family: $antique;
        font-size: 1.375rem;
        font-style: italic;
        letter-spacing: normal;
        text-transform: uppercase;
        color: var(--gray-900);
    }
    hr.line-wallet {
        width: 310px;
        max-width: 100%;
        border: solid 2px var(--blue);
    }
    .text-body-wallet-recharge {
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #343434;
    }
    button.btn.btn-primary {
        height: 50px;
        font-family: $antique;
        border-radius: 24px;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--white);
    }
    button.btn.btn-outline-primary {
        min-height: 50px;
        font-family: $antique;
        border-radius: 24px;
        font-size: 15px;
        font-style: italic;
        text-align: center;
        color: #0080ce;
    }
    button.btn.btn-outline-primary:hover {
        height: 50px;
        font-family: $antique;
        border-radius: 24px;
        font-size: 15px;
        font-style: italic;
        text-align: center;
        color: #ffff;
    }
    .amount-wallet {
        max-width: 269px;
        position: relative;
        .form-control {
            padding: 0.375rem 1.5rem;
        }
        .currency-amount-wallet {
            bottom: 10px;
            right: 18px;
            z-index: 1;
            position: absolute;
            opacity: 0.5;
            font-size: 16px;
        }
        .amount-wallet {
            border: none;
            border-radius: 22.5px;
            box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);
            background-color: $white;
        }
    }
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}