.cms-page {
    .hero-header{
        margin-bottom: 60px;
        h1.title{
            margin-top: 60px;
        }
    }
}

.cms-page * {
    font-family: unset;
}