.card {
    padding: 0;
    margin-bottom: 1rem;
    border: none;
    border-radius: 1.25rem;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.12);
    background-color: $white;
    .card-img {
        height: 195px;
        object-fit: cover;
        border-radius: 13px;
        transition: all 0.3s ease-in-out;
    }
    .card-body {
        padding: 0;
    }
}

// Course Card
.course-card {
    max-width: 317px;
    width: 100%;
    // height: 430px;
    margin: 0 auto 3.125rem;
    .card-top {
        position: relative;
        border-radius: 0.8125rem;
        overflow: hidden;
    }
    .hovered {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 0.8125rem;
            background-color: rgba(0, 128, 206, 0.6);
        }
        .content {
            width: 100%;
            font-style: italic;
            color: $white;
            z-index: 1;
        }
    }
    &.active {
        .card-img {
            -webkit-filter: blur(10px);
            filter: blur(10px);
        }
        .hovered {
            opacity: 1;
        }
    }
    .card-title {
        justify-content: center;
        padding-top: 8px;
        padding-bottom: 7px;
        margin-top: 1rem;
        border-radius: 1.3125rem;
        background-color: rgba($gray-200, 0.6);
        font-family: $antique;
        font-size: 1.2rem;
        font-style: italic;
        color: $blue;
    }
    .media .badge {
        padding: 4px 8px;
        margin-top: 6px;
        font-weight: 600;
    }
}

// Group card
.group-card {
    // width: 317px;
    // height: 430px;
    margin: 0.125rem auto;
    @media (min-width: 992px) {
        margin: 0.125rem 0;
    }
    .card-top {
        position: relative;
        border-radius: 0.8125rem;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.65;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, $black 84%);
            border-radius: 13px;
        }
    }
    .card-title {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        font-family: $antique;
        font-size: 1.2rem;
        font-style: italic;
        color: $white;
    }
    .participants {
        display: flex;
        align-items: center;
        min-height: 54px;
        justify-content: space-between;
        // margin-top: 1rem;
        margin-bottom: 1rem;
        border-radius: 21px;
        background-color: $gray-200;
        padding: 0.375rem 1.3125rem;
        font-family: $antique-regular;
        font-size: 0.9375rem;
        font-weight: 500;
        font-style: italic;
        color: $blue;
        .number {
            font-family: $antique;
        }
        .badge-danger {
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            font-family: $antique;
            font-style: italic;
            color: $white;
        }
    }
    .avatars {
        .avatar {
            display: inline-block;
            width: 30px;
            margin-left: -12px;
            border: 2px solid $white;
        }
        .more {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            margin-left: -12px;
            color: $gray-900;
            border: solid 2px $white;
            background-color: #c4d3dc;
            font-family: $montserrat;
            font-size: 0.75rem;
            font-weight: 600;
        }
    }
    .hovered {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 0.8125rem;
            background-color: rgba(0, 128, 206, 0.6);
            border-radius: 0;
        }
        .content {
            width: 100%;
            font-style: italic;
            color: $white;
            z-index: 1;
        }
    }
    &.active {
        .card-img {
            -webkit-filter: blur(10px);
            filter: blur(10px);
        }
        .hovered {
            opacity: 1;
        }
    }
    .card-title-details {
        justify-content: center;
        padding-top: 8px;
        padding-bottom: 7px;
        margin-top: 1rem;
        border-radius: 1.3125rem;
        background-color: rgba($gray-200, 0.6);
        font-family: $antique;
        font-size: 1.2rem;
        font-style: italic;
        color: $blue;
    }
}

.group-card.detailed {
    // width: 100%;
    // max-width: 370px;
    height: auto;
    // margin-right: auto;
    // margin-left: auto;
    margin-bottom: 2rem;
    .card-title {
        margin-bottom: 0;
        .coach {
            margin-top: 10px;
            font-family: $montserrat;
            font-size: 0.9375rem;
            font-weight: bold;
            .avatar {
                border: none;
                width: 24px;
                height: 24px;
            }
            .badge {
                font-weight: 600;
                padding: 4px 8px;
                background-color: rgba($black, 0.35);
            }
        }
    }
    .b-info-container {
        align-items: stretch;
        // padding-left: 0;
        // padding-right: 0;
    }
    .b-info {
        margin-right: 0;
        max-width: 50%;
        margin-bottom: 0.5rem;
        .info {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0.25rem;
            &.wt-13 {
                width: 110px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .zone {
            margin-bottom: 0.25rem;
            width: 36px;
            height: 36px;
            object-fit: contain;
        }
    }
    ul.price-listing {
        margin-top: 0;
        padding: 1.125rem 1.25rem 0;
        border-top: solid 1px #e5e5e5;
        li {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }
    }
    .btn {
        padding: 0.75rem 2rem;
        margin-bottom: 0.5rem;
        border-radius: 1.5rem;
        font-family: $antique;
        font-size: 0.75rem;
        font-style: italic;
        text-transform: uppercase;
    }
}

.group-card.pinned {
    width: 100%;
    max-width: 370px;
    margin-left: auto;
    padding: 1.5rem;
    height: inherit;
    margin-bottom: 2rem;
    .title {
        justify-content: center;
        padding-bottom: 0.875rem;
        margin-bottom: 0.25rem;
        font-family: $antique;
        font-size: 1.25rem;
        font-style: italic;
        color: $gray-900;
        border-bottom: solid 3px $blue;
    }
    .participants {
        background: $white;
        margin-bottom: 1rem;
        padding-left: 0;
        padding-right: 0;
    }
    .price-listing {
        margin-top: 1.25rem;
        margin-bottom: 0;
    }
    .btn {
        display: block;
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
        width: 70%;
        margin: 0.25rem auto;
        border-radius: 1.5rem;
        font-family: $antique;
        font-size: 0.8125rem;
        font-style: italic;
        text-transform: uppercase;
    }
    .progress {
        overflow: visible;
    }
}

.coach-card {
    width: 270px;
    min-height: 170px;
    padding: 0;
    margin: 16px;
    .card-img-top {
        border-top-left-radius: 1.25rem;
        border-top-right-radius: 1.25rem;
    }
    img.card-img-top {
        height: 170px;
        object-fit: cover;
    }
    .card-body {
        font-family: $antique-regular;
        font-size: 16px;
        font-style: italic;
        padding: 1rem;
        text-align: center;
        .price {
            margin-left: 8px;
            font-family: $antique;
            font-size: 18px;
            color: $blue;
        }
    }
    .card-title {
        display: block;
        padding: 0.8125rem;
        font-size: 0.875rem;
        font-style: italic;
        text-transform: uppercase;
        border-radius: 1.3125rem;
        background-color: $gray-200;
    }
    .i-icon {
        display: inline-block;
        img {
            width: 30px;
        }
    }
    .btn {
        // margin-bottom: 10px;
        font-family: $antique;
        font-size: 0.75rem;
        font-style: italic;
        text-transform: uppercase;
    }
}

.owl-carousel .owl-item {
    img.info-icon {
        width: 30px;
    }
}

img.info-icon {
    width: 30px;
}

.jscroll-inner {
    margin: 0;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
}

img.miniature.i-xl {
    width: 25px;
    margin-right: 0.2rem;
}
/**********************/

.small-group {
    #containerData{
        justify-content: space-around;
        margin: 0;
    }
    .small-group-card-container {
        max-width: 350px;
        padding: 0 15px;
    }
}
.home,
.small-group,
.live-courses,
.nutrition,
.personal-training {
    .card {
        margin-bottom: 0;
        border: none;
        border-radius: 1.5rem;
        box-shadow: 0 15px 30px 0 rgb(0 0 0 / 12%);
        background-color: $gray-nero;
        color: $white;
        overflow: hidden;

        .card-img {
            height: 200px;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
            border-radius: 0;
        }
        .card-body {
            padding: 0.75rem;
        }

        // Group card
        &.group-card {
            // width: 317px;
            // height: 430px;
            // margin: 0.125rem auto;
            // @media (min-width: 992px) {
            //     margin: 0.125rem 0;
            // }
            .card-top {
                position: relative;
                border-radius: 0;
                overflow: hidden;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.65;
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, $black 84%);
                    border-radius: 0;
                }
            }
            .card-title {
                position: absolute;
                bottom: 0;
                left: 1rem;
                margin-bottom: 0.5rem;
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                font-family: $antique;
                font-size: 1.2rem;
                font-style: italic;
                color: $white;
                img {
                    width: 22px;
                }
            }
            .participants {
                display: flex;
                align-items: center;
                min-height: 54px;
                justify-content: space-between;
                margin-bottom: 1rem;
                border-radius: 10px;
                background-color: $blue-pacific;
                padding: 0.75rem 1.3125rem;
                font-family: $antique-regular;
                font-size: 0.9375rem;
                font-weight: 500;
                font-style: italic;
                color: $white;
                .number {
                    font-family: $antique;
                }
                .badge-danger {
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                    font-family: $antique;
                    font-style: italic;
                    color: $white;
                }
            }
            .avatars {
                .avatar {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    margin-left: -12px;
                    position: relative;
                    border-radius: 20px;
                    border: 2px solid $white;
                }
                .more {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    margin-left: -12px;
                    color: $gray-900;
                    border: solid 2px $white;
                    background-color: #c4d3dc;
                    font-family: $montserrat;
                    font-size: 0.75rem;
                    font-weight: 600;
                }
            }
            .hovered {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    content: "";
                    width: 100%;
                    height: 100%;
                    background-color: $blue;
                }
                .content {
                    width: 100%;
                    font-style: italic;
                    color: $white;
                    z-index: 1;
                }
            }
            &.active {
                .card-img {
                    filter: blur(10px);
                }
                .hovered {
                    opacity: 1;
                }
            }
            .card-title-details {
                justify-content: center;
                border-radius: 1.3125rem;
                font-family: $geogrotesque;
                font-size: 1.75rem;
                font-style: italic;
                color: $blue;
            }
        }
    }

    // Course Card
    .course-card {
        max-width: 317px;
        width: 100%;
        // height: 430px;
        // margin: 0 auto 3.125rem;
        .card-top {
            position: relative;
            border-radius: 0;
            overflow: hidden;
        }
        .hovered {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 0;
                background-color: $blue;
            }
            .content {
                width: 100%;
                font-style: italic;
                color: $white;
                z-index: 1;
                .countdown-live-title {
                    font-family: $geogrotesque;
                    font-style: italic;
                    font-weight: 300;
                    font-size: 1.2rem;
                }
            }
        }
        &.active {
            .card-img {
                -webkit-filter: blur(10px);
                filter: blur(10px);
            }
            .hovered {
                opacity: 1;
            }
        }
        .card-title {
            justify-content: center;
            // padding-top: 8px;
            // padding-bottom: 7px;
            // margin-top: 1rem;
            border-radius: 1.3125rem;
            background-color: $gray-nero;
            font-family: $geogrotesque;
            // font-size: 1.2rem;
            font-style: italic;
            color: $white;

            padding: 0;
            margin-top: 0;
            font-size: 1.75rem;
            font-weight: 700;
        }
        .media .badge {
            padding: 4px 8px;
            margin-top: 6px;
            font-weight: 600;
        }
    }

    .group-card.detailed {
        // width: 100%;
        // max-width: 370px;
        height: auto;
        // margin-right: auto;
        // margin-left: auto;
        margin-bottom: 2rem;
        .card-title {
            .coach {
                margin-top: 10px;
                font-family: $montserrat;
                font-size: 0.9375rem;
                font-weight: bold;
                .avatar {
                    border: none;
                    width: 24px;
                    height: 24px;
                }
                .badge {
                    font-weight: 600;
                    padding: 4px 8px;
                    background-color: rgba($black, 0.35);
                }
            }
        }
        .b-info {
            margin-right: 0;
            margin-bottom: 0.5rem;

            .info {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0.25rem;
                &.wt-13 {
                    width: 110px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                strong {
                    white-space: nowrap;
                }
            }
            .zone {
                margin-bottom: 0.25rem;
                width: 36px;
                height: 36px;
                object-fit: contain;
            }
        }
        ul.price-listing {
            margin-top: 0;
            padding: 1.125rem 1.25rem 0;
            border-top: solid 1px $blue-pacific;
            li {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
                color: $white;
            }
        }
        .btn {
            padding: 0.5rem 2rem;
            margin-bottom: 0.5rem;
            border-radius: 1.5rem;
            font-family: $geogrotesque;
            font-size: 0.9rem;
            font-style: italic;
            text-transform: uppercase;
        }
    }

    .group-card.pinned {
        width: 100%;
        max-width: 370px;
        margin-left: auto;
        padding: 1.5rem;
        height: inherit;
        margin-bottom: 2rem;
        .title {
            justify-content: center;
            padding-bottom: 0.875rem;
            margin-bottom: 0.25rem;
            font-family: $antique;
            font-size: 1.25rem;
            font-style: italic;
            color: $white;
            border-bottom: solid 3px $blue;
        }
        .participants {
            background: $blue-pacific;
            margin-bottom: 1rem;
            padding: 0.75rem 1.3125rem;
        }
        .price-listing {
            margin-top: 1.25rem;
            margin-bottom: 0;
        }
        .btn {
            display: block;
            padding-top: 0.9375rem;
            padding-bottom: 0.9375rem;
            width: 70%;
            margin: 0.25rem auto;
            border-radius: 1.5rem;
            font-family: $antique;
            font-size: 0.8125rem;
            font-style: italic;
            text-transform: uppercase;
        }
        .progress {
            overflow: visible;
        }
    }

    .coach-card {
        width: 270px;
        min-height: 170px;
        padding: 0;
        margin: 16px;
        display: flex !important;
        .card-img-top {
            border-top-left-radius: 1.25rem;
            border-top-right-radius: 1.25rem;
        }
        img.card-img-top {
            height: 170px;
            object-fit: cover;
        }
        .card-body {
            font-family: $antique-regular;
            font-size: 16px;
            font-style: italic;
            padding: 1rem 1rem 110px 1rem;
            text-align: center;
            position: relative;
            .price {
                margin-left: 8px;
                font-family: $antique;
                font-size: 18px;
                color: $blue;
            }
            .card-bottom {
                position: absolute;
                bottom: 1rem;
                left: 0;
                width: 100%;
                max-height: 110px;
            }
        }
        .card-title {
            display: block;
            padding: 0;
            font-size: 0.875rem;
            font-style: italic;
            text-transform: uppercase;
            border-radius: 0;
            color: white;
            width: 100%;
            margin-bottom: 1rem;
            background-color: $gray-nero;
            line-height: 1.5rem;
            height: 3rem;
            display: -webkit-box;
            width: 100%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .i-icon {
            display: inline-block;
            img {
                width: 30px;
            }
        }
        .btn {
            // margin-bottom: 10px;
            font-family: $antique;
            font-size: 0.75rem;
            font-style: italic;
            text-transform: uppercase;
        }
    }
}

.live-courses {
    .live.grand-public {
        .course-card {
            .card-img {
                -webkit-filter: initial;
                filter: initial;
            }
            .hovered {
                &:after {
                    background: url("../../../img/coach-smoke.png") no-repeat;
                    background-size: 100% 100%;
                    opacity: 0.3;
                }
            }
            .card-top {
                &:after {
                    background-color: #0080ce;
                    content: "SANS INSCRIPTION";
                    position: absolute;
                    display: block;
                    top: 0;
                    right: 0;
                    width: 50%;
                    transform-origin: bottom left;
                    transform: translate(29.29%, -100%) rotate(45deg);
                    color: white;
                    border-radius: 1em;
                    font-weight: bold;
                    text-align: center;
                    font-size: 16px;
                    padding: 24px 0 0;
                }
            }
        }

    }
}

.badge-free {
    margin-right: 0;
    font-size: 0.97rem;
    font-family: $geogrotesque;
    font-style: italic;
}

.badge-price {
    font-family: $antique;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 600;
}

.normal-price-label,
.normal-price {
    font-size: 0.85rem !important;
}

.normal-price {
    text-decoration: line-through !important;
    color: white !important;
}
