.group-details {
    margin-bottom: 3.125rem;
    section {
        margin-bottom: 3.75rem;
    }
    .section-title {
        font-size: 1.125rem;
        margin-bottom: 1.75rem;
        text-transform: inherit;
    }
    .comment {
        border: none;
        margin-left: 1.5rem;
        margin-top: 0.75rem;
        .display-none {
            display: none;
        }
        .media img {
            width: 70px;
            height: 70px;
            border: solid 1px #dddddd;
        }
        p {
            margin-bottom: 0;
        }
    }
    .checked-list {
        padding-left: 2rem;
        margin-top: 1rem;
    }
    .numbered-list {
        padding-left: 2rem;
    }
    .detail-items {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-wrap: wrap;
        .item {
            text-align: center;
            margin: 0.5rem;
            .label {
                display: block;
                margin-top: 19px;
                font-size: 15px;
                color: $gray-900;
            }
            .info {
                display: block;
                margin: 0.5rem 0 1rem;
                font-family: $antique-regular;
                font-size: 1.125rem;
                font-style: italic;
                color: $blue;
            }
        }
    }
}

.basket-modal {
    .modal-content {
        width: 100%;
        //height: 430px;
        border-radius: 13px;
        box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.33);
        background-color: var(--white);
    }
    .text-primary-basket {
        width: 100%;
        // height: 70px;
        font-family: $antique;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.94;
        letter-spacing: normal;
        text-align: center;
        color: var(--blue);
    }
}

.basket-modal {
    .modal-content {
        width: 100%;
        // height: 430px;
        border-radius: 13px;
        box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.33);
        background-color: var(--white);
    }
    .text-primary-basket {
        // width: 70%;
        // height: 70px;
        font-family: $antique;
        font-size: 1.125rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.94;
        letter-spacing: normal;
        text-align: center;
        color: var(--blue);
    }
    p {
        &.dont-worry {
            opacity: 0.53;
            font-family: $montserrat;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: var(--gray-900);
            text-align: revert;
            margin: 24px;
        }
        &.text-basket-process {
            width: 100%;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: normal;
            text-align: center;
            color: var(--gray-900);
        }
    }
    .btn {
        padding: 0.75rem 2rem;
        margin-bottom: 0.5rem;
        border-radius: 1.5rem;
        font-family: $antique;
        font-size: 0.75rem;
        font-style: italic;
        text-transform: uppercase;
    }
    .btn-process {
        text-transform: uppercase;
        opacity: 0.5;
        font-family: $montserrat;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: normal;
        text-align: center;
        color: var(--gray-900);
        background: none;
        border: none;
    }
    .btn-plus-tard {
        border-radius: 24px;
        border: solid 2px var(--blue);
        padding: 0.75rem 2rem;
        margin-bottom: 0.5rem;
        font-family: $antique;
        font-size: 0.75rem;
        font-style: italic;
        text-transform: uppercase;
        color: var(--blue);
    }
}

@include media-breakpoint-down(md) {
    .group-details {
        margin-bottom: 0;
        section {
            margin-bottom: 40px;
        }
        .btn {
            padding: 0.75rem 2rem;
            margin-bottom: 0.5rem;
            border-radius: 1.5rem;
            font-family: $antique;
            font-size: 0.75rem;
            font-style: italic;
            text-transform: uppercase;
        }
        .btn-process {
            text-transform: uppercase;
            opacity: 0.5;
            font-family: $montserrat;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: normal;
            text-align: center;
            color: var(--gray-900);
            background: none;
            border: none;
        }
        .btn-plus-tard {
            border-radius: 24px;
            border: solid 2px var(--blue);
            padding: 0.75rem 2rem;
            margin-bottom: 0.5rem;
            font-family: $antique;
            font-size: 0.75rem;
            font-style: italic;
            text-transform: uppercase;
            color: var(--blue);
        }
        .modal-footer {
            &.disponiblity {
                border-top: 0px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .group-details {
        margin-bottom: 0;
        section {
            margin-bottom: 40px;
        }
        .checked-list,
        .numbered-list {
            padding-left: 0;
        }
        .comment {
            margin-left: 0;
        }
    }
}
