section .payment {
  .table th {
    border: none;
  }
  .table td {
    border: none;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 15px;
  }
  //tbody {
  //  //tr {
  .odd {
    width: 100%;
    height: 50px;
    border-radius: 11px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px rgba(161, 161, 161, 0.11);
    background-color: var(--white);
    border-spacing: 5em;
  }
  .event {
    width: 100%;
    height: 50px;
    border-radius: 11px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px rgba(161, 161, 161, 0.11);
    background-color: var(--white);
    border-spacing: 5em;
  }
  .tr-odd {
    box-shadow: none !important;
  }
  //  //}
  //}

  .table th, .table td {
    border-top: none;
  }
  .table thead th {
    vertical-align: bottom;
    border: none;
  }

  tr td:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

  }
  tr td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }



  div#payment-table_filter {
    float: right;
    input[type="search"] {
      padding-left: 2rem;
      width: 223px;
      height: 39px;
      border-radius: 19.5px;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
      border: #ffff;
      background-color: var(--white);
    }
  }
}
