/*register*/
.login-page .form-register {
    text-align: center;
}

.login-page {
    .invalid-feedback-register {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        color: #dc3545;
        font-size: 14px;
        text-align: start;
        font-weight: 600;
        padding-left: 20px;
    }
    .valid-feedback-register {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        color: green;
        font-size: 14px;
        text-align: start;
        font-weight: 600;
        padding-left: 20px;
    }

    input#email {
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
    }

    .input-group > .form-control:not(:last-child),
    .input-group > .custom-select:not(:last-child) {
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
    }

    .hide {
        display: none;
    }

    .hide_th {
        border: 0;
        background: none;
    }

    #valid-msg {
        color: #00c900;
    }

    .invalid {
        width: 100%;
        margin-top: 0.25rem;
        color: #343434;
        font-size: 14px;
        text-align: start;
        font-weight: 400;
    }


    .list-group {
        padding-left: 0;
        padding-right: 0;
    }

    .list-group-item {
        position: relative;
        display: block;
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        display: flex;
        align-items: center;
    }

    .list-group-item.active {
        z-index: 2;
        color: #000000;
        background-color: #ffffff;
        border-color: #0080ce !important;
    }

    .radio {
        display: flex;
        margin: 0;
        position: relative;
        // padding-left: 22px;
        input[type="checkbox"] {
            margin-left: 0;
            top: 1px;
            position: absolute;
        }
    }

    .multisteps-form__progress {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }

    .d-flex.justify-content-center.multisteps-form__content {
        padding-top: 58px;
        /* padding: 24px 41px; */
    }

    .js-active-ins {
        color: #0080ce;
    }

    .multisteps-form__title {
        text-align: center;
        font-size: 20px;
        font-style: italic;
        text-transform: uppercase;
    }

    .list-group-flush .list-group-item:first-child {
        border-top-width: 2px;
    }

    .list-group-flush:last-child .list-group-item:last-child {
        border-bottom-width: 2px;
    }

    .list-group-item + .list-group-item.active {
        margin-top: 0px;
        border-top-width: 2px;
    }

    .list-group-flush .list-group-item {
        /*border-top-width: 1px; */
        border-radius: 7px;
        border: solid 2px #c0cbd2;
        width: 100%;
        height: 70px;
        margin-bottom: 15px;
    }

    .multisteps-form__progress-btn {
        transition-property: all;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        transition-delay: 0s;
        position: relative;
        color: #dce2e6;
        padding-top: 20px;
        text-indent: -9999px;
        border: none;
        background-color: transparent;
        outline: none !important;
        cursor: pointer;
    }

    .multisteps-form__progress-btn-ins {
        transition-property: all;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        transition-delay: 0s;
        position: relative;
        color: #dce2e6;
        padding-top: 20px;
        text-indent: -9999px;
        border: none;
        background-color: transparent;
        outline: none !important;
        cursor: pointer;
    }

    label.radio-label {
        font-size: 1rem;
        font-weight: 600;
        padding-left: 1.875rem;
        display: flex;
        position: relative;
        margin-bottom: 0;

        i {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    label.radio-label.gender {
        margin-bottom: 0;
        i {
            position: relative;
         }
    }

    @media (min-width: 500px) {
        .multisteps-form__progress-btn {
            text-indent: 0;
        }

        .multisteps-form__progress-btn-ins {
            text-indent: 0;
        }
    }

    .multisteps-form__progress-btn:before {
        position: absolute;
        top: 30%;
        left: 50%;
        display: block;
        width: 16px;
        height: 16px;
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        /*box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);*/
        transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s,
            -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        border: 2px solid currentColor;
        border-radius: 50%;
        background-color: #dce2e6;
        box-shadow: none;
        box-sizing: border-box;
        z-index: 3;
    }

    .multisteps-form__progress-btn-ins:before {
        position: absolute;
        top: 30%;
        left: 50%;
        display: block;
        width: 16px;
        height: 16px;
        content: "";
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        /*box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);*/
        transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s,
            -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        border: 2px solid currentColor;
        border-radius: 50%;
        background-color: #dce2e6;
        box-shadow: none;
        box-sizing: border-box;
        z-index: 3;
    }

    .multisteps-form__progress-btn:after {
        position: absolute;
        top: calc(30% + 5px);
        left: calc(-50% - 13px / 2);
        transition-property: all;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        transition-delay: 0s;
        display: block;
        width: 100%;
        height: 4px;
        content: "";
        background-color: currentColor;
        z-index: 1;
    }

    .multisteps-form__progress-btn-ins:after {
        position: absolute;
        top: calc(30% + 5px);
        left: calc(-50% - 13px / 2);
        transition-property: all;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        transition-delay: 0s;
        display: block;
        width: 100%;
        height: 4px;
        content: "";
        background-color: currentColor;
        z-index: 1;
    }

    .multisteps-form__progress-btn:first-child:after {
        display: none;
    }

    .multisteps-form__progress-btn-ins:first-child:after {
        display: none;
    }

    .multisteps-form__progress-btn.js-active {
        color: #0080ce;
    }

    .multisteps-form__progress-btn-ins.js-active-ins {
        color: #0080ce;
    }

    .multisteps-form__progress-btn.js-active:before {
        -webkit-transform: translateX(-50%) scale(1.2);
        transform: translateX(-50%) scale(1.2);
        background-color: currentColor;
    }

    .multisteps-form__progress-btn-ins.js-active-ins:before {
        -webkit-transform: translateX(-50%) scale(1.2);
        transform: translateX(-50%) scale(1.2);
        background-color: currentColor;
    }

    .multisteps-form__form {
        position: relative;
        height: auto !important;
        margin-bottom: 2rem;
        overflow: hidden;
    }

    .multisteps-form__panel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        opacity: 0;
        visibility: hidden;
    }

    .multisteps-form__panel.js-active {
        height: auto;
        opacity: 1;
        visibility: visible;
        position: relative;
    }

    .multisteps-form__panel[data-animation="scaleIn"] {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    .multisteps-form__panel[data-animation="scaleIn"].js-active {
        transition-property: all;
        transition-duration: 0.2s;
        transition-timing-function: linear;
        transition-delay: 0s;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
    }

    input[type="checkbox"] {
        height: 21px;
        width: 21px;
        background: #fff;
        border-radius: 2px;
        border: 2px solid #838d93b8;
        margin-left: -25px;
        top: -3px;
        position: relative;
        z-index: 22;
        vertical-align: top;
    }

    input[type="checkbox"]:checked {
        background: #ffffff;
    }

    input[type="checkbox"]:checked:before {
        font-family: FontAwesome;
        content: "";
        display: block;
        color: #0080ce;
        font-size: 17px;
        position: absolute;
        top: -6px;
        height: 21px;
        width: 21px;
    }

    .avatar-upload {
        position: relative;
        max-width: 205px;
        margin: 26px auto;
    }

    .avatar-upload .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 146px;
    }

    .avatar-upload .avatar-edit input {
        display: none;
    }

    .avatar-upload .avatar-edit input + label {
        content: url(../../img/icons/taking-pictures-circle-alternate.svg);
        object-fit: none;
        display: inline-block;
        width: 42px;
        height: 42px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
    }

    .avatar-upload .avatar-edit input + label:hover {
        background: #f1f1f1;
        border-color: #d6d6d6;
    }

    .avatar-upload .avatar-edit input + label:after {
        content: "";
        font-family: "FontAwesome";
        color: #757575;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
    }

    .avatar-upload .avatar-preview {
        width: 192px;
        height: 192px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #f8f8f8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .avatar-upload .avatar-preview > div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    ul.planlist {
        display: flex;
        padding: 0px 14px;
        li {
            list-style: none;
            flex: 1;
        }

        li:last-child {
            border-right: 1px solid #ddd;
        }
        li.active button {
            background: #f5f5f5;
            color: #000;
        }

        button {
            width: 100%;
            border: 1px solid #ddd;
            border-right: 0;
            border-top: 0;
            padding: 0.625rem;
            background: #fff;
            font-size: 0.875rem;
            font-weight: bold;
            color: #999;
        }
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    .table {
        width: 100%;
        margin-bottom: 1rem;
        color: #343434;
        border-collapse: separate;
        border-spacing: 17px 0px;
    }

    th {
        background: #f5f5f5;
        display: none;
    }

    td,
    th {
        height: 53px;
    }

    td,
    th {
        //border: 1px solid #ddd;
        padding: 10px;
        empty-cells: show;
    }

    td,
    th {
        text-align: left;
    }

    td + td,
    th + th {
        text-align: center;
        display: none;
    }

    td.default {
        display: table-cell;
    }

    //.bg-purple {
    //    border-top: 3px solid #a32362;
    //}
    //
    //.bg-blue {
    //    border-top: 3px solid #0097cf;
    //}

    .sep {
        background: #f5f5f5;
        font-weight: bold;
    }

    .txt-l {
        font-size: 28px;
        font-weight: bold;
    }

    .txt-top {
        position: relative;
        top: -9px;
        left: -2px;
    }

    .tick {
        font-size: 18px;
        color: #2ca01c;
    }
    button#plan-standard {
        color: #000;
        font-size: 0.9rem;
    }
    .btn-outline-dark:hover {
        color: #ffffff!important;
        background-color: #343434;
        border-color: #343434;
    }
    .standard-heitz {
        border-top: solid 1px #e6e6e6;
    }
    .plan-heitz{
        font-size: 13px!important;
    }
    /*@media (min-width: 991.98px) {*/
    /*article {*/
    /*width: 100%;*/
    /*}*/
    /*}*/
    @media (min-width: 991px) {
        article {
            width: 79% !important;
        }

        .login-page .submit-btn-plan {
            font-size: 10px;
        }
    }

    @media (max-width: 767px) {
        .login-page .submit-btn-plan {
            font-size: 10px !important;
        }

        article {
            width: 100% !important;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        article {
            width: 100% !important;
        }

        .login-page .submit-btn-plan {
            font-size: 10px !important;
        }
    }

    @media (min-width: 768px) {
        ul.planlist {
            display: none;
        }

        td,
        th {
            display: table-cell !important;
        }

        td,
        th {
            width: 330px;
        }

        td + td,
        th + th {
            width: auto;
        }
    }

    th.bg-purple {
        background: none;
    }

    td.default {
        /* border-radius: 25px; */
        background-color: #f5f5f5;
        font-size: 17px;
        font-weight: 500;
    }

    td.default.price-plan {
        padding-top: 27px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    td.default.last-default-plan {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    td.plan-text {
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: right;
    }

    article {
        width: 79%;
        max-width: 1000px;
        margin: 83px 0px 3px 0px;
        height: 1000px;
        position: relative;
    }

    th.bg-purple {
        font-family: AntiqueOliveStd-Nord;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
    }

    th.bg-blue.default {
        font-family: AntiqueOliveStd-Nord;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        color: var(--blue);
        font-style: italic;
    }

    .txt-top {
        position: relative;
        top: -14px;
        left: 2px;
        font-family: AntiqueOliveStd-Nord;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
    }

    .txt-l {
        font-size: 28px;
        font-weight: bold;
        font-family: AntiqueOliveStd-Nord;
        font-size: 31px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
    }

    span.month-plan {
        font-family: AntiqueOliveStd;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
    }

    .premium {
        background-color: #ffff;
    }

    .login-page .submit-btn-plan {
        display: block;
        width: 100%;
        margin: 0 auto 1rem;
        height: 52px;
        border-radius: 1.5rem;
        font-family: AntiqueOliveStd-Nord;
        font-size: 0.8125rem;
        font-style: italic;
        text-transform: uppercase;
    }

    input#num_tel {
        padding-left: 91px !important;
        border: 1.5px solid #cacaca;
        border-radius: 22px;
        height: 45px;
    }

    span#error-msg {
        width: 100%;
        margin-top: 0.25rem;
        color: #dc3545;
        font-size: 14px;
        text-align: start;
        font-weight: 600;
    }

    span#error-msg-check-phone {
        width: 100%;
        margin-top: 0.25rem;
        color: #dc3545;
        font-size: 14px;
        text-align: start;
        font-weight: 600;
    }

    span#error-msg-valid {
        width: 100%;
        margin-top: 0.25rem;
        color: #dc3545;
        font-size: 14px;
        text-align: start;
        font-weight: 600;
    }

    #valid-msg {
        color: #00c900;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 14px;
        text-align: start;
        font-weight: 600;
    }

    td.standard {
        border-right: solid 1px #e6e6e6;
        border-left: solid 1px #e6e6e6;
        font-size: 17px;
        font-weight: 500;
    }

    td.standard.standard-btn {
        border-bottom: solid 1px #e6e6e6;
    }

    td.standard.price-standard {
        padding-top: 27px;
        border-top: solid 1px #e6e6e6;
    }

    .iti.iti--allow-dropdown.iti--separate-dial-code {
        width: 100%;
    }

    .iti--separate-dial-code .iti__selected-flag {
        background-color: rgba(0, 0, 0, 0.05);
        border-top-left-radius: 17px;
        border-bottom-left-radius: 17px;
    }

    [data-toggle="collapse"] .fa:before {
        content: "\f139";
        font-size: 24px;
        margin-right: 11px;
        color: #0080ce;
    }

    [data-toggle="collapse"].collapsed .fa:before {
        content: "\f13a";
        font-size: 24px;
        margin-right: 11px;
        color: #0080ce;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }


    .iti__country-list {
        position: absolute;
        z-index: 25 !important;
        list-style: none;
        text-align: left;
        padding: 0;
        margin: 0 0 0 -1px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
        background-color: white;
        border: 1px solid #ccc;
        white-space: nowrap;
        max-height: 200px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    span.unit-cut {
        position: absolute;
        right: 17px;
        top: 11px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        z-index: 4;
    }

    .form-check.text-center.checkCondition {
        position: relative;
        left: 87px;
    }

    .form-check.text-center.checkConfidentiality {
        position: relative;
        left: 41px;
    }

    input[name="politique"]:checked:before {
        font-family: FontAwesome;
        content: "";
        display: block;
        color: #0080ce;
        font-size: 17px;
        position: absolute;
        top: -6px;
    }
}
