.toggler-navbar {
    display: none;
}
.dashboard {
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 264px;
        height: 100%;
        padding-top: 80px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
        background-color: $white;

        min-height: 100vh;
        margin-left: 0;
        z-index: 11;
        transition: margin 0.25s ease-out;
        overflow-x: hidden;
        overflow-y: auto;
        @include media-breakpoint-down(md) {
            margin-left: -264px;
        }
        .unsubscribe{
            color: grey;
            text-decoration: underline;
        }
        .user {
            display: flex;
            align-items: center;
            padding: 1rem;
            .img {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                object-fit: cover;
                margin-right: 12px;
            }
            .link {
                font-size: 13px;
                color: $blue;
                float: right;
            }
        }
        .name {
            margin-left: 1rem;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
        }
        .age {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            margin-left: 1rem;
            color: rgba(52, 52, 52, 0.385954);
        }
        .user-pack {
            display: flex;
            align-items: center;
            padding: 4px;
            border-bottom: solid 1px #efefef;
            .pack {
                width: 100%;
                .pack-type {
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: capitalize;
                }
                .link {
                    font-size: 16px;
                    color: #0080ce;
                    cursor: pointer;
                    float: right;
                }
            }
        }
        .nav {
            font-size: 0.875rem;
            font-weight: 600;
        }
        .nav-item {
            margin-bottom: 15px;
            &:first-child {
                margin-top: 15px;
            }
        }
        .nav-link {
            display: flex;
            align-items: center;
            color: $gray-900;
            padding-left: 32px;
            .icon {
                width: 20px;
                height: 20px;
                margin-right: 15px;
            }
            &.active {
                color: $blue;
                border-left: 5px solid $blue;
            }
        }
        .height {
            border-top: 1px solid #eaeaea;
            padding: 14px;
            margin-top: 11px;
            width: 107%;
            //border-bottom: 1px solid #eaeaea;
            height: 70px;
            .size-user {
                position: relative;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #0080ce;
            }
            .Weight-user {
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #0080ce;
            }
            .vl {
                border-left: 1px solid #eaeaea;
                height: 70px;
                position: absolute;
                bottom: -16px;
                right: 4px;
            }
            //.chart-weight {
            //  position: inherit;
            //  top: -14px;
            //  right: 15px;
            //}
            .pos-triangle {
                position: relative;
                top: 22px;
                left: 9px;
            }
        }
        .imc {
            border-top: 1px solid #eaeaea;
            padding: 14px;
            margin-top: 11px;
            width: 107%;
            border-bottom: 1px solid #eaeaea;
            height: 70px;
            .size-user {
                position: relative;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #0080ce;
            }
            .Weight-user {
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #0080ce;
            }
            .vl {
                border-left: 1px solid #eaeaea;
                height: 70px;
                position: absolute;
                bottom: -16px;
                right: 4px;
            }
            //.chart-weight {
            //  position: inherit;
            //  top: -14px;
            //  right: 15px;
            //}
            .pos-triangle {
                position: relative;
                top: 22px;
                left: 9px;
            }
        }
    }
    .no-member-ship {
        padding-top: 7rem!important;
    }
    .toggler-navbar {
        outline: none !important;
        box-shadow: none !important;
        border-radius: 2px;
        padding: 0;
        line-height: 10px;
        text-align: center;
        margin-right: 0.5rem;
        display: none;
        transition: all 0.25s ease-out;
        @include media-breakpoint-down(md) {
            display: block;
        }
        .icon {
            transition: all 0.25s ease-out;
        }
    }

    &.toggled {
        .toggler-navbar {
            .icon {
                transition: all 0.25s ease-out;
                transform: rotate(0deg);
                @include media-breakpoint-down(md) {
                    transform: rotate(180deg);
                }
            }
        }
        .sidebar {
            margin-left: -265px;
            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }
        .main {
            margin-left: 0;
        }
    }
}
.chart-weight {
    position: relative;
    bottom: 10px;
}

.label.icon-container {
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    white-space: nowrap;
    &.line {
        text-decoration: line-through;
    }
    &.msg {
        white-space: nowrap;
        color: red;
    }
}

// @include media-breakpoint-up(lg) {
//   .dashboard .sidebar {
//     width: 264px;
//   }
// }
