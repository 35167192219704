.nutri-pack-badge {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.084599);
    border-radius: 8.5px;
    font-family: AntiqueOliveStd;
    font-size: 10px;
    line-height: 12px;
    margin-left: 13px;
    color: #0080ce;
    padding: 4px 0.4em !important;
    font-weight: 500;
}
.cn_text,
.cn_icon {
    opacity: 0.3;
}
//
//.col-md-3.calendarCard {
//    height: 380px;
//}
//
//section.dash-nutri .availability-coach .fc-toolbar h2{
//    font-size: 11px;
//    font-weight: 600;
//    text-transform: capitalize;
//}
//
//section.dash-nutri .nutri-dispo .fc-scroller.fc-day-grid-container{
//    height: 100px !important;
//    overflow: visible;
//}
//section.dash-nutri .nutri-dispo .fc-unthemed th{
//    font-size: 9px !important;
//
//}
//section.dash-nutri .availability-coach .fc-dayGrid-view .fc-body .fc-row{
//    height:26px !important;
//}
//
//section.dash-nutri .fc-dayGrid-view .fc-body .fc-row{
//    min-height: 15px;
//}
//.fc-row.fc-week.fc-widget-content{
//    height:20px !important;
//
//}
//section.dash-nutri .availability-coach td.fc-widget-content{
//    right: 0 !important;
//}
//
//section.dash-nutri .availability-coach .fc-unthemed td.fc-today span{
//    height: 10px !important;
//    background-color: #0080ce;
//    border-radius: 4px;
//    width: 19px !important;
//    padding: 2px !important;
//    font-size: 13px !important;
//}
//
//section.dashboard-coach .availability-coach .fc-unthemed tbody td span{
//    width: 19px !important;
//    height: 24px !important;
//    font-size: 13px !important;
//}
//
//.fc td {
//    vertical-align: middle;
//}
//section.dashboard-coach .coachCalendar tr > td > .fc-day-grid-event{
//    //margin-bottom: 0 !important;
//    //margin-left: 0 !important;
//margin: 1px 1px 0;
//padding: 0 1px;
//
//}
//.session-box .coach {
//    font-size: 11px;
//}

.nutrition-bg {
    text-align: right;
    @include media-breakpoint-down(md) {
        overflow-x: hidden;
    }
    &:before {
        content: "";
        position: absolute;
        top: 15px;
        left: -80px;
        width: 520px;
        height: 200px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        background: linear-gradient(224.19deg, #ecefff 14.13%, #f3f6f8 96.28%);
        transform: skewX(24deg);
    }
}
