.new_payment {
    // margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-family: $montserrat;

    .checked {
        border: 2px solid #0080ce;
    }

    .member-extra {
        border-left: 1px solid #cecece;
        border-right: 1px solid #cecece;
        height: 60px;
        font-weight: 500;
        font-size: 16px;
    }

    .last-row {
        border-left: 1px solid #cecece;
        border-right: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        height: 60px;
        font-weight: 500;
        font-size: 16px;
    }

    .product {
        font-weight: 600;
    }

    .member-ship-title {
        font-style: italic;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 4rem;
        text-transform: uppercase;
    }

    .first-row {
        height: 135px;
        border-left: 1px solid #cecece;
        border-right: 1px solid #cecece;
        border-top: 1px solid #cecece;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        line-height: 100px;
    }

    .amount {
        // font-family: "AntiqueOliveStd-Nord", sans-serif;
        font-style: italic;
        font-size: 27px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 100px;
        border-bottom: 1px solid #cecece;
    }

    input[type="radio"] {
        width: 12%;
        height: 1.3rem;
        position: relative;
        top: 0;
    }

    button#payMembership {
        height: 46px;
        // font-family: "AntiqueOliveStd-Nord", sans-serif;
        font-size: 13px;
        font-weight: 100;
        font-style: italic;
        text-transform: uppercase;
    }
    .small {
        font-size: 13px;
        font-weight: 400;
        font-family: "Montserrat";
        text-transform: lowercase;
    }

    // i.icon.icon-visa.pos-payment {
    //     position: relative;
    //     top: 9px;
    // }

    // i.icon.icon-master-card.pos-payment {
    //     position: relative;
    //     top: 9px;
    // }
    @media (max-width: 414px) {
        .member-ship-title {
            // font-family: "AntiqueOliveStd-Nord", sans-serif;
            font-style: italic;
            font-size: 18px !important;
            font-weight: bold;
            margin-bottom: 2rem;
            text-transform: uppercase;
        }
        .amount {
            // font-family: "AntiqueOliveStd-Nord", sans-serif;
            font-style: italic;
            font-size: 14px !important;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 100px;
            border-bottom: 1px solid #cecece;
        }
    }

    .section-icon2 {
        margin-right: 20px;
    }

    .banner-payment {
        background: rgba(#0080ce, 0.05);
        margin-bottom: 2rem;
        padding: 1rem;

        .banner-content {
            max-width: 400px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .plan-text {
        background: #f7f8fa;
        border-radius: 13px;
        padding: 2rem;
        margin-bottom: 2rem;
    }

    h3 {
        font-family: $antique;
        display: block;
        width: 100%;
        margin-bottom: 15px;
        font-size: 19px;
        line-height: 22px;
        text-align: center;
        color: #343434;
    }

    .btn  {
        margin-bottom: 1rem;
    }

    .description {
        display: block;
        max-width: 400px;
        margin: 15px auto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #343434;
    }

    .in-checked {
        position: relative;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.5rem;
        height: 80px;

        &.monthly,
        &.daily {
            justify-content: center;
            align-items: center;
        }

        .txt-l {
            font-style: italic;
            font-weight: 800;
            font-size: 29px;
            line-height: 20px;
            text-align: center;
            color: #0080ce;
        }

        .txt-top {
            position: relative;
            top: -12px;
            font-size: 15px;
            line-height: 18px;
            text-align: right;
            color: #0080ce;
        }

        .month-plan {
            font-style: italic;
            font-weight: normal;
            font-size: 17px;
            // line-height: 49px;
            color: #343434;
        }
    }

    .box-info {
        background: rgba(#0080ce, 0.05);
        border-radius: 13px;
        padding: 1rem;
        margin: 1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0080ce;
        p {
            margin-bottom: 0;
        }
    }

    .pay-info {
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 49px;
        color: #343434;
        padding: 0 1rem;
    }

    .default {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;
        text-align: center;
        color: #0080ce;
    }

    .bootm-text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #343434;
    }

    label {
        font-size: 13px;
        color: #424242;
        font-weight: 500;
    }

    .btn-color-mode-switch {
        display: inline-block;
        margin: 0px;
        position: relative;
        margin-bottom: 2rem;
        width: 100%;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner {
        margin: 0px;
        width: 100%;
        height: 58px;
        background: #e0e0e0;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
        display: block;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
        content: attr(data-on);
        position: absolute;
        font-size: 22px;
        font-weight: 500;
        top: 14px;
        right: 0;
        width: 50%;
        text-align: center;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
        content: attr(data-off);
        width: 50%;
        height: 50px;
        background: #0080ce;
        border-radius: 30px;
        position: absolute;
        left: 4px;
        top: 4px;
        text-align: center;
        transition: all 0.3s ease;
        box-shadow: 0px 0px 6px -2px #111;
        padding: 10px 0px;
        font-size: 22px;
        color: #fff;
    }

    .btn-color-mode-switch > .alert {
        display: none;
        background: #ff9800;
        border: none;
        color: #fff;
    }

    .btn-color-mode-switch input[type="checkbox"] {
        cursor: pointer;
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
        //background: #151515;
        //color: #fff;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
        content: attr(data-on);
        left: calc(50% - 4px);
        // background: #3c3c3c;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
        content: attr(data-off);
        right: auto;
        width: 50%;
        text-align: center;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
        /*background: #66BB6A; */
        /*color: #fff;*/
    }

    .btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
        display: block;
    }

    @media (min-width: 768px) {
        td,
        th {
            width: 60%;
        }

        td + td,
        th + th {
            width: auto;
        }
    }

    label.radio-label {
        padding-left: 0;
        padding-right: 0.5rem;
    }
}



.just-deactived {
    display: none !important;
}
.bold_blue {
    color: #0080ce;
    font-weight: bolder;
}

/*mode preview*/
.dark-preview {
    background: #0d0d0d;
}

.dark-preview div.btn-container i.fa-sun-o {
    color: #777;
}

.dark-preview div.btn-container i.fa-moon-o {
    color: #fff;
    text-shadow: 0px 0px 11px #fff;
}

.white-preview {
    background: #fff;
}

.white-preview div.btn-container i.fa-sun-o {
    color: #ffa500;
    text-shadow: 0px 0px 16px #ffa500;
}

.white-preview div.btn-container i.fa-moon-o {
    color: #777;
}

p.by a {
    text-decoration: none;
    color: #000;
}

.dark-preview p.by a {
    color: #777;
}

.white-preview p.by a {
    color: #000;
}
