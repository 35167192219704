.contact-description{
    position: relative;
    background-image: linear-gradient(to bottom, #ffffff, #f7f7f7);
    padding-top: 30px;
    padding-bottom: 84px;
}
span.help-block {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}