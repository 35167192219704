section.dashboard-coach {
    .dataTables_wrapper {
        overflow-x: auto;
        padding: 5px;
    }
    pre.text-plan {
        font-family: $montserrat;
        font-weight: 400;
        font-size: 16px!important;
        color: #343434!important;
    }
    span.session-coach-upcoming {
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        color: #0080ce;
    }
    .availability-coach {
        .fc-unthemed tbody td span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            float: none !important;
            height: 40px;
            border-radius: 3.6px;
            background: none;
            font-size: 1rem;
            font-weight: 600;
        }
        .fc-unthemed td.fc-today span {
            color: $white;
            height: 36px;
            background-color: #0080ce;
            border-radius: 10px;
        }
        .fc-dayGrid-view .fc-body .fc-row {
            height: 53px !important;
        }
        span.fc-day-number {
            color: rgba(52, 52, 52, 0.5);
        }
        .fc-button-primary {
            color: #0080ce;
            background-color: $white;
            border-color: $white;
        }
        .fc-toolbar h2 {
            font-size: 1.0375rem;
            font-weight: 600;
            text-transform: capitalize;
        }
        .fc-button-primary:hover {
            color: #0080ce;
            background-color: #fff;
            border-color: #fff;
        }
        td.fc-widget-content {
            position: relative;
            right: 6px;
        }
    }
    .list-payment-coach {
        p.gain-coach {
            height: 18px;
            font-size: 0.875rem;
            font-weight: 600;
            color: #0080ce;
        }
        .gain {
            border-radius: 9px;
            background-color: #343434;
            height: 61px;
            color: #ffff;
            margin-top: 26px;
        }
        .month-gain {
            line-height: 48px;
            padding-right: 14px;
            padding-left: 14px;
            .month {
                font-size: 1.125rem;
                font-weight: 600;
            }
            .cg-month-gain {
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
        .price {
            font-size: 1rem;
            font-weight: 600;
            .DT {
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }
    .card.past-session,.card.future-session {
        padding: 1rem;
        .list-session td,
        .list-session th {
            border-collapse: separate;
            border: none;
        }
        span.type-session {
            position: relative;
            bottom: 5px;
            margin-left: 0.2rem;
        }
        p.session-coach-past {
            height: 18px;
            font-size: 0.9375rem;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--blue);
        }
        table.table.list-session {
            border-collapse: separate;
            border-spacing: 0 10px;
            margin-bottom: 0.45rem;
            .th-coach {
                height: 15px;
                font-size: 0.75rem;
                font-weight: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #9ba0a3;
                padding: 0.75rem 5px 0.75rem 2rem;
                @include media-breakpoint-down(sm) {
                    padding: 0.75rem 2px 0.75rem 2px;
                }
            }
            tr.session-type {
                height: 50px;
                box-shadow: 0 2px 8px 0 rgb(0 0 0 / 18%);
                border: solid 1px rgba(0, 128, 206, 0.11);
                background-color: $white;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                .td-coach {
                    padding-left: 2rem;
                    &.date-coach {
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 25px;
                        letter-spacing: normal;
                        color: var(--gray-900);
                        &:nth-of-type(1), &:nth-of-type(3), &:nth-of-type(5) {
                            white-space: nowrap;
                            span {
                                white-space: normal;
                            }
                        }
                    }
                    .avatars {
                        display: inline-block;
                        & img.avatar {
                            width: 30px;
                            height: 30px;
                            border-radius: 15px;
                            border: 2px solid $white;
                            margin-left: -12px;
                        }
                    }
                    span.badge.more {
                        width: 30px;
                        height: 30px;
                        border-radius: 25px;
                        margin-left: -12px;
                        border: solid 2px var(--white);
                        background-color: #c4d3dc;
                    }
                }
                td:first-child {
                    border-top-left-radius: 10px;
                }
                td:last-child {
                    border-top-right-radius: 10px;
                }
                td:first-child {
                    border-bottom-left-radius: 10px;
                }
                td:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }
    .coachCalendar {
        tr > td > .fc-day-grid-event {
            z-index: -1;
            height: 6px;
            width: 4px;
            display: inline-block;
            margin: 0 0 0 -2px;
            position: absolute;
            bottom: 8px;
            left: 50%;
            border: 0;
        }
    }
    img.coach-activity-dashboard {
        width: 25px;
        height: 20px;
    }
    ul.pagination {
        margin: 0;
    }
    div#list-session-past_paginate {
        text-align: center;
        margin-top: 0rem;
    }
    a.paginate_button {
        margin: 1rem;
        cursor: pointer;
    }
    a.paginate_button.current {
        display: inline-grid;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 43px;
        height: 43px;
        border: none;
        border-radius: 50%;
        font-family: "Montserrat", sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        color: #ffffff;
        background-color: #0080ce;
        border-color: #0080ce;
    }
    a#list-session-past_previous {
        display: inline-flex;
    }

    a.paginate_button {
        font-size: 16.6px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--blue);
    }
    a#list-session-past_next {
        border: solid 0.8px var(--blue);
        display: inline-grid;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 43px;
        height: 43px;
        border-radius: 50%;
    }
}

.card.list-coach-dispo {
    min-height: 100px;
}
.card.list-coach-dispo {
    .heure {
        width: 44px;
        height: 19px;
        opacity: 0.5;
        font-size: 0.9375rem;
        font-weight: 500;
        color: #434343;
    }
    p.list-event-coach {
        padding: 25px;
        font-size: 1.125rem;
        font-weight: 500;
        color: var(--blue);
    }
    .title {
        font-size: 0.875rem;
        font-weight: 600;
        color: #040404;
    }
    .color-list-coach {
        position: absolute;
        bottom: 1px;
        width: 12px;
        left: 1px;
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
        height: 100%;
    }
    .activity-name {
        margin-left: 30px;
        font-family: AntiqueOliveStd;
        text-transform: uppercase;
        font-weight: 700;
    }
    .activity-name-adherent{
        font-family: $montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
    }
    .availability-icon-coach {
        position: absolute;
        // top: 30px;
        right: 25px;
    }
}

p.list-event-coach {
    font-size: 1.0625rem;
    font-weight: 500;
    padding: 6px;
    letter-spacing: normal;
    color: var(--blue);
}

p.no-disponiblity-coach {
    /*padding: 10rem;*/
    font-size: 1.0625rem;
    font-weight: 600;
    color: #040404;
}

.calendar.calendar-coach {
    .fc-unthemed tbody td span {
        width: 100%;
        height: 28px;
    }
    .fc-unthemed tbody td span {
        font-size: 0.85rem;
        color: $white;
        margin-left: 7px;
        line-height: 30px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        background: #343434;
    }
    span.fc-title {
        justify-content: stretch;
        padding-left: 6px;
    }
    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0.5em;
        justify-content: space-evenly;
    }
    th.fc-day-header.fc-widget-header span {
        opacity: 0.8;
        font-size: 0.7688rem;
        font-weight: 500;
        text-align: center;
        color: var(--gray-900);
    }
    .fc-button-primary {
        color: #fff;
        background-color: #ffff;
        border-color: #ffff;
    }
}

.pos-icon-coach.mr-1 {
    position: relative;
    top: 3px;
}

.calendar-coach-dashboard {
    button.fc-addEventButton-button.fc-button.fc-button-primary {
        padding: 0.75rem 2rem;
        margin-bottom: 0.5rem;
        border-radius: 1.5rem;
        font-family: $antique !important;
        font-size: 0.75rem !important;
        font-style: italic;
        text-transform: uppercase;
    }
    .fc-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
            font-family: $montserrat;
            font-size: 1.1687rem;
            font-weight: 600;
            color: #343434;
            margin-right: 1.2rem;
        }
        .fc-button-primary {
            color: #0080ce;
            background-color: #ffff;
            border-color: #ffff;
        }
    }
    th.fc-day-header.fc-widget-header {
        opacity: 0.5;
        font-family: $montserrat;
        font-size: 1.0813rem;
        font-weight: 500;
    }
    .fc-dayGrid-view .fc-week-number,
    .fc-dayGrid-view .fc-day-number {
        padding: 20px;
        opacity: 0.5;
        font-family: $montserrat;
        font-size: 0.9875rem;
        font-weight: 700;
        color: #343434;
    }
    .fc-other-month {
        background-color: #f7f7f7;
    }
    td.fc-day-top.fc-tue.fc-today .fc-day-number {
        background: #0080ce;
        opacity: 1;
        color: #ffff;
        margin: 4px;
        width: 11px;
        padding: 8px 16px 19px 12px;
        position: relative;
        top: 9px;
        height: 11px;
        border-radius: 11px;
    }
    td.fc-today {
        background: $white;
    }
    .fc th {
        border-style: none !important;
    }
    button.fc-addEventButton-button.fc-button.fc-button-primary {
        color: #ffff;
        background-color: #0080ce;
        border-color: #0080ce;
        border-radius: 25px;
        font-weight: 600;
        font-family: $montserrat;
        font-size: 1rem;
    }
    a.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end {
        height: 30px;
        margin: 1px 6px 2px 6px;
        border-radius: 3.4px;
    }
    .fc-day-grid-event .fc-time {
        font-weight: 700;
        color: #ffff;
        position: absolute;
        top: 5px;
        left: 7px;
    }
    .fc-title {
        position: relative;
        top: 5px;
        left: 35px;
        color: #ffff;
        font-weight: 600;
        font-size: 0.9375rem;
        cursor: pointer;
    }
    .fc-content {
        height: 34px;
        cursor: pointer;
    }
    .fc-unthemed .fc-disabled-day {
        background: #ffff;
        opacity: 0.3;
    }
}

/*
.section-title {
    position: relative;
    display: inline-block;
    font-family: $antique;
    font-size: 1.5625rem;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;
    color: #343434;
    padding-bottom: 0.5rem;
    margin-bottom: 1.75rem;
}
*/

label.start {
    font-size: 1rem;
    margin-left: 0.9rem;
    color: var(--gray-900);
}

.error {
    margin-bottom: 1rem;
    font-family: $antique;
    font-size: 1rem;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0080ce;
}

.multipe-selection {
    font-family: $montserrat;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--gray-900);
    cursor: pointer;
}

.config-week {
    margin: 25px 26px 21px 144px;
}

.week {
    margin: 1rem;
}

.flatpickr-calendar {
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    -webkit-animation: none;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 0.875rem;
    line-height: 1.5rem;
    border-radius: 5px;
    position: absolute;
    width: 307.875px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 4rem;
}

.card.plus {
    width: 44px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    cursor: pointer;
    padding: 14px;
}

.flatpickr-calendar.hasTime .flatpickr-time {
    height: 40px;
    border: 1px solid #e6e6e6;
}

.checkbox {
    margin-left: 4rem;
}

.trash-time {
    position: relative;
    right: 16px;
    top: 5px;
}

.date-ranage-title {
    display: block;
    font-size: 0.9375rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.pagination {
    li.page-item:first-child {
        display: none;
    }
}
