@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

@font-face {
  font-family: "AntiqueOliveStd-Nord";
  src: url("../../fonts/antique-olive/AntiqueOliveStd-Nord.eot");
  src: url("../../fonts/antique-olive/AntiqueOliveStd-Nord.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/antique-olive/AntiqueOliveStd-Nord.woff2") format("woff2"),
    url("../../fonts/antique-olive/AntiqueOliveStd-Nord.woff") format("woff"),
    url("../../fonts/antique-olive/AntiqueOliveStd-Nord.ttf") format("truetype"),
    url("../../fonts/antique-olive/AntiqueOliveStd-Nord.svg#AntiqueOliveStd-Nord") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AntiqueOliveStd-Nord";
  src: url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.eot");
  src: url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.woff2") format("woff2"),
    url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.woff") format("woff"),
    url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.ttf") format("truetype"),
    url("../../fonts/antique-olive/AntiqueOliveStd-NordItalic.svg#AntiqueOliveStd-NordItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "AntiqueOliveStd";
  src: url("../../fonts/antique-olive/AntiqueOliveStd-Italic.eot");
  src: url("../../fonts/antique-olive/AntiqueOliveStd-Italic.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/antique-olive/AntiqueOliveStd-Italic.woff2") format("woff2"),
    url("../../fonts/antique-olive/AntiqueOliveStd-Italic.woff") format("woff"),
    url("../../fonts/antique-olive/AntiqueOliveStd-Italic.ttf") format("truetype"),
    url("../../fonts/antique-olive/AntiqueOliveStd-Italic.svg#AntiqueOliveStd-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}





@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-Regular.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-Regular.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-SemiBoldItalic.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-SemiBoldItalic.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-BoldItalic.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-BoldItalic.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-Bold.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-Bold.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-UltraLight.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-UltraLight.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-UltraLightItalic.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-UltraLightItalic.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-Light.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-Light.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-SemiBold.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-SemiBold.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-MediumItalic.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-MediumItalic.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-Thin.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-Thin.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-RegularItalic.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-RegularItalic.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-Medium.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-Medium.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-LightItalic.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-LightItalic.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Geogrotesque';
    src: url('../../fonts/geogrotesque/Geogrotesque-ThinItalic.woff2') format('woff2'),
        url('../../fonts/geogrotesque/Geogrotesque-ThinItalic.woff') format('woff'),
        url('../../fonts/geogrotesque/Geogrotesque-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

