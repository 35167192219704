// Block video item
.b-video {
    margin-bottom: 12px;
    margin-right: 12px;
    &:hover {
        text-decoration: none;
    }
    .img {
        position: relative;
        width: 270px;
        height: 150px;
        border-radius: 5px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: #d8d8d8;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.65;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, $black 84%);
            border-radius: 5px;
        }
        &:after {
            content: "";
            position: absolute;
            left: 8px;
            bottom: 6px;
            width: 38px;
            height: 38px;
            background: url(../../../img/player2.svg) no-repeat;
            background-size: contain;
            z-index: 2;
        }
        .time {
            content: "";
            position: absolute;
            right: 6px;
            bottom: 11px;
            padding: 2px 5px;
            border-radius: 2px;
            background-color: rgba($black, 0.5);
            font-family: $montserrat;
            font-size: 11px;
            font-weight: 500;
            text-align: center;
            color: $white;
        }
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }
    .info {
        font-family: $montserrat;
        font-size: 0.875rem;
        padding-top: 0.875rem;
        .title {
            font-weight: 600;
            color: $gray-900;
            position: relative;
            padding-right: 20px;
            line-height: 1.5em;
            height: 3em;
            overflow: hidden;
        }
        .category {
            font-weight: 500;
            color: #a9a9a9;
        }
    }
}

.videos-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .b-video {
        margin-right: 17px;
        width: 257px;
        &.details {
            position: relative;
        }
        .img {
            width: 260px;
        }
    }
}

.video-details {
    margin-bottom: 2rem;
    .video-player {
        width: 100%;
        height: 566px;
        margin-bottom: 32px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.38);
        background-color: #383838;
		padding-bottom: 0;
		.video-js{
			width: 100%;
			height: 100%;
			.vjs-big-play-button{
				margin-top: -0.75em;
				margin-left: -1.5em;
				height: 1.5em;
				width: 3em;
				top: 50%;
				left: 50%;
			}
			.vimeoFrame{
				height: 100% !important;
				video{
					pointer-events: none;
				  -moz-opacity: 0;
				  -webkit-opacity: 0;
				  opacity: 0;
				  filter: alpha(opacity=0);
				  visibility: hidden;
				}
			}
		}
    }
    .details {
        font-family: $montserrat;
        margin-bottom: 1rem;
        .title {
            margin-bottom: 8px;
            font-size: 25px;
            font-weight: 600;
            color: $gray-900;
        }
        .category {
            margin-bottom: 24px;
            font-size: 16px;
            font-weight: 500;
            color: #a9a9a9;
        }
        .description {
            font-size: 13px;
            font-weight: normal;
            color: $gray-900;
        }
        .share {
            span {
                display: block;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 500;
            }
            img {
                width: 37px;
                height: 37px;
                object-fit: contain;
                margin-right: 8px;
            }
        }
    }
    .other-videos {
        margin-bottom: 1rem;
        @include media-breakpoint-up(lg) {
            border-left: 1px solid #dedede;
            padding-left: 25px;
        }
        .title {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 16px;
        }
        .b-video {
            display: flex;
            align-items: flex-start;
            .img {
                width: 167px;
                height: 99px;
                margin-right: 8px;
            }
        }
    }
}

.page-load-status {
    display: none;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    text-align: center;
    color: #777;
}

.embed-container {
    --video--width: 1296;
    --video--height: 540;
    position: relative;
    padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
    /* 41.66666667% */
    overflow: hidden;
    max-width: 100%;
    background: black;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

a.link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.rating-modal {
    .modal-lg {
        width: 552px;
    }
    .modal-content {
        background: transparent;
        border: none;
        border-radius: 13px;
        height: 60vh;
    }
    .modal-body {
        padding: 32px;
        text-align: center;
        border: none;
        border-radius: 13px;
        box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.33);
        background-color: $white;
        font-size: 16px;
        font-weight: 500;
        color: #4c4c4c;
    }
    .modal-header {
        justify-content: center;
        padding-bottom: 32px;
        border: none;
        font-family: $montserrat;
        font-size: 35px;
        font-weight: 600;
        color: $white;
    }
    .note-text {
        display: block;
        font-size: 22px;
        color: #6b9be8;
        line-height: 1.36;
    }
    .coach {
        margin-top: 42px;
        margin-bottom: 42px;
        font-size: 18px;
        font-weight: bold;
        .avatar {
            display: block;
            width: 70px;
            height: 70px;
            border: none;
            margin: 12px auto;
        }
    }
    .form-control {
        margin-top: 32px;
        margin-bottom: 34px;
        border-radius: 10px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: $white;
        font-size: 15px;
        font-weight: 500;
        // color: rgba(#3b3b3b, 0.52);
        line-height: 1.8;
        border: none;
        &::placeholder {
            color: rgba(#3b3b3b, 0.52);
        }
    }
    .actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn-primary {
        height: 48px;
        border-radius: 24px;
        box-shadow: 0 4px 10px 0 rgba(3, 109, 173, 0.3);
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .btn-link {
        margin-top: 4px;
        font-size: 13px;
        font-weight: 500;
        color: #a4a4a4;
        text-transform: uppercase;
    }
}

.col-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .count-video {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
    }
}

.nb-vues {
    font-size: 16px;
    font-weight: 500;
    float: right;
}

.activity-color {
    background-color: #0080ce;
}

.item-vod {
    width: 45%;
}

.top-10 {
    padding-top: 10px;
}

.btn-curser {
    cursor: pointer;
}

//more
a.morelink {
    text-decoration: none;
    outline: none;
}

.morecontent span {
    display: none;
}

.number-view {
    content: "";
    position: absolute;
    right: 6px;
    top: 0px;
    font-family: $montserrat;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    color: $white;
    .color-b {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 2px 5px;
        margin: 5px 0px;
        border-radius: 3px;
    }
    .nb-vues {
        font-size: 11px;
        font-weight: 500;
        .badge {
            margin: 5px 0px;
            font-size: 10px;
            font-weight: 400;
        }
    }
    .icon-eye-gray {
        background-image: url(../../images/icons/view-1.svg);
    }
}
