header {
    // position: relative;
    z-index: 99;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(255 255 255 / 70%);
    transition: all 0.3s linear;

    &.is-sticky {
        background-color: $white !important;
    }

    &.sticky-top {
        position: fixed !important;
    }
    .member-ship-badge {
        border-radius: 0px;
        text-align: center;
        font-weight: 500;
    }
    .member-ship-badge.alert-danger {
        background-color: #dc3545;
        border-color: #dc3545;
        color: #FFF;
    }
    .member-ship-badge.alert-warning {
        background-color: #ff8f00;
        border-color: #ff8f00;
        color: #FFF;
    }

    u.buyMembership {
        cursor: pointer;
    }
    @include media-breakpoint-down(lg) {
        .container-lg,
        .container-md,
        .container-sm,
        .container {
            max-width: 100% !important;
        }
    }

    .options-navbar {
        display: flex;
        flex-wrap: nowrap;
        @include media-breakpoint-down(md) {
            position: absolute;
            right: 0;
            top: 12px;
            min-height: 38px;
            .dropdown-user {
                display: flex;
                align-items: center;
                .logged-user {
                    margin-left: 0;
                    margin-right: 0;
                    padding-right: 0;
                    padding-left: 0;

                    .user-info {
                        display: none;
                    }
                }
            }
            .basket-toggle {
                margin-top: 0;
                display: flex;
                align-items: center;
                height: auto;
                .dropdown-toggle {
                    padding-right: 5px;
                    margin-left: 5px;
                }
            }
        }
        .dropdown-user {
            .dropdown {
                @include media-breakpoint-up(lg) {
                    &:after {
                        content: "";
                        position: fixed;
                        top: 81px;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 10;
                        opacity: 0;
                        visibility: hidden;
                        background-color: rgba($gray-900, 0.57);
                        pointer-events: none;
                        transition: all 0.3s ease-in-out;
                    }
                    &.show:after {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            .dropdown-menu {
                left: auto;
                right: 0;
                top: 100%;
                // width: 100%;
                z-index: 99999;
                min-width: 11rem;
                background: #ffffff;
                box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.293816);
                .dropdown-item {
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1.0625rem;
                    color: #343434;
                    padding: 0.5rem 1rem;
                    &.active {
                        font-weight: 600;
                        color: #0080ce;
                        background: #ffffff;
                    }
                    .icon {
                        margin-right: 6px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
    }

    .navbar {
        // box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
        // z-index: 3;
        padding: 0.5rem 0;
        .navbar-brand {
            .image_full {
                max-width: 195px;
            }
            .image_mobile {
                max-width: 52px;
            }
        }
    }
    .navbar-nav {
        // width: 100%;
        justify-content: flex-end;

        .nav-item {
            margin-right: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            &.btn-login {
                margin-right: 0;
            }
            @include media-breakpoint-down(lg) {
                margin-right: 1rem;
            }
            @include media-breakpoint-down(md) {
                margin-right: 0;
                margin-bottom: 0.5rem;
            }
        }
        .btn-primary {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .navbar-expand-lg .navbar-collapse {
        min-height: 64px;
    }
    .navbar-light .navbar-nav .nav-link {
        color: $gray-900;
        font-weight: 500;
        font-size: 0.875rem;
        text-align: center;
        transition: all 0.3s linear;
    }
    .navbar-light .navbar-toggler {
        border: none;
        padding-left: 5px;
        padding-right: 5px;
    }
    .no-break {
        white-space: nowrap;
    }
    .logged-user {
        position: relative;
        display: flex;
        align-items: flex-start;
        max-width: 180px;
        margin-left: 0;
        margin-right: 0.75rem;
        padding-right: 1.5rem;
        padding-left: 1rem;
        border-left: solid 1px #dfdfdf;
        text-align: left;
        &:after {
            position: absolute;
            right: 0px;
            top: 8px;
        }

        .name {
            display: block;
            max-width: 112px;
            margin-bottom: 0.125rem;
            font-size: 0.75rem;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            user-select: none;
        }
        .avatar {
            width: 22px;
            height: 22px;
            margin-right: 12px;
        }
    }
    .basket-toggle {
        @include media-breakpoint-up(lg) {
            &:after {
                content: "";
                position: fixed;
                top: 81px;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                opacity: 0;
                visibility: hidden;
                background-color: rgba($gray-900, 0.57);
                pointer-events: none;
                transition: all 0.3s ease-in-out;
            }
            &.show:after {
                visibility: visible;
                opacity: 1;
            }
        }
        .dropdown-toggle {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            min-width: 50px;
            margin-left: auto;
            margin-right: auto;
            padding-right: 1.25rem;
            padding-left: 5px;
            border-left: solid 1px #dfdfdf;
            &:after {
                display: none;
            }
        }
        .badge {
            position: absolute;
            top: 8px;
            left: 22px;
            height: 22px;
            min-width: 22px;
        }
        table {
            margin-bottom: 0;
        }
        td {
            padding: 0.75rem 0.25rem;
            vertical-align: middle;
            &.name {
                //max-width: 100px;
            }
        }
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
            .title {
                font-size: 18px;
                font-weight: bold;
                color: $blue;
            }
            .btn-link {
                padding: 0;
                font-size: 12px;
                font-weight: 500;
                color: $gray-900;
            }
        }
    }
    .basket-menu {
        max-width: 520px;
        width: calc(100vw - 1rem);
        right: -0.5rem !important;
        left: auto !important;
        top: calc(100% + 0.85rem);
        padding: 1rem;
        box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.29);
        background-color: $white;
        border: none;
        @include media-breakpoint-down(md) {
            overflow-y: auto;
            max-height: 90vh;
        }
        #default-cart {
            text-align: center;
        }
        .basket-img {
            width: 80px;
            height: 60px;
            border-radius: 9.2px;
            background-color: #d8d8d8;
            object-fit: cover;
        }
        .label {
            font-size: 12px;
            font-weight: 600;
            &.line {
                text-decoration: line-through;
            }
        }
        .info {
            padding-left: 0px;
            font-size: 13px;
            font-weight: 500;
            color: rgba($gray-900, 0.5);
            &.msg {
                color: red;
                margin-top: -4px;
                font-size: 10px;
                white-space: nowrap;
            }
        }
        .price {
            font-size: 15px;
            font-weight: bold;
            color: $blue;
            white-space: nowrap;
        }
        .link {
            font-size: 13px;
            font-weight: 500;
            color: $blue;
            text-decoration: underline;
        }
        .total {
            padding-right: 16px;
            font-size: 19px;
            font-weight: bold;
            text-align: right;
            vertical-align: bottom;
        }
        .total-price {
            font-size: 20px;
            font-weight: bold;
            color: $blue;
            text-align: left;
        }
    }
    .validate-basket {
        border-top: solid 1px #dedede;
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
        .btn {
            display: block;
            width: 80%;
            margin-right: auto;
            margin-left: auto;
            border-radius: 24px;
            font-family: $antique;
            font-size: 13px;
            font-style: italic;
            text-transform: uppercase;
        }
        a.btn {
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-shopping-cart-white {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }
        }
    }
    li.nav-item.active {
        border-bottom: 3px solid #0080ce;
    }
    .navbar-light .navbar-nav .show > .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
    }
    @include media-breakpoint-down(md) {
        .navbar {
            // padding-left: 1rem;
            // padding-right: 1rem;
            .navbar-brand {
                margin-right: 0;
            }
        }
        .logged-user,
        .basket-menu {
            border: none;
        }
        /*
        .basket-toggle {
            margin-top: 1rem;
            margin-left: 0;
        }
        */
    }
}
body.new_payment {
    header {
        .alert {
            display: none;
        }
    }
}

.image_full {
    display: block;
}

.image_mobile {
    display: none;
}

@include media-breakpoint-down(xs) {
    .image_full {
        display: none;
    }

    .image_mobile {
        display: block;
    }
}

.live-course-menu {
    border-bottom: 3px solid $live-course-color !important;
    a.nav-link {
        color: $live-course-color !important;
    }
}

.small-group-menu {
    border-bottom: 3px solid $small-group-color !important;
    a.nav-link {
        color: $small-group-color !important;
    }
}

.personal-training-menu {
    border-bottom: 3px solid $personal-training-color !important;
    a.nav-link {
        color: $personal-training-color !important;
    }
}

.vod-menu {
    border-bottom: 3px solid $vod-color !important;
    a.nav-link {
        color: $vod-color !important;
    }
}

.nutrition-menu {
    border-bottom: 3px solid $nutrition-color !important;
    a.nav-link {
        color: $nutrition-color !important;
    }
}
