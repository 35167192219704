.dashboard {
    position: relative;
    background-color: #f5f6fd;
    &:not(.has-sideabar) {
        .toggler-navbar {
            display: none !important;
        }
    }
    &.has-sideabar {
        .main {
            margin-left: 264px;
            transition: all 0.25s ease-out;
            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }
    }

    header {
        .logged-user {
            border: none;
        }
    }
    .main {
        padding: 20px 0;

        .dropdown-toggle {
            position: relative;
            text-align: left;
            padding: 6px 40px 6px 12px;
            font-size: 0.8125rem;
            font-weight: 500;
            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                border: none;
                width: 10px;
                height: 5px;
                background: url(../../../img/icons/dropdown-arrow.svg) no-repeat;
                background-size: contain;
                transform: translateY(-50%);
            }
        }
        .dropdown-item {
            &.active {
                color: #0080ce;
                background-color: $white;
            }
            &:hover {
                color: #272727;
                text-decoration: none;
                background-color: #f8f9fa;
            }
        }
    }
    .no-member-ship-dashboard {
        padding-top: 3rem !important;
    }
    .section-title {
        font-size: 1.25rem;
    }
}

.box {
    padding: 16px;
    margin-bottom: 30px;
    border-radius: 11px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: $white;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1.125rem;
        font-size: 0.8125rem;
        font-weight: 500;
    }
}

.b-stats {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // width: 360px;
    min-height: 160px;
    height: calc(100% - 30px);
    padding: 16px 96px 16px 24px;
    // margin-right: 21px;
    // margin-bottom: 21px;
    &:before {
        content: "";
        position: absolute;
        bottom: 16px;
        right: 16px;
        width: 60px;
        height: 83px;
        background-size: contain;
    }
    &.flame:before {
        background: url(../../../img/icons/flame-alternate.svg) no-repeat;
    }
    &.stopwatch:before {
        background: url(../../../img/icons/stopwatch-alternate.svg) no-repeat;
    }
    &.sessions:before {
        background: url(../../../img/icons/sessions-alternate.svg) no-repeat;
    }
    &.live:before {
        background-image: url(../../img/icons/wifi-black.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: none;
        opacity: 0.14674;
    }
    &.small-group:before {
        background-image: url(../../../img/icons/small-group.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: none;
        opacity: 0.14674;
    }
    &.p-t:before {
        background-image: url(../../images/icons/users1x.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: none;
        opacity: 0.14674;
    }
    &.total:before {
        background-image: url(../../img/icons/yoga-arm.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: none;
        opacity: 0.14674;
    }
    &.totale:before {
        background-image: url(../../img/icons/nutrition-dark-grey.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: none;
        opacity: 0.14674;
    }
    .title {
        display: block;
        font-size: 0.9375rem;
        font-weight: 600;
        margin-bottom: 0.625rem;
    }
    .dropdown-toggle {
        display: block;
        width: 100%;
        max-width: 156px;
    }
    .result {
        font-size: 2rem;
        font-weight: bold;
        color: $blue;
        .unit {
            font-size: 0.75rem;
            font-weight: 500;
        }
    }
    &.coach {
        min-height: 120px;
        .result {
            font-size: 1.5625rem;
        }
    }
}

.session-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: $lighter-grey;
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
    &.remaining {
        justify-content: space-between;
    }
    .date {
        width: 105px;
        height: 118px;
        border-radius: 11px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 0.8125rem;
        font-weight: 500;
        line-height: normal;
        @include media-breakpoint-down(sm) {
            width: 50%;
        }
        .day {
            font-size: 1.5625rem;
            font-weight: 600;
            color: $blue;
        }
        .month {
            margin-bottom: 0.5rem;
            font-size: 1.0625rem;
            font-weight: 600;
            color: $blue;
            font-size: 0.8125rem;
            line-height: 1rem;
        }
    }
    .session-time {
        position: relative;
        width: 175px;
        height: 120px;
        border-radius: 15px;
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.65;
            // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, $black 84%);
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 60%);
            border-radius: 13px;
        }
        @include media-breakpoint-down(sm) {
            margin-right: 0;
            width: calc(50% - 0.9375rem);
            // flex-basis: 50%;
        }
        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 13px;
        }
        .content {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 100%;
            font-style: italic;
            color: $white;
            z-index: 1;
        }
    }
    .session-desc {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        flex: 1 0 auto;
        @include media-breakpoint-down(sm) {
            margin-top: 0.9375rem;
            margin-bottom: 0.9375rem;
        }
    }

    .rejoindre-session {
        display: flex;
        align-items: center;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        flex-basis: auto;
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
        .btn {
            .waiting {
                opacity: 0.3;
            }
        }
    }
    .remaining-session {
        display: flex;
        align-items: center;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 105px;
        height: 118px;
        border-radius: 11px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--white);
        text-align: center;
        margin-left: auto;
        margin-right: 50px;
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin-right: auto;
            margin-top: 15px;
        }
        span {
            font-family: $antique;
            font-size: 1.75rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: var(--blue);
        }
        p {
            font-size: 0.8125rem;
            font-weight: 600;
            font-style: normal;
            line-height: normal;
        }
    }
    .category {
        opacity: 0.6;
        font-family: $antique-regular;
        font-size: 0.75rem;
        //font-weight: normal;
        font-style: italic;
        //color: $gray-900;
        margin-bottom: 0.9375rem;
        color: #0080ce;
        font-weight: 600;
    }
    .activity {
        font-family: $antique;
        font-size: 1rem;
        font-style: italic;
        margin-bottom: 1.125rem;
    }
    .coach {
        font-size: 0.875rem;
        font-weight: 600;
        .avatar {
            width: 24px;
            height: 24px;
        }
    }
    .badge {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        background: #fff;
        color: $gray-900;
    }
    .drop-actions {
        position: absolute;
        top: 10px;
        right: 0;
        .dropdown-toggle {
            padding: 6px;
            &:after {
                display: none;
            }
        }
    }
    .session-count {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90px;
        height: 68px;
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: $white;
        font-size: 0.8125rem;
        font-weight: 600;
        line-height: normal;
        span {
            font-size: 1rem;
        }
        .nb {
            font-size: 1.75rem;
        }
    }
}

.b-nb-session {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 26px;
    margin-bottom: 10px;
    width: 100%;
    height: 53px;
    border-radius: 12px;
    background-color: $lighter-grey;
    font-size: 0.9375rem;
    font-weight: 600;
    &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: 8px;
        width: 4px;
        height: 36px;
        border-radius: 4.5px;
        background-color: #61c5ea;
    }
}

.activity-chart {
    position: relative;
    padding: 20%;
    .canvas-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.0625rem;
        font-weight: 600;
        text-align: center;
    }
    .canvas-inner span {
        display: block;
        font-size: 1.9375rem;
        font-weight: 600;
    }
}

.zones-chart {
    align-items: center;
    .legend-chard {
        list-style: none;
        padding-left: 0;
        li {
            display: flex;
            align-items: center;
            margin-bottom: 26px;
            font-size: 0.8125rem;
            font-weight: 500;
            .color-box {
                display: inline-block;
                width: 1.125rem;
                height: 1.125rem;
                border-radius: 3px;
                margin-right: 16px;
            }
        }
    }
}

.solde-box {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: $gray-900;
    color: $white;
    // beta-hide uncomment next line when recharge link is displayed again
    // min-height: 160px;
    .solde-info {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .text {
        display: block;
        font-family: $antique;
        font-size: 1.125rem;
        font-style: italic;
        text-transform: uppercase;
        position: relative;
        padding-left: 40px;
        margin-bottom: 10px;
        &:before {
            content: "";
            position: absolute;
            top: 4px;
            left: 10px;
            width: 20px;
            height: 20px;
            background-image: url(../../../img/icons/cash.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    a {
        padding: 0.375rem 0.75rem;
        font-size: 0.75rem;
        text-align: center;
        font-weight: 500;
        color: $white;
        border-radius: 13.5px;
        background-color: rgba($white, 0.23);
    }
    .solde {
        padding: 0.1875rem 0.875rem;
        margin-left: 0.5rem;
        border-radius: 4px;
        color: $blue;
        background-color: $white;
        span {
            font-size: 1.375rem;
            font-weight: bold;
        }
        sup {
            font-size: 0.6875rem;
            font-weight: 600;
        }
    }
    &.command {
        margin-bottom: 0;
        .text {
            padding-left: 0;
            &:before {
                display: none;
            }
        }
        .solde {
            border-radius: 22px;
        }
    }
}

//planing box
.planning-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 15px;
    margin-bottom: 15px;
    background: $lighter-grey;
    border-radius: 10px;
    font-family: $montserrat;
    font-weight: 600;
    font-style: normal;
    .planning-time {
        display: flex;
        align-items: center;
        position: relative;
        min-width: 180px;
        width: calc(20% - 8px);
        height: 70px;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;
        border-radius: 7px;
        &:last-child {
            margin-right: 0;
        }
        /*
        @include media-breakpoint-down(sm) {
            width: 33%;
            max-width: 100%;
        }
        */
        @include media-breakpoint-down(sm) {
            width: calc(50% - 5px);
            &:nth-child(even) {
                margin-right: 0 !important;
            }
        }
        @include media-breakpoint-down(xs) {
            width: 100%;
            margin-right: 0;
        }
        .drop-actions {
            position: absolute;
            top: 0;
            right: 0;
            .dropdown-toggle {
                padding: 6px;
                &:after {
                    display: none;
                }
            }
        }
        &.finish {
            border: solid 1.5px #d6d6d6;
            opacity: 0.5;
        }
        &.empty {
            border: 1.5px dashed rgba(0, 128, 206, 0.39953);
            cursor: pointer;
            .number {
                background: $white;
            }
        }
        &.plan {
            background: $white;
            box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.12);
            .number {
                background: #f6f6f6;
                font-family: $antique-regular;
                font-size: 1.1875rem;
                line-height: 1.375rem;
                text-align: center;
                color: #0080ce;
            }
        }
        .text {
            color: #0080ce;
            top: calc(50% - 28px / 2 + 0.5px);
            font-size: 0.75rem;
            line-height: 0.875rem;
        }
        .number {
            width: 46px;
            height: 54px;
            border-radius: 8px;
            background-color: #ebebeb;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 15px;
            margin: 10px;
            font-family: $antique-regular;
            font-size: 1.1875rem;
            line-height: 1.375rem;
            color: #343434;
            mix-blend-mode: normal;
        }
        .date {
            width: 100px;
            opacity: 0.6;
            font-size: 0.8125rem;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--gray-900);
        }
    }
}

.coach-avatar {
    width: 19px;
    height: 19px;
    border: solid 1px #dddddd;
    border-radius: 15px;
    margin-right: 0.5rem;
}

.pack-session {
    .five-session-pack {
        font-family: $antique;
        font-size: 1.375rem;
        font-style: italic;
        color: $blue;
    }
    .name-coach {
        font-family: $antique;
        font-style: italic;
        font-size: 1rem;
        color: $black;
    }
    .modal-footer {
        border: none;
    }
}

.date-range {
    background: $white;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    width: 100%;
    height: auto;
    font-size: 0.75rem;
    span {
        font-size: 0.75rem;
    }
}

.training-date {
    width: 120px;
}

section.payment {
    .mes-achats {
        padding: 1rem;
        margin-top: 2rem;
        padding-top: 66px;

        #payment-table_filter,
        #transaction-table_filter {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }

        #payment-table_wrapper,
        #transaction-table_wrapper {
            overflow-x: auto;
            margin-bottom: 50px;

        }

        #payment-table_paginate,
        #transaction-table_paginate {
            height: 35px;
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 15px;
        }

        .table-checkout {
            margin-bottom: 0;
            padding: 5px;
        }
    }

    .table-checkout {
        border-collapse: separate;
        border-spacing: 0 15px;
    }
    button.recharge-button {
        background-color: rgba(255, 255, 255, 0.23);
        border-radius: 25px;
        border: none;
        padding: 3px 9px 3px 10px;
        font-size: 0.875rem;
        color: #ffff;
    }
    div#payment-table_filter {
        float: right;
    }
    div#transaction-table_filter {
        float: right;
    }
    input[type="search"] {
        height: 39px;
        border: none;
        background: no-repeat url(../../img/icons/search.svg);
        background-repeat: no-repeat;
        background-position: 10px 7px;
        padding-left: 38px;
        font-size: 0.875rem;
        border-radius: 19.5px;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
        background-color: var(--white);
    }
    .table {
        th {
            padding: 0.65rem;
            vertical-align: top;
            border: none;
            font-family: $montserrat;
            font-weight: normal;
            font-size: 0.8125rem;
            color: #9ba0a3;
        }
        tbody {
            tr {
                &.odd {
                    width: 100%;
                    height: 50px;
                    border-radius: 11px;
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
                    border: solid 1px rgba(161, 161, 161, 0.11);
                    background-color: var(--white);
                    border-spacing: 5em;
                }
                &.even {
                    width: 100%;
                    height: 50px;
                    border-radius: 11px;
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
                    border: solid 1px rgba(161, 161, 161, 0.11);
                    background-color: var(--white);
                    border-spacing: 5em;
                }
                &.tr-odd {
                    border-radius: 0px;
                    background-color: var(--white);
                    box-shadow: none !important;
                }
            }
            td {
                border: none;
            }
            td:first-child {
                border-top-left-radius: 11px;
                border-bottom-left-radius: 11px;
            }
            td:last-child {
                border-top-right-radius: 11px;
                border-bottom-right-radius: 11px;
            }
        }
    }
    .nav-tabs {
        border-bottom: 1px solid #f5f6fd;
    }
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: #000;
        background-color: #ffffff;
        border: none;
        font-weight: 600;
        opacity: 1;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.122842);
        border-radius: 20px;
    }
    section.payment .nav-tabs .nav-link:focus {
        border-color: #f5f6fd;
    }
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        border: none;
    }
    a {
        color: #b2a8c3;
        text-decoration: none;
        background-color: transparent;
        font-size: 0.875rem;
    }
    .nav-tabs .nav-link {
        border: none;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
}

section.edit-profil {
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
        width: 100%;
    }
    input#date_of_birth {
        background-color: #ffffff;
    }
    label {
        font-family: $montserrat;
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--gray-900);
    }
    .nav-tabs {
        border: none;
    }

    .nav {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        align-items: center;
        @include media-breakpoint-down(sm) {
            grid-template-columns: auto;
        }
        /*
        display: inline-flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        */

        .nav-link {
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            color: #343434;
            border-radius: 20px;
            opacity: 0.5;
            border: none;
            &.active {
                font-weight: 600;
                opacity: 1;
                background: #ffffff;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.122842);
            }
        }
    }

    /*
    .nav-tabs {
        .nav-link {
            .active {
                color: #000;
                opacity: 1;
                background-color: #ffffff;
                border-radius: 25px;
                font-weight: 600;
                font-stretch: normal;
                border: none;
                font-style: normal;
                letter-spacing: normal;
            }
        }
    }
    */
    span.unit-cut {
        position: absolute;
        top: 37px;
        right: 28px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    a {
        opacity: 0.5;
        font-size: 0.875rem;
        font-weight: 500;
        color: var(--gray-900);
    }
    .nav-tabs .nav-link:hover {
        font-size: 0.875rem;
        // border: 1px solid #f5f6fd;
    }
    .invalid-feedback.error {
        display: block;
        font-family: $montserrat;
        font-size: 0.875rem;
        margin-top: 4px;
        padding-left: 8px;
        font-style: normal;
        font-weight: 500;
        color: red;
        text-align: left;
    }
    button.btn.btn-primary.edit-profile {
        font-family: AntiqueOliveStd-Nord;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        text-align: center;
        color: var(--white);
    }
    .avatar-upload {
        position: relative;
        max-width: 205px;
        margin: 50px auto;
    }
    .avatar-upload .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 146px;
    }
    .avatar-upload .avatar-edit input {
        display: none;
    }
    .avatar-upload .avatar-edit input + label {
        content: url(../../img/icons/taking-pictures-circle-alternate.svg);
        object-fit: none;
        display: inline-block;
        width: 42px;
        height: 42px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
    }
    .avatar-upload .avatar-edit input + label:hover {
        background: #f1f1f1;
        border-color: #d6d6d6;
    }
    .avatar-upload .avatar-edit input + label:after {
        content: "";
        font-family: "FontAwesome";
        color: #757575;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
    }
    .avatar-upload .avatar-preview {
        width: 192px;
        height: 192px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #f8f8f8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
    .avatar-upload .avatar-preview > div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

section.session {
    .nav {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        align-items: center;
        &.adherent-profile {
            @include media-breakpoint-down(sm) {
                grid-template-columns: auto;
            }
        }
        .nav-item {
            text-align: center;
            .nav-link {
                font-style: normal;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.0625rem;
                color: #343434;
                mix-blend-mode: normal;
                opacity: 0.5;
                padding: 0.5rem 0;
                &.active {
                    font-weight: 600;
                    opacity: 1;
                    background: #ffffff;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.122842);
                    border-radius: 20px;
                }
            }
        }
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        background-color: #ffffff;
        border-color: #ffffff #ffffff #ffffff;
        border-radius: 25px;
        font-size: 0.875rem;
        color: #000;
        font-weight: 600;
    }
    .nav-tabs {
        border-bottom: 1px solid #f5f6fd;
    }
    span.badge.badge-pill.badge-white {
        background-color: #fff;
        color: #0080cf;
    }
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        border-color: #f5f6fd #f5f6fd #f5f6fd;
    }
    a {
        // color: var(--gray-900);
        font-size: 0.875rem;
        text-decoration: none;
        // background-color: transparent;
    }

    /*
    .session-box {
        .date {
            @include media-breakpoint-down(lg) {
                width: 105px;
                flex-basis: auto;
            }
            @include media-breakpoint-down(md) {
                width: 50%;
                flex-basis: 50%;
            }
        }
        .session-time {
            @include media-breakpoint-down(lg) {
                width: 174px;
                flex-basis: auto;
                padding-right: 0.9375rem;
                &:before {
                    width: calc(100% - 0.9375rem);
                }
            }

            @include media-breakpoint-down(md) {
                width: 50%;
                flex-basis: 50%;
            }
        }
    }
      */

    .tab-pane#pack-session {
        .session-box {
            .session-time {
                margin-left: 0;
            }
            .session-desc {
                @include media-breakpoint-down(sm) {
                    width: 50%;
                }
            }
        }
    }
}

a.mr-1.reserve-persoanl-training {
    padding-left: 2.4rem;
}

.empty-basket {
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
}

.title.empty-basket-title {
    border-bottom: 1px solid #bbbbbb52;
    width: 100%;
    padding-bottom: 7px;
}

a.btn.btn-secondary.account-btn.m-3 {
    font-family: "AntiqueOliveStd-Nord", sans-serif;
    font-size: 0.8125rem;
    font-style: italic;
    text-transform: uppercase;
}

.d-flex.flex-row-reverse.bd-highlight {
    &.line-total {
        border-top: 1px solid #dedede;
        padding-top: 1rem;
        padding-bottom: 1rem;
        .p-2.bd-highlight {
            &.price {
                font-family: $montserrat;
                font-style: normal;
                font-weight: bold;
                text-align: end;
                font-size: 1.1875rem;
                line-height: 1.4375rem;
            }
            &.total-cart {
                font-family: $montserrat;
                font-style: normal;
                font-weight: bold;
                font-size: 1.1875rem;
                line-height: 1.4375rem;
                color: #343434;
            }
        }
    }
}
.table {
    .table {
        margin-bottom: 0;
    }
}

table.plan-nutrition {
    th {
        height: 15px;
        font-size: 0.75rem;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #9ba0a3;
    }
    td {
    }
    .coach {
        font-size: 0.875rem;
        font-weight: 600;
    }
}
section.food_survey {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .wrapper {
        position: relative;
    }

    .wrapper span {
        position: absolute;
        right: 14px;
        top: 41px;
        font-weight: 700;
    }

    b {
        position: absolute;
        top: 39px;
        right: 38px;
    }

    .cancel_food_survey {
        opacity: 0.5;
        font-family: $montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.8;
        border: none;
        background: none;
        text-align: center;
        cursor: pointer;
    }

    .visible {
        visibility: visible;
    }
    .invisible {
        display: none;
    }
    label {
        font-family: $montserrat;
        font-size: 1rem;
        font-weight: 500;
    }
    p.purchase {
        font-family: "AntiqueOliveStd-Nord", sans-serif;
        font-size: 20px;
        font-style: italic;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-bottom: 4px solid #0080ce;
        }
    }

    span {
        &.text-danger {
            display: block;
            font-family: $montserrat;
            font-size: 0.875rem;
            margin-top: 4px;
            padding-left: 8px;
            font-style: normal;
            font-weight: 500;
            color: red;
            text-align: left;
        }
    }
}
.recharge-link {
    font-size: 1.2rem !important;
    color: $blue !important;
    text-decoration: underline !important;
    background-color: unset !important;
}

.display-in-header {
    display: none;
}

.payment-membership {
    th {
        &.bg-purple {
            text-align: center;
            border-top: 1px solid #c9c9c9;
            border-left: 1px solid #c9c9c9;
            border-right: 1px solid #c9c9c9;
        }
    }
    td {
        &.plan-text {
            text-align: end;
            font-weight: 600;
        }
    }
    td {
        &.standard {
            text-align: center;
            border-left: 1px solid #c9c9c9;
            border-right: 1px solid #c9c9c9;
        }
    }
    td {
        &.last-row {
            border-bottom: 1px solid #c9c9c9;
        }
    }
    td {
        &.first-row {
            border-left: 1px solid #c9c9c9;
            border-right: 1px solid #c9c9c9;
        }
    }
}

.modal .double-column > div {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}
