.title-calendar {
    display: inline-flex;
    .grid-button {
        position: absolute;
        display: flex;
        align-items: center;
        right: 14px;
        width: 9.875rem;
        height: 39px;
        border: 1px solid #DADADA;
        border-radius: 19.5px;
        font-family: $montserrat;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        .btn-active {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $white;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.116259);
            border-radius: 19.5px;
            color: var(--blue);
            width: 5.8125rem;
            height: 33px;
        }
        .btn-desactive {
            background: none;
            color: var(--gray-900);
            opacity: 0.6;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: inherit;
            margin-left: 16px;
            width: 4.8125rem;
        }
    }
}

.calendar {
    .sessions-container {
        max-height: 398px;
        overflow-y: auto;
        .palinify-session {
            height: 60px;
            margin-bottom: 1rem;
            align-items: center;
            display: flex;
            border-radius: 7px;
            cursor: pointer;
            border: solid 1.5px var(--blue);
            background-color: $lighter-grey;
            .icon {
                position: absolute;
                margin-left: 22px;
            }
            &::before {
                content: "";
                width: 2.625rem;
                height: 46px;
                border-radius: 8px;
                background-color: var(--white);
                margin: 8px 7px 6px 9px;
            }
            &:hover {
                border-style: dashed;

            }
            .title {
                font-size: 13px;
                color: #5292e1;
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
            text-align: center;
            li {
                margin-bottom: 1rem;
                margin-top: 1rem;
                .block-session {
                    display: flex;
                    height: 60px;
                    border-radius: 11px;
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
                    border: solid 1px rgba(161, 161, 161, 0.11);
                    background-color: var(--white);
                    font-family: $montserrat;
                    font-weight: 500;
                    .heure {
                        opacity: 0.5;
                        color: #434343;
                        margin-left: 23px;
                        margin-top: 11px;
                    }
                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        color: #040404;
                        margin-left: 14px;
                        margin-top: 11px;
                        text-align: initial;
                        .coach {
                            font-size: 12px;
                            font-weight: normal;
                            color: var(--gray-900);
                            margin-top: 5px;
                            text-align: initial;
                        }
                    }
                    &::before {
                        content: "";
                        width: 10px;
                        height: 60px;
                        border-radius: 43px 0px 0px 43px;
                        background-color: var(--blue);
                    }
                }
            }
        }
    }
    .fc-button-primary {
        color: #fff;
        background-color: #fff;
        border-color: #fff;
    }
    .p-calendar {
        position: relative;
        top: 3px;
    }
    .p-icon-calendar {
        position: relative;
        top: 13px;
        left: 8px;
    }
    div.bg-white.pt-3.fc.fc-ltr.fc-unthemed {
        border-radius: 13px;
    }
    .fc-dayGrid-view .fc-body .fc-row {
        min-height: 6.5em;
    }
    .fc-other-month {
        background-color: #f7f7f7;
    }
    .fc-day-top.disable-day-calendar {
        position: relative;
        color: #bbbbbb;
    }
    .fc-dayGrid-view .fc-week-number,
    .fc-dayGrid-view .fc-day-number {
        padding: 13px;
        opacity: 0.5;
        font-size: 13.8px;
        font-weight: 600;
        color: var(--gray-900);
    }
    td.fc-more-cell {
        //position: absolute;
        top: 80px;
        font-size: 14.7px;
        font-weight: 600;
    }
    .fc-header.fc-widget-header {
        border-radius: 14px;
    }
    .fc-popover.fc-more-popover {
        border-radius: 12px;
    }
    .sessions-container {
        max-height: 398px;
        overflow-y: auto;
        .palinify-session {
            height: 60px;
            margin-bottom: 1rem;
            align-items: center;
            display: flex;
            border-radius: 7px;
            border: solid 1.5px $blue;
            border-style: dashed;
            background-color: $lighter-grey;
            .icon {
                position: absolute;
                margin-left: 22px;
            }
            &::before {
                content: "";
                width: 42px;
                height: 46px;
                border-radius: 8px;
                background-color: var(--white);
                margin: 8px 7px 6px 9px;
            }
            .title {
                font-size: 13px;
                color: #5292e1;
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
            text-align: center;
            li {
                margin-bottom: 1rem;
                .block-session {
                    display: flex;
                    height: 80px;
                    border-radius: 11px;
                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
                    border: solid 1px rgba(161, 161, 161, 0.11);
                    background-color: var(--white);
                    font-family: $montserrat;
                    font-weight: 500;
                    .heure {
                        opacity: 0.5;
                        color: #434343;
                        margin-left: 23px;
                        margin-top: 11px;
                    }
                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        color: #040404;
                        margin-left: 14px;
                        margin-top: 11px;
                        .coach {
                            font-size: 12px;
                            font-weight: normal;
                            color: var(--gray-900);
                        }
                    }
                    &::before {
                        content: "";
                        width: 10px;
                        height: 80px;
                        border-radius: 43px 0px 0px 43px;
                        background-color: var(--blue);
                    }
                }
            }
        }
    }
    .fc-day-top.disable-day-calendar {
        position: relative;
        color: #bbbbbb;
    }
    .fc-toolbar h2 {
        font-family: $montserrat;
        font-size: 18.6px;
        font-weight: 600;
        text-transform: capitalize;
    }
    tr:first-child>td>.fc-day-grid-event {
        position: relative;
        top: 0px;
    }
    button.fc-prev-button.fc-button.fc-button-primary {
        color: #0080ce;
    }
    button.fc-next-button.fc-button.fc-button-primary {
        color: #0080ce;
    }
    .fc-unthemed tbody td span {
        font-size: 0.85rem;
        color: $white;
        background: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    td {
        border-color: #ddd;
    }
    .fc-day-grid-event .fc-time {
        display: none;
    }
    .fc-toolbar {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0.5em;
    }
    .fc-event {
        background: #343434;
        color: $white;
        border-radius: 5px;
        border: 0px solid #343434;
        &:hover {
            color: $white;
        }
        .fc-content {
            display: flex;
            justify-content: center;
            &:before {
                content: "";
                position: absolute;
                left: 0px;
                width: 6px;
                height: 23px;
                background: transparent;
                border-radius: 5px 0px 0px 5px;
            }
        }
    }
    .fc-dayGrid-view .fc-body .fc-row {
        min-height: 7rem;
    }
    @include media-breakpoint-up(md) {
        .fc-unthemed tbody td span {
            width: 100%;
            height: 26px;
        }
    }
}

.palinification-session-modal {
    font-family: $montserrat;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    .title-icon {
        width: 23px;
        height: 10px;
        font-family: $antique;
        font-size: 10px;
        opacity: 0.3;
        font-style: italic;
        color: var(--gray-900);
    }
    .title {
        font-size: 18px;
        text-align: center;
        color: var(--gray-900);
    }
    .title-day {
        font-size: 24px;
        text-align: center;
        color: var(--blue);
    }
    .pack-card {
        width: 20.1875rem;
        height: 150px;
        border-radius: 9px;
        background-color: $lighter-grey;
        cursor: pointer;
        &.selected {
            box-shadow: 0 13px 28px 0 rgba(0, 0, 0, 0.12);
            border: solid 3px var(--blue);
        }
    }
    .icon {
        width: 22px;
        height: 13px;
        object-fit: contain;
        opacity: 0.3;
    }
    .pack-title {
        font-family: $antique;
        font-size: 13px;
        font-style: italic;
        line-height: 1.62;
        letter-spacing: normal;
        font-weight: 500;
        color: var(--gray-900);
    }
    .coach {
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: var(--gray-900);
        img {
            width: 24px;
            height: 24px;
            border: solid 1px #dddddd;
        }
    }
    .remaining-session {
        width: 6.6875rem;
        height: 91px;
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--white);
        font-size: 12px;
        margin: auto;
        text-align: center;
        .number {
            font-size: 28px;
            font-weight: 600;
            color: var(--blue);
        }
    }
    .pack-session-slider {
        &.owl-theme .owl-nav {
            display: none;
            @media (min-width: 1100px) {
                display: block;
            }
        }
        .owl-nav button.owl-prev {
            position: absolute;
            top: 62px;
            left: -56px;
            width: 20px;
        }
        .owl-nav button.owl-next {
            position: absolute;
            top: 62px;
            right: -40px;
            width: 20px;
            &:hover {
                background: transparent;
            }
        }
        .owl-nav .owl-prev.disabled,
        .owl-nav .owl-next.disabled {
            opacity: 0.6;
        }
        .owl-nav button.owl-prev {
            position: absolute;
            top: 62px;
            right: -40px;
            width: 20px;
            &:hover {
                background: transparent;
            }
        }
        .nav-prev {
            width: 40px;
            height: 40px;
            opacity: 0.6;
        }
        .nav-next {
            width: 40px;
            height: 40px;
        }
    }
    .times {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 1rem;
        justify-content: center;
        .time {
            padding: 8px 12px;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 8px;
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.14);
            background-color: $white;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
            &.selected {
                box-shadow: 0 8px 9px 0 rgba(0, 0, 0, 0.14);
                border: solid 2px var(--blue);
                background-color: var(--white);
                color: var(--blue);
            }
            .icon {
                width: 20px;
                height: 20px;
            }
        }
    }
    .validation {
        width: 19.875rem;
        height: 48px;
        border-radius: 24px;
        box-shadow: 0 4px 10px 0 rgba(3, 109, 173, 0.3);
        background-color: var(--blue);
        font-family: $antique;
        font-size: 13px;
    }
}

.message-title {
    font-family: $montserrat;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 18px;
    color: var(--gray-900);
}