.checkout {
    margin: auto;
    img.activity-checkout {
        margin: 4.2px;
        width: 18px;
        height: 13px;
    }
    td.name-checkout.roundleft {
        font-size: 13px;
    }
    button.btn.btn-primary.btn-block.paiement-checkout {
        height: 48px;
        font-family: $antique;
        font-size: 13px;
        border-radius: 25px;
        font-style: italic;
        text-align: center;
        color: var(--white);
    }
    img.coach-checkout {
        border-radius: 25px;
        height: 25px;
        width: 25px;
        border: solid 1px #dddddd;
        object-fit: cover;
    }
    span.name-coach {
        font-size: 14px;
        font-weight: 500;
        margin-left: 0.2rem;
    }
    .type-checkout-payment {
        li {
            position: relative;
            list-style-type: none;
        }
    }
    .check {
        position: absolute;
        top: 28px;
        left: 18px;
    }
    .cart {
        position: absolute;
        top: 23px;
        left: 203px;
    }
    a.recharge-wallet {
        font-size: 13px;
        font-weight: 500;
        color: var(--gray-900);
    }
    .badge-wallet {
        background-color: #e6f3fa;
        /* opacity: 0.1; */
        min-width: 84px;
        height: 25px;
        border-radius: 12.5px;
        color: #0080ce;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        top: 27px;
        right: 1px;
    }
    ul.type-checkout-payment {
        padding: 0;
        width: 100%;
        margin: 0;
        li.active {
            border-radius: 7px;
            height: 80px;
            border: solid 2px var(--blue);
            font-size: 15px;
            font-weight: bold;
            text-align: center;
            color: var(--blue);
        }
        li.inactive {
            border-radius: 7px;
            height: 80px;
            border: solid 2px #c0cbd2;
            font-size: 15px;
            font-weight: 600;
            color: var(--gray-900);
        }
    }
    .purchase {
        font-family: $antique;
        font-size: 20px;
        font-style: italic;
        color: var(--gray-900);
        margin-bottom: 7px;
    }
    p.checkout-purchase-card {
        font-size: 15px;
        font-weight: 600;
        margin-top: 0.5rem;
        color: var(--blue);
    }
    hr.purchase-hr {
        margin: -4px;
        width: 161px;
        border: solid 2px var(--blue);
    }
    td.roundleft {
        border-top-left-radius: 11px;
        border-bottom-left-radius: 11px;
        -moz-border-radius-topleft: 11px;
        /* Firefox top left corner */
        -moz-border-radius-bottomleft: 11px;
        /* Firefox bottom left corner */
    }
    td.roundright {
        -moz-border-radius-topright: 11px;
        /* Firefox top right corner */
        -moz-border-radius-bottomright: 11px;
        /* Firefox bottom right corner */
        border-top-right-radius: 11px;
        border-bottom-right-radius: 11px;
    }
    .card.checkout-card {
        border-radius: 11px;
        padding: 1rem;
    }
    .remise-checkout {
        font-size: 14px;
        font-weight: 500;
        color: var(--blue);
    }
    .total-checkout {
        font-size: 14px;
        font-weight: bold;
        color: var(--gray-900);
    }
    .checkout-total {
        font-size: 19px;
        font-weight: bold;
        color: var(--gray-900);
    }
    .table-checkout {
        border-collapse: separate;
        border-spacing: 0 15px;
        thead {
            height: 14px;
            tr {
                th.text-checkout {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    padding-bottom: 0;
                    color: #9ba0a3;
                }
            }
        }
        tr.data-checkout {
            height: 71px;
            border-radius: 11px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
            background-color: var(--white);
            td {
                vertical-align: middle;
            }
            td.name-checkout.sorting_1 {
                font-size: 14px;
                font-weight: 600;
                color: var(--gray-900);
            }
            td.date-checkout {
                font-size: 12px;
                font-weight: 500;
                color: var(--gray-900);
            }
            .info {
                font-size: 14px;
                font-weight: 500;
                color: var(--gray-900);
            }
            td.type-checkout {
                font-size: 13px;
                font-weight: 500;
                color: var(--gray-900);
            }
            p.price {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 0;
                color: #0080ce;
                white-space: nowrap;
            }
            span.price {
                font-size: 11px;
                font-weight: bold;
                color: #0080ce;
            }
        }
    }
    .five-session-pack {
        font-family: "AntiqueOliveStd-Nord", sans-serif;
        font-size: 1.375rem;
        font-style: italic;
        color: #0080ce;
    }
    .text-basket-process.text-center {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.8;
        text-align: center;
    }
}

#dropdownBasket,
#loggedUser {
    &:hover {
        cursor: pointer;
    }
}

img.activity-checkout {
    margin: 4.2px;
    width: 22px !important;
    height: 20px;
}