body {
    font-size: 16px;
    // font-family: $geogrotesque;
    // &:before {
    //     content: "";
    //     background-image: url(../../img/cgym.png);
    //     background-position: top center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     position: absolute;
    //     top: 0;
    //     left: calc(50% - 600px);
    //     width: 1200px;
    //     height: 4000px;
    //     z-index: 1;
    // }
    @include media-breakpoint-down(md) {
        font-size: 14px;
    }
    @include media-breakpoint-down(lg) {
        .container-lg,
        .container-md,
        .container-sm,
        .container {
            max-width: 100% !important;
        }
    }
    &.header-relative {
        padding-top: 82px;
    }
}

.btn {
    font-family: $geogrotesque;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    padding: 0.5rem 1.5625rem;
}

.btn-white {
    color: $gray-900;
    background-color: $white;
    border-color: $white;
    &:hover {
        color: $white;
        background-color: $gray-900;
        border-color: $gray-900;
    }
}

.btn-outline-light {
    color: $white;
    background-color: transparent;
    border-color: $white;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    &:hover {
        color: $blue-prussian;
        background-color: $white;
        border-color: $white;
    }
}

// .btn-outline-primary {
//     color: $blue-prussian;
//     border-color: $blue-prussian;
// }
.btn-light {
    color: $blue;
    &:hover {
        color: $white;
        background-color: $blue;
        border-color: $blue;
    }
}

.btn-light-slide {
    color: $blue;
    background-color: #fff;
    border-color: $blue;
    &:hover {
        color: $blue;
        background-color: #fff;
        border-color: $blue;
    }
}

button.btn.btn-primary.mr-2.liveModalSecondSlide {
    color: $blue;
    background-color: #fff;
    border-color: $blue;
}
.show-price {
    display: none;
}
.number-sess-header {
   max-width: 100%!important;
}

.btn-outline-dark {
    color: #ffffff;
}
.btn-card {
    text-decoration: none !important;
    margin-bottom: 1rem;
    &:hover {
        box-shadow: 0 15px 30px 0 rgb(0 0 0 / 22%);
    }
}
.disabledButton {
    pointer-events: none;
    opacity: 0.4;
}

.btn-outline-secondary {
    color: $gray-900;
}

.badge-white {
    color: $white;
    border: solid 0.8px $white;
}

p {
    font-size: 0.9375rem;
    line-height: 1.87;
}

*:focus {
    outline: none !important;
}

img.avatar {
    width: 34px;
    height: 34px;
    object-fit: cover;
    // border: solid 1px #dddddd;
    border-radius: 5px;
}

// Sections
.section-icon {
    display: block;
    margin: 1rem auto;
}

.section-title {
    position: relative;
    display: inline-block;
    font-family: $geogrotesque;
    font-size: 2.4rem;
    font-weight: bold;
    font-style: italic;
    text-transform: capitalize;
    text-align: left;
    color: $gray-fuscous;
    padding-bottom: 0.5rem;
    margin-bottom: 1.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 3px solid $blue;
    }
}

.section-msg {
    position: relative;
    display: inline-block;
    font-family: $antique;
    font-size: 1.2rem;
    font-style: italic;
    text-transform: uppercase;
    text-align: left;
    color: $gray-900;
    padding-bottom: 0;
    margin-bottom: 0;
}

.section-description {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.8;
    text-align: center;
}

.text-none {
    text-transform: none !important;
}

.fc-scroller {
    height: auto !important;
}

// Numbered list
ul.numbered-list {
    padding-left: 0;
    li {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 38px;
        margin-bottom: 1rem;
        padding-left: 4rem;
        list-style-type: none;
        counter-increment: li;
        &:before {
            content: counter(li, decimal);
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 40px;
            background: url(../../img/icons/rectangle.svg) no-repeat;
            font-family: $montserrat;
            font-size: 1.0625rem;
            font-weight: 600;
            color: $blue;
        }
    }
}

ul.checked-list {
    padding-left: 0;
    list-style: none;
    li {
        position: relative;
        margin-bottom: 1rem;
        padding-left: 2.25rem;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 25px;
            height: 25px;
            background: url(../../img/icons/check.svg) no-repeat;
        }
    }
}

ul.tick-list {
    padding-left: 0;
    list-style: none;
    li {
        position: relative;
        margin-bottom: 1.5rem;
        padding-left: 2.25rem;
        font-weight: 500;
        &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            width: 18px;
            height: 18px;
            background: url(../../img/tick-blue.svg) no-repeat;
            background-size: contain;
        }
    }
}

.maxed-list {
    ul {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 31px;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white);
        }
        &.hidden:after {
            display: none;
        }
        li:nth-child(n + 5) {
            display: none;
        }
    }
    .show-more {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
        font-size: 0.8125rem;
        font-weight: 500;
        color: $blue;
        cursor: pointer;
    }
}

// gradient background
.bg-gradient-1 {
    background-image: linear-gradient(to bottom, $white, #f7f7f7 55%);
}

// back link
.back-link {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: $gray-900;
}

// pagination
.pagination {
    flex-wrap: wrap;
    .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 40px;
        height: 40px;
        color: $blue;
        border: none;
        border-radius: 50%;
        font-family: $montserrat;
        font-size: 17px;
        font-weight: 600;
        &.active {
            color: $white;
            background-color: $blue;
        }
    }
    .page-item:first-child .page-link {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }
    .page-item:last-child .page-link {
        border: solid 1px $blue;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }
}

.irs--round .irs-handle {
    border: solid 2px #9db1ca;
}

.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single,
.irs--round .irs-bar {
    background-color: $blue;
}

.badge {
    margin-right: 0.75rem;
    font-family: $montserrat;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
}

.badge-info {
    color: $blue;
    background-color: rgba($blue, 0.1);
}

.fix-right {
    right: 0 !important;
    left: auto !important;
    transform: translate3d(-5px, 28px, 0px) !important;
}

.stars {
    display: inline-block;
    input.star {
        display: none;
    }
    label.star {
        float: right;
        transition: all 0.2s;
    }
    label.star:hover {
        transform: rotate(-15deg) scale(1.3);
    }
    label.star:before {
        content: "";
        display: block;
        background-image: url(../../img/icons/star-empty.svg);
        width: 37px;
        height: 37px;
        margin-right: 4px;
        margin-left: 4px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    input.star:checked ~ label.star:before {
        transition: all 0.25s;
        background-image: url(../../img/icons/star.svg);
    }
    input.star-5:checked ~ label.star:before {
        color: #fe7;
        text-shadow: 0 0 20px #952;
    }
    input.star-1:checked ~ label.star:before {
        color: #f62;
    }
}

.border-none {
    border: none !important;
}

.btn-link {
    font-style: normal;
    color: $blue;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    padding: 5px;
    text-decoration: underline;
}

.badge-pack {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.084599);
    border-radius: 8.5px;
    font-family: AntiqueOliveStd !important;
    font-size: 10px;
    line-height: 12px;
    margin-left: 13px;
    color: #0080ce !important;
    padding: 4px 0.7em !important;
    font-weight: 500 !important;
    width: fit-content;
    height: fit-content;
}
dd.history-text {
    white-space: pre-wrap;
}

.opac-100 {
    opacity: 1 !important;
}

.opac-30 {
    opacity: 0.3 !important;
}

.black-filter {
    filter: grayscale(1) brightness(0.55);
}

.white-filter {
    filter: brightness(0) invert(1);
}
.h-fit-content {
    height: fit-content !important;
}

.jq-toast-wrap.bottom-right {
    bottom: 1px !important;
    right: 7px !important;
}

.coming-soon {
    background-image: url(../../img/coming-soon.png);
    background-repeat: no-repeat;
    height: 280px;
}

.coming-soon-card {
    height: 280px;
}

.next-session-label {
    font-family : $geogrotesque;
    font-size: 16px;
    line-height: 18.62px;
    font-weight: 500;
    position: absolute;
    top: 68px;
    left: 0;
    right: 0;    
    margin-left: auto;
    margin-right: auto;
}

.coming-soon-label{
    font-family : $geogrotesque;
    font-weight : bold;
    font-style : italic;
    font-size : 25px;
    line-height : 31.22px;
    opacity: 0.6;
    position: absolute;
    top: 14.8rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.next-session-icon {
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
