.icon-container {
    display: flex;
    align-items: center;
    .icon {
        margin-right: 7px;
    }
    .left {
        margin-right: 0px;
        margin-left: 7px;
    }
}

.icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.active {
        filter: invert(27%) sepia(92%) saturate(2737%) hue-rotate(185deg) brightness(98%) contrast(101%);
    }
    &.disactive {
        filter: invert(72%) sepia(3%) saturate(6%) hue-rotate(100deg) brightness(87%) contrast(84%);
    }
}

.i-sm {
    width: 12px;
    height: 12px;
}

.i-lg {
    width: 18px;
    height: 18px;
}

.i-xl {
    width: 22px;
    height: 22px;
}

.i-sxxl {
    width: 30px;
    height: 30px;
}

.i-xxl {
    width: 43px;
    height: 42px;
    background-repeat: no-repeat;
}

.i-xxxl {
    width: 80px;
    height: 50px;
    background-repeat: no-repeat;
}

.i-xxxxl {
    width: 80px;
    height: 107px;
    background-repeat: no-repeat;
}

.i-xxxxxl {
    width: 289px;
    height: 170px;
    background-repeat: no-repeat;
}

.i-card-users {
    width: 27px;
    height: 22px;
    background-repeat: no-repeat;
}

.i-card-user-pack {
    width: 20px;
    height: 22px;
    background-repeat: no-repeat;
}


.i-card-pt-pack {
    height: 79px;
    width: 75px;
    background-repeat: no-repeat;
}

.i-card-size {
    height: 25px;
    width: 24px;
    background-repeat: no-repeat;
}

.i-star-empty-size {
    height: 15px;
    width: 15px;
    background-repeat: no-repeat;
}

.i-star-size {
    height: 11px;
    width: 11px;
    margin-left: 0.15rem !important;
    background-repeat: no-repeat;
}

.i-book-size {
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
}

.icon-stopwatch {
    background-image: url(../../../img/icons/stopwatch.svg);
}

.icon-stopwatch-alternative {
    background-image: url(../../../img/icons/stopwatch-alternate.svg);
}

.icon-triangle {
    background-image: url(../../../img/icons/Triangle.svg);
}

.icon-triangle-up {
    transform: rotate(180deg);
    background-image: url(../../../img/icons/Triangle.svg);
}

.icon-shopping-basket {
    background-image: url(../../../img/icons/shopping-basket.svg);
}

//.icon-plus-money {
//    background-image: url(../../../img/icons/plus.svg);
//}
.icon-1to1 {
    background-image: url(../../../img/icons/1t1.svg);
}

.icon-1to1-grey {
    background-image: url(../../../img/icons/1t1-grey.svg);
}

.icon-1to1-bleu {
    background-image: url(../../../img/icons/PT.svg);
}

.icon-onetoone-white {
    background-image: url(../../../img/icons/wifi-signal-1.svg);
}

.icon-search {
    background-image: url(../../../img/icons/search.svg);
}

.icon-coming-soon {
    background-image: url(../../../img/icons/bientot-01.svg);
}

.icon-iphone-send {
    background-image: url(../../../img/icons/smartphone-sending-data.svg);
}
.icon-nutrition-pack {
    background-image: url(../../../img/icons/nutrition-pack.svg);
}
.icon-nutrition-black {
    background-image: url(../../../img/icons/nutrition-black.svg);
}

.icon-small-group {
    background-image: url(../../../img/icons/small-group.svg);
}

.icon-small-group-white {
    background-image: url(../../../img/icons/small-group-white.svg);
}
.icon-audit-report-survey{
    background-image: url(../../../img/icons/survey.svg);
}

.icon-p-t{
    background-image: url(../../../img/icons/multiple-users-wifi.svg);
}

.icon-cart {
    background-image: url(../../../img/icons/cart.svg);
}

.icon-lock {
    background-image: url(../../../img/icons/lock-2.svg);
}

.icon-single-man {
    background-image: url(../../../img/icons/single-man-actions.svg);
}

.icon-black-wallet {
    background-image: url(../../../img/icons/black-wallet.svg);
}

.icon-credit-card {
    background-image: url(../../../img/icons/credit-card.svg);
}

.icon-accounting-coins-bill {
    background-image: url(../../../img/icons/accounting-coins-bill.svg);
}

.icon-visa {
    width: 30px;
    height: 30px;
    background-image: url(../../../img/icons/visa.svg);
}

.icon-master-card {
    width: 30px;
    height: 30px;
    background-image: url(../../../img/icons/master-card.svg);
}

.icon-trash-checkout {
    cursor: pointer;
    opacity: 1;
    background-image: url(../../../img/icons/trash-checkout.svg);
}

.icon-money-wallet {
    background-image: url(../../../img/icons/money-wallet.svg);
}

.icon-red-cross {
    background-image: url(../../../img/icons/red-cross-01.svg);
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
}

.icon-blue-check {
    background-image: url(../../../img/icons/check.svg);
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.icon-wallet-empty {
    background-image: url(../../../img/icons/wallet-empty.svg);
}

.icon-star {
    background-image: url(../../img/icons/star.svg);
}

.icon-star-gray {
    background-image: url(../../img/icons/star-gray.svg);
}

.icon-money-wallet-blue {
    background-image: url(../../img/icons/money-wallet-blue.svg);
}

.icon-men {
    background-image: url(../../img/icons/people-man-beard-1.svg);
}

.icon-women {
    background-image: url(../../img/icons/single-woman-actions.svg);
}

.icon-user {
    background-image: url(../../img/icons/user.svg);
}

.icon-padlock {
    background-image: url(../../img/icons/padlock.svg);
}

.icon-location {
    background-image: url(../../img/icons/location.svg);
}
.icon-location-blue {
    background-image: url(../../img/icons/location-blue.svg);
    background-position: bottom center;
    width: 22px;
    height: 25px;
}
.icon-phone-blue {
    background-image: url(../../img/icons/v2/phone-blue.svg);
    width: 22px;
    height: 25px;
    background-size: cover;
    background-position: bottom;
}
.icon-phone-gray {
    background-image: url(../../img/icons/v2/phone-gray.svg);
    width: 22px;
    height: 25px;
    background-size: cover;
    background-position: bottom;
}
.icon-ruler {
    background-image: url(../../img/icons/ruler.svg);
}

.icon-weight {
    background-image: url(../../img/icons/weight.svg);
}

.icon-money {
    background-image: url(../../img/icons/money.svg);
}

.icon-imc {
    background-image: url(../../img/icons/v2/imc.svg);
}

.icon-star-empty {
    background-image: url(../../img/icons/star-empty.svg);
}

.icon-star-empty-gray {
    background-image: url(../../img/icons/star-empty-gray.svg);
}

.icon-wifi {
    width: 15px;
    height: 10px;
    background-image: url(../../img/icons/wifi.svg);
}

.icon-wifi-black {
    background-image: url(../../img/icons/wifi-black.svg);
}

.icon-coach {
    background-image: url(../../img/icons/coach.svg);
}

.icon-calendar {
    background-image: url(../../img/icons/calendar.svg);
}

.icon-calendar-white {
    background-image: url(../../img/icons/calendar-white.svg);
}

.icon-calendar-black {
    background-image: url(../../img/icons/calendar-black.svg);
}

.icon-clock {
    opacity: 0.25;
    background-image: url(../../img/icons/clock-black.svg);
    &.clear {
        opacity: 1;
    }
}

.icon-close {
    opacity: 1;
    background-image: url(../../img/icons/close.svg);
}

.icon-foursquare-check-in {
    opacity: 1;
    background-image: url(../../img/icons/foursquare-check-in.svg);
}

.icon-clock-black {
    background-image: url(../../img/icons/clock-black.svg);
}

.icon-clock-white {
    background-image: url(../../img/icons/clock-white.svg);
}

.icon-workout-blue {
    background-image: url(../../img/icons/workout-blue.svg);
}

.icon-workout-white {
    background-image: url(../../img/icons/workout-white.svg);
}

.icon-workout-black {
    background-image: url(../../img/icons/workout-black.svg);
}

.icon-yoga {
    background-image: url(../../img/icons/yoga.svg);
}

.icon-yoga-white {
    background-image: url(../../img/icons/yoga-white.svg);
}

.icon-balance {
    background-image: url(../../img/icons/balance.svg);
}

.icon-white-cn-calendar {
    background-image: url(../../img/icons/cn-calendar.svg);
}

.icon-white-pt-calendar {
    background-image: url(../../img/icons/pt-calendar.svg);
}

.icon-white-sg-calendar {
    background-image: url(../../img/icons/sg-calendar.svg);
}

.icon-flag-tn {
    background-image: url(../../img/icons/tn.png);
}

.icon-monitor {
    background-image: url(../../img/icons/monitor.svg);
}

.icon-monitor-blue {
    background-image: url(../../img/icons/monitor-blue.svg);
}

.icon-yoga-arm {
    background-image: url(../../img/icons/yoga-arm.svg);
}

.icon-yoga-arm-black {
    background-image: url(../../img/icons/yoga-arm-black.svg);
    width: 30px;
    height: 24px;
}

.icon-calendrier {
    background-image: url(../../img/icons/calendrier.svg);
}

.icon-alarm {
    background-image: url(../../img/icons/alarm.svg);
}

.icon-logout {
    background-image: url(../../img/icons/logout.svg);
}

.icon-eye {
    background-image: url(../../images/icons/view-1.svg);
}

.icon-eye-gray {
    background-image: url(../../images/icons/view-gray.svg);
}

.icon-intensity-0 {
    background-image: url(../../images/icons/intensity1.svg);
}

.icon-intensity-1 {
    background-image: url(../../images/icons/intensity1.svg);
}

.icon-intensity-2 {
    background-image: url(../../images/icons/intensity2.svg);
}

.icon-intensity-3 {
    background-image: url(../../images/icons/intensity3.svg);
}

.icon-filter {
    background-image: url(../../images/icons/filter.svg);
}

.icon-up-arrow {
    background-image: url(../../images/icons/up-arrow.svg);
}

.icon-down-arrow {
    background-image: url(../../images/icons/down-arrow.svg);
}

.icon-info-white {
    background-image: url(../../img/icons/info-white-2.svg);
    margin: -1px 0px -7px 1px;
    cursor: pointer;
}

.icon-right {
    position: absolute;
    right: 6px;
    &.intensity {
        top: 0;
        right: 0;
    }
}

.icon-users-1x {
    background-image: url(../../images/icons/users1x.svg);
}

.icon-users-5x {
    background-image: url(../../images/icons/one-to-one-5.svg);
}

.icon-plus {
    background-image: url(../../images/icons/plus+.svg);
}

.icon-threedots {
    background-image: url(../../images/icons/threedots.svg);
}

.icon-slider-prev {
    background-image: url(../../img/prev.svg);
}

.icon-slider-next {
    background-image: url(../../img/next.svg);
}

.icon-404 {
    background-image: url(../../img/404.svg);
    width: 100%;
    padding-top: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon-membership-expired {
    background-image: url(../../img/membership-expired.svg);
    width: 100%;
    padding-top: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon-shopping-cart-white {
    background-image: url(../../img/icons/shopping-cart-white.svg);
}

.icon-shopping-cart-blue {
    background-image: url(../../img/icons/shopping-cart-blue.svg);
}

.icon-calories {
    background-image: url(../../img/icons/calories.svg);
}

.icon-nutri-dash {
    background-image: url(../../img/icons/nutrition-dark-grey.svg);
}

.icon-nutri-grey {
    background-image: url(../../img/icons/nutrition-grey-session.svg);
}


.icon-arrow-right {
    background-image: url(../../img/icons/right-arrow.svg);
}

.icon-c-n{
    background-image: url(../../../img/icons/nutrition-calendar.svg);
}

.icon-info-solid {
    background-image: url(../../../img/icons/info-circle-solid.svg);
}

.icon-alert-delete{
    background-image: url(../../../img/error_red.svg);
}

// V2 icones
.icon-sort-by {
    background-image: url(../../img/icons/v2/sort-by.svg);
}

.icon-goal {
    background-image: url(../../img/icons/v2/goal.svg);
}

.icon-envelope {
    background-image: url(../../img/icons/v2/envelope.svg);
}

.icon-envelope-gray {
    background-image: url(../../img/icons/v2/envelope-gray.svg);
}
.icon-login-gray {
    background-image: url(../../img/icons/v2/login.svg);
}
.icon-envelope-blue {
    background-image: url(../../img/icons/v2/envelope-blue.svg);
}
.icon-password-confirmation {
    background-image: url(../../img/icons/v2/password-confirmation.svg);
}

.icon-coming-soon-2 {
    background-image: url(../../img/icons/v2/coming-soon.svg);
}

.icon-coming-soon-white {
    background-image: url(../../img/icons/v2/coming-soon-white.svg);
}

.icon-info {
    background-image: url(../../img/icons/v2/info.svg);
}
.icon-info-grey {
    background-image: url(../../img/icons/v2/info-grey.svg);
}

.icon-calendar-white-2 {
    background-image: url(../../img/icons/v2/calendar-white.svg);
}

.icon-time-white {
    background-image: url(../../img/icons/v2/time-white.svg);
}

.icon-coach-white {
    background-image: url(../../img/icons/v2/coach-white.svg);
}

.icon-book {
    background-image: url(../../img/icons/v2/book.svg);
}

.icon-book-white {
    background-image: url(../../img/icons/v2/book-white.svg);
}

.icon-star-2 {
    background-image: url(../../img/icons/v2/star.svg);
}

.icon-star-empty-2 {
    background-image: url(../../img/icons/v2/star-empty.svg);
}

.icon-user-gray {
    background-image: url(../../img/icons/v2/user-gray.svg);
}

.icon-board-gray {
    background-image: url(../../img/icons/v2/board-gray.svg);
}

.icon-password-gray {
    background-image: url(../../img/icons/v2/password-gray.svg);
}

.icon-small-group-white-2 {
    background-image: url(../../img/icons/v2/small-group-white.svg);
}

.icon-live {
    background-image: url(../../img/icons/v2/live.svg);
}

.icon-live-gray {
    background-image: url(../../img/icons/v2/live-gray.svg);
}

.icon-live-white {
    background-image: url(../../img/icons/v2/live-white.svg);
}

.icon-vod-white {
    background-image: url(../../img/icons/v2/vod-white.svg);
}

.icon-calories-white {
    background-image: url(../../img/icons/v2/calories-white.svg);
}

.icon-goal-white {
    background-image: url(../../img/icons/v2/goal-white.svg);
}

.icon-body-area-white {
    background-image: url(../../img/icons/v2/body-area-white.svg);
}

.icon-body-area-white-2 {
    background-image: url(../../img/icons/v2/body-area-white-2.svg);
}

.icon-dashboard-blue {
    background-image: url(../../img/icons/v2/dashboard-blue.svg);
}

.icon-session-gray {
    background-image: url(../../img/icons/v2/session-gray.svg);
}

.icon-calendar-gray {
    background-image: url(../../img/icons/v2/calendar-gray.svg);
}

.icon-payment-gray {
    background-image: url(../../img/icons/v2/payment-gray.svg);
}

.icon-nutrition-plan-gray {
    background-image: url(../../img/icons/v2/nutrition-plan-gray.svg);
}

.icon-logout-gray {
    background-image: url(../../img/icons/v2/logout-gray.svg);
}

.icon-eye-2 {
    background-image: url(../../img/icons/v2/eye.svg);
}

.icon-eye-white {
    background-image: url(../../img/icons/v2/eye-white.svg);
}

.icon-date {
    background-image: url(../../img/icons/v2/date.svg);
}

.icon-live-gray-sm {
    background-image: url(../../img/icons/v2/live-gray-sm.svg);
}

.icon-personal-training-gray-sm {
    background-image: url(../../img/icons/v2/personal-training-gray-sm.svg);
}

.icon-small-group-gray-sm {
    background-image: url(../../img/icons/v2/small-group-gray-sm.svg);
}

.icon-next-session {
    background-image: url(../../img/icons/v2/next-session.svg);
}

.icon-id-card {
    background-image: url(../../img/icons/v2/id-card.svg);
}

span.desc-icon {
    align-items: baseline;
    padding-top: 10px;
}
