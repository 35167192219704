.payment-membership {
  margin-top: 3rem;
  margin-bottom: 1.5rem;

  .pms {
    width: 100%;
    height: 88px;
    background-color: #FFF;
    line-height: 85px;
    border-radius: 12px;
    position: relative;
  }

  .checked{
    border: 2px solid #0080ce;
  }

  .in-checked{
    border: 2px solid #cecece;
  }

  label {
    &.radio-label {
      font-weight: 600;
      width: 60%;
    }
  }

  .member-extra {
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece;
    height: 60px;
    font-weight: 500;
    font-size: 16px;
  }

  .last-row {
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 60px;
    font-weight: 500;
    font-size: 16px;
  }

  .product {
    font-weight: 600;
  }

  .member-ship-title {
    font-family: "AntiqueOliveStd-Nord", sans-serif;
    font-style: italic;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 4rem;
    text-transform: uppercase;
  }

  .first-row {
    height: 135px;
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece;
    border-top: 1px solid #cecece;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    line-height: 100px;
  }

  .amount {
    font-family: "AntiqueOliveStd-Nord", sans-serif;
    font-style: italic;
    font-size: 27px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 100px;
    border-bottom: 1px solid #cecece;
  }

  input[type="radio"]{
    width: 12%;
    height: 1.3rem;
    position: relative;
    top: 5px;
  }

  button#payMembership {
    height: 46px;
    font-family: "AntiqueOliveStd-Nord", sans-serif;
    font-size: 13px;
    font-weight: 100;
    font-style: italic;
    text-transform: uppercase;
  }
  .small {
    font-size: 13px;
    font-weight: 400;
    font-family: "Montserrat";
    text-transform: lowercase;
  }

  i.icon.icon-visa.pos-payment {
    position: relative;
    top: 9px;
  }

  i.icon.icon-master-card.pos-payment {
    position: relative;
    top: 9px;
  }
  @media (max-width: 414px) {
      .member-ship-title {
        font-family: "AntiqueOliveStd-Nord", sans-serif;
        font-style: italic;
        font-size: 18px!important;
        font-weight: bold;
        margin-bottom: 2rem;
        text-transform: uppercase;
      }
      .amount {
        font-family: "AntiqueOliveStd-Nord", sans-serif;
        font-style: italic;
        font-size: 14px!important;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 100px;
        border-bottom: 1px solid #cecece;
      }
    }


}



