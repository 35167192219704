footer {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    margin-top: 2rem;
    padding-top: 57px;
    padding-bottom: 40px;
    color: $white;
    background-color: $black;
    font-family: montserrat;
    font-weight: 300;
    overflow: hidden;
    .about {
        p {
            text-align: justify;
            font-size: 15px;
        }
        small {
            font-size: 13px;
            font-weight: 700;
        }
    }
    .title {
        margin-bottom: 22px;
        font-size: 18px;
        color: $primary;
        text-transform: uppercase;
        font-weight: 500;
    }
    ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        font-size: 14px;
        line-height: 24px;
    }
    .available-apps {
        display: inline-flex;
        width: 100%;
        img {
            cursor: pointer;
            margin: 2px;
            max-width: 100%;
            height: auto;
        }
    }
    .contact-us {
        display: block;
        padding: 0 0 0 30px;
        height: 30px;
        line-height: 30px;
        position: relative;
        overflow: hidden;
        word-wrap: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        a {
            color: $white;
            font-size: 16px;
            font-weight: 400;
            margin-left: 5px;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
            &:after {
                content: "";
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                height: 30px;
                width: 30px;
                pointer-events: none;
                background-repeat: no-repeat;
                background-position: center center;
                -webkit-background-size: contain;
                -moz-background-size: contain;
                -o-background-size: contain;
                background-size: contain;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0, 0, 400,400' ml-update='aware'%3E%3Cg%3E%3Cpath d='M74.800 77.626 C 55.028 78.885,40.283 93.022,38.796 112.147 C 38.287 118.691,38.289 281.910,38.798 288.054 C 40.241 305.461,52.999 319.027,70.800 322.085 C 76.233 323.018,325.967 322.823,330.329 321.882 C 348.048 318.060,359.752 305.215,361.200 288.000 C 361.719 281.840,361.720 118.199,361.202 111.946 C 359.776 94.743,347.576 81.534,329.800 77.949 C 327.000 77.384,83.446 77.076,74.800 77.626 M302.845 110.269 C 305.966 111.670,304.928 112.850,287.851 127.318 C 279.463 134.424,260.810 150.224,246.400 162.429 C 231.990 174.634,216.632 187.645,212.272 191.342 C 202.695 199.460,201.930 200.000,200.000 200.000 C 197.948 200.000,198.105 200.122,177.377 182.552 C 151.381 160.519,129.732 142.191,112.800 127.883 C 96.990 114.522,96.000 113.583,96.000 111.939 C 96.000 110.626,96.288 110.422,98.720 110.008 C 101.923 109.464,301.620 109.719,302.845 110.269 M75.750 140.992 C 76.554 141.537,80.674 144.906,84.906 148.477 C 95.111 157.089,125.255 182.551,129.800 186.397 C 131.780 188.072,136.370 191.947,140.000 195.007 C 143.630 198.067,150.650 203.992,155.600 208.175 C 189.323 236.668,185.025 234.316,202.600 233.889 C 215.673 233.572,209.728 237.517,255.800 198.584 C 276.040 181.481,294.040 166.276,295.800 164.796 C 297.560 163.315,304.580 157.387,311.400 151.622 C 326.293 139.033,326.960 138.630,328.488 141.305 C 329.401 142.901,329.222 284.993,328.306 286.668 C 326.123 290.660,335.643 290.384,200.000 290.384 C 64.357 290.384,73.877 290.660,71.694 286.668 C 70.605 284.678,70.620 142.745,71.709 141.082 C 72.603 139.718,73.832 139.690,75.750 140.992 ' stroke='none' fill='%230080ce' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
    .useful-links {
        ul {
            li {
                a {
                    color: $white;
                    text-decoration: none;
                    -moz-opacity: 0.88;
                    -webkit-opacity: 0.88;
                    filter: alpha(opacity=88);
                    opacity: 0.88;
                    &:hover {
                        -moz-opacity: 1;
                        -webkit-opacity: 1;
                        filter: alpha(opacity=100);
                        opacity: 1;
                    }
                }
            }
        }
    }
    .social-network {
        /* margin-top: 20px; */
        ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            li {
                margin-right: 5px;
                .social-network-picture {
                    cursor: pointer;
                    width: auto;
                    height: 40px;
                    -moz-opacity: 0.88;
                    -webkit-opacity: 0.88;
                    filter: alpha(opacity=88);
                    opacity: 0.88;
                    &:hover {
                        -moz-opacity: 1;
                        -webkit-opacity: 1;
                        filter: alpha(opacity=100);
                        opacity: 1;
                    }
                }
            }
        }
    }
    .newsletter {
        .input-group {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                height: 40px;
                width: 40px;
                pointer-events: none;
                background-repeat: no-repeat;
                background-position: center center;
                -webkit-background-size: contain;
                -moz-background-size: contain;
                -o-background-size: contain;
                background-size: contain;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0, 0, 400,400' ml-update='aware'%3E%3Cg%3E%3Cpath d='M198.389 119.615 C 194.066 120.268,189.033 122.481,183.600 126.120 C 174.127 132.464,131.407 166.362,128.279 170.016 C 125.229 173.579,125.400 170.721,125.400 218.200 L 125.400 260.600 126.351 262.703 C 127.434 265.099,129.339 266.941,131.980 268.146 C 134.268 269.189,148.050 269.430,218.200 269.651 C 271.980 269.821,267.976 270.062,271.610 266.428 C 275.110 262.928,274.866 266.094,275.256 219.212 C 275.636 173.622,275.696 175.028,273.212 171.418 C 271.742 169.280,269.699 167.593,227.390 133.560 C 211.191 120.531,206.686 118.364,198.389 119.615 M206.600 131.577 C 209.417 132.863,210.386 133.612,238.600 156.307 C 262.087 175.200,263.709 176.635,264.373 179.100 C 265.304 182.558,264.355 255.733,263.362 257.071 C 261.640 259.390,264.697 259.306,199.521 258.832 C 138.822 258.390,138.418 258.377,137.000 256.804 C 136.060 255.761,135.509 182.109,136.416 178.742 C 137.146 176.031,141.318 172.320,161.800 156.164 C 195.202 129.818,198.628 127.937,206.600 131.577 M241.825 197.009 C 241.008 197.563,238.148 199.780,235.470 201.934 C 209.312 222.974,205.098 225.642,198.932 225.067 C 193.844 224.591,192.417 223.674,171.836 207.647 C 157.411 196.414,157.848 196.720,156.231 196.720 C 154.357 196.720,153.439 197.318,151.635 199.712 C 147.900 204.668,147.082 203.653,167.800 219.775 C 188.177 235.632,191.057 237.190,200.000 237.187 C 209.561 237.184,211.707 235.969,236.368 216.588 C 252.040 204.272,252.508 203.678,249.660 199.746 C 246.905 195.943,244.588 195.134,241.825 197.009 ' stroke='none' fill='%23050505' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
        button {
            font-size: 20px;
            line-height: 1em;
            font-weight: 600;
            padding: 0 15px;
            font-family: var(--font-family-monospace);
        }
        input[type="email"] {
            padding: 6px 12px 6px 40px;
            height: 40px;
            line-height: 40px;
        }
    }
    .footer-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border-top: 1px solid #3e3d3d;
        padding-top: 16px;
        font-size: 14px;
        color: #b1b2b2;
        text-transform: uppercase;
        a {
            color: #717274;
            text-decoration: none;
        }
    }
}