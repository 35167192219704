// Information block in cards
.b-info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    .b-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 0.75rem;
        max-width: 33%;
        width: 100%;
        &:last-child {
            padding-right: 0rem;
        }
    }
}

.b-info {
    text-align: left;
    .label {
        margin-bottom: 0.25rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: rgba($gray-900, 0.4);
        img.icon {
            display: inline-block;
            width: 19px;
            height: 19px;
            object-fit: contain;
            margin-right: 0.625rem;
        }
    }
    .info {
        font-size: 0.8125rem;
        font-weight: 600;
        color: $white;
        .badge {
            padding: 4px 8px;
            margin-left: 4px;
            font-weight: 600;
            margin-right: 00;
        }
    }
    img.avatar {
        display: inline-block;
        width: 30px;
    }
}

// Countdown block in cards
.countdown {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-family: $geogrotesque;
    color: $white;
    .timer {
        position: relative;
        width: 25%;
        font-size: 1.8rem;
        font-weight: 600;
        font-style: italic;
        text-shadow: 0 0 5px black;
        &:not(:last-child):after {
            content: ":";
            position: absolute;
            top: 0;
            right: 0;
            font-family: $geogrotesque;
            font-weight: 400;
            font-size: 1rem;
            font-style: italic;
        }
        &.finished {
            opacity: 0.6;
        }
    }
    span {
        display: block;
        font-size: 1rem;
        font-weight: 400;
        font-style: italic;
        text-align: center;
    }
}

// Video box
.video-boxes {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    .video-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 102px;
        height: 108px;
        margin: 10px;
        border-radius: 13px;
        box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.11);
        background-color: $white;
        cursor: pointer;
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            content: "";
            width: 33px;
            height: 33px;
            background: url("../../../img/player.svg") no-repeat;
            background-size: contain;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
        .category {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%) translateY(0);
            border-radius: 15px;
            padding: 0.25rem 0.5rem;
            background-color: rgba($black, 0.53);
            font-size: 0.6875rem;
            font-weight: 600;
            text-align: center;
            color: $white;
        }
        img {
            width: 75px;
            height: 97px;
            object-fit: contain;
        }
        @include media-breakpoint-up(lg) {
            width: 170px;
            height: 180px;
            &:after {
                width: 55px;
                height: 54px;
            }
            .category {
                font-size: 15px;
                padding: 4px 16px;
            }
            img {
                width: 125px;
                height: 162px;
                object-fit: contain;
            }
        }
    }
}

.videos {
    position: relative;
    display: block;
    .video-link {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        z-index: 1;
        transform: translate(-50%, 15px) scale(0.95);
        transition: 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:hover {
        .video-boxes {
            transform: scale(0.95);
            filter: blur(10px);
            opacity: 0.7;
        }
        .video-link {
            opacity: 1;
            transform: translate(-50%, -7px);
        }
    }
}

// Price listing
ul.price-listing {
    list-style: none;
    padding-left: 0;
    margin-top: 2rem;
    li {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
        font-family: $geogrotesque;
        font-size: 1.0625rem;
        font-style: italic;
        text-align: left;
        color: $gray-900;
        .price {
            font-family: $geogrotesque;
            font-size: 1.125rem;
            font-style: italic;
            font-weight: bold;
            text-align: right;
        }
        &.member {
            color: $blue;
        }
        &:not(:last-child) {
            border-bottom: solid 1px #cdcdcd;
        }
    }
}

// Block Type
.b-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px $gray-900;
    margin-bottom: 20px;
    .title {
        padding-bottom: 4px;
        font-family: $antique;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: $blue;
        border-bottom: solid 5px $gray-900;
        .cat-link {
            cursor: pointer;
            text-decoration: none;
        }
    }
    .link {
        font-family: $montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $gray-900;
    }
}

// Block filters
.b-filters {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .dropdown {
        margin-bottom: 0.5rem;
        &:not(:last-child) {
            margin-right: 1rem;
        }
        @include media-breakpoint-down(md) {
            width: calc(50% - 10px);
            margin-right: 5px;
            margin-left: 5px;
            &:not(:last-child) {
                margin-right: 5px;
                margin-left: 5px;
            }
            button {
                width: 100%;
            }
            .dropdown-menu {
                right: 0;
                left: auto;
            }
        }
        @include media-breakpoint-down(xs) {
            width: calc(100% - 10px);
            button {
                width: 100%;
            }
            .dropdown-menu {
                width: 100%;
            }
        }
    }
    .dropdown-toggle {
        font-size: 0.875rem;
        font-weight: 500;
        &::after {
            display: none;
        }
    }
    .dropdown-menu {
        min-width: 313px;
        padding: 0.5rem;
        margin-top: 0.25rem;
        border: none;
        border-radius: 3px;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.19);
        background-color: $white;
    }
    .dropdown-header {
        font-weight: 600;
        color: $gray-900;
    }
    ul {
        position: relative;
        list-style: none;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-bottom: 0;
        max-height: 235px;
        overflow-y: auto;
        li:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }
    .dropdown-more {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 11px;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white);
        }
    }
    .form-check-label {
        font-size: 0.875rem;
        font-weight: 500;
    }
    .form-search {
        margin: 0.5rem 1.5rem 1rem;
        input {
            font-size: 13px;
            border-radius: 16px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
            border: solid 1px #cdcdcd;
            background-color: $white;
        }
    }
    .filters-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .reset,
        .submit {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
        }
        .reset {
            color: #8192a1;
        }
        .submit {
            color: $blue;
        }
    }
    .avatar {
        width: 19px;
        height: 19px;
    }
    .timeslot {
        margin-top: 1rem;
        margin-bottom: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        .fromto {
            margin-bottom: 2rem;
            font-size: 0.8125rem;
            font-weight: 500;
            span {
                color: $blue;
            }
        }
    }
    &.center {
        justify-content: center;
    }
    .icon {
        margin-right: 10px;
        top: 3px;
        position: relative;
    }
}

.filter-items {
    max-height: 500px;
    overflow-y: auto;
    ul {
        max-height: 100%;
    }
    ul li:nth-child(n + 5) {
        display: none;
    }
    .show-more {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
        font-size: 0.8125rem;
        font-weight: 500;
        color: $blue;
        cursor: pointer;
    }
}

.fullwidth-dropdown {
    position: static !important;
    .dropdown-menu {
        width: 100% !important;
    }
}

// Radio choice list
.choice-list {
    margin-top: 1.5rem;
    list-style: none;
    padding-left: 0;
    .custom-radio {
        width: 100%;
        padding-left: 0;
        margin-bottom: 1rem;
        min-height: 58px;
        border-radius: 7px;
        border: solid 1px #c0cbd2;
        background-color: $white;
        font-size: 15px;
        font-weight: 600;
        .custom-control-label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 16px 16px 16px 42px;
            &:before,
            &:after {
                top: calc(50% - 0.5rem);
                left: 16px;
            }
            .number {
                font-size: 17px;
                font-weight: bold;
                color: $blue;
            }
            .price {
                font-family: $antique;
                font-size: 15px;
                font-style: italic;
                position: relative;
                max-width: calc(50% + 10px);
            }
            .number-sess {
                max-width: calc(50% - 60px);
            }
            .badge-info {
                position: absolute;
                left: -60px;
                top: 7px;
            }
        }
    }
}

.count-coaches {
    font-size: 14px;
    font-weight: 600;
    padding: 13px 4px;
}

// Sort dropdown: trier par
.sort-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
    font-weight: 600;

    @include media-breakpoint-down(xs) {
        // flex-direction: column;
    }

    .dropdown-toggle {
        display: inline-flex;
        width: 190px;
        padding: 9px 16px;
        margin-left: 8px;
        font-size: 14px;
        font-weight: 600;
        border: none;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
        &:after {
            position: absolute;
            right: 16px;
            top: 12px;
            width: 14px;
            height: 14px;
            background: url(../../../img/down-arrow.svg) no-repeat;
            background-size: contain;
            border: none;
        }
        i {
            margin-right: 8px;
        }
    }
}

.rotate180 {
    transform: rotate(180deg);
}

.cg-badge {
    width: 25px;
    height: 25px;
    display: initial;
    border-radius: 50%;
    position: relative;
    margin-left: 5px;
    &:before {
        content: attr(data-count);
        position: absolute;
        top: 50%;
        left: calc(50% + 13px);
        transform: translate(-50%, -50%);
        color: $white;
        background: $blue;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        padding: 2px;
    }
}

.coach-img {
    /*
    width: 200px;
    height: 600px;
    transform: scale(1.5);
    */
    object-fit: contain;
    max-width: 100%;
    bottom: -3rem;
    position: relative;
}

p.biography {
    display: block;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 13;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.reserver-coach {
    outline: none;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none !important;
    color: #0080ce !important;
}

.reserver-coach:hover {
    color: #ffff !important;
}

.no-dispo {
    font-weight: 600;
}

$color1: #f4f4f4;
$color2: #3197ee;
.radio {
    margin: 0.5rem;
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        + .radio-label {
            &:before {
                content: "";
                background: $color1;
                border-radius: 100%;
                border: 2px solid darken($color1, 25%);
                display: inline-block;
                width: 1.4em;
                height: 1.4em;
                position: relative;
                top: 0em;
                margin-right: 1em;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }
        &:checked {
            + .radio-label {
                &:before {
                    background-color: $color2;
                    box-shadow: inset 0 0 0 4px $color1;
                }
            }
        }
    }
}

.pack-selection {
    .number {
        font-size: 17px;
        font-weight: bold;
        color: $blue;
    }
    .price {
        font-family: $antique;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: var(--gray-900);
        &.right {
            float: right;
        }
        &.strikethrough {
            text-decoration: line-through;
            opacity: 0.46;
        }
    }
}

.btn-sort-order {
    width: 63px;
    padding: 9px 16px;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
}

.pack {
    position: absolute;
    top: 1px;
    font-size: 1px;
}

.badge {
    margin-right: 0.75rem;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
}

.blink {
    animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.circle {
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}

/************************/

.home,
.small-group,
.live-courses,
.nutrition,
.personal-training {
    .b-info-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        .b-info {
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 0 0 auto;
            width: 50%;
            max-width: 100%;
            margin-bottom: 0.5rem;
            padding-right: 0;
            &.last-child {
                margin-bottom: 0rem;
                padding: 0;
                width: 100%;
                .label {
                    margin-bottom: 0.5rem;
                }
            }
            &:nth-child(even) {
                padding-left: 2rem;
            }
            .label {
                font-family: $geogrotesque;
                font-size: 1rem;
                font-style: italic;
                margin-bottom: 0.25rem;
                font-weight: 300;
                color: rgba($white, 1);
                img.icon {
                    display: inline-block;
                    width: 19px;
                    height: 19px;
                    object-fit: contain;
                    margin-right: 0.625rem;
                }
            }
            .info {
                font-family: $geogrotesque;
                font-weight: 600;
                font-size: 1rem;
                font-style: italic;
                height: 48px;
                .badge {
                    font-size: 1rem;
                    font-weight: 500;
                    padding: 4px 8px;
                    margin-left: 15px;
                    margin-right: 0;
                    i {
                        margin-right: 0.5rem;
                    }
                }
                &.flex-between {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: stretch;
                    align-items: flex-start;
                    justify-content: space-between;
                }
                &.flex-around {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: stretch;
                    align-items: flex-start;
                    justify-content: space-around;
                }
            }
            img.avatar {
                display: inline-block;
                width: 30px;
            }
        }
    }
ul.price-listing {
    li {
        color: $white;
    }
}

}
