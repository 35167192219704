section.live-courses {
    position: relative;
    background-image: linear-gradient(to bottom, $white, #f7f7f7);
    padding-top: 30px;
    padding-bottom: 84px;
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        width: 60px;
        height: 100%;
        z-index: 2;
        @media (min-width: 767.98px) {
            width: 184px;
        }
    }
    @include media-breakpoint-up(md) {
        &:before {
            right: 0;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #f7f7f7 97%);
        }
        &:after {
            left: 0;
            background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #f7f7f7 97%);
        }
    }
}

.live-courses-slider {
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px;
    // .course-card {
    //     height: 474px;
    // }
}

.small-group-slider {
    position: relative;
    margin-bottom: 20px;
    .course-card {
        height: 474px;
    }
}

// Nutritionist
section.nutritions {
    position: relative;
    min-height: 520px;
    margin-top: 140px;
    margin-bottom: 50px;
    color: $white;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: calc(100% + 15px);
        border-radius: 25px;
        background-image: linear-gradient(70deg, #4b4b4b 2%, #2d2d2d 98%);
    }
    .section-title {
        font-weight: 600;
        font-size: 1.8rem;
        margin-bottom: 0;
        color: $blue;
        &:after {
            display: none;
        }
    }
}

.nutritionist-boxes {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 300px;
    overflow-x: auto;
    padding: 1.25rem 0.625rem 0 0.625rem;
    margin-bottom: 15px;
}

.nutritionist-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    max-width: 335px;
    height: 100px;
    padding: 10px 10px 10px 110px;
    margin-bottom: 30px;
    margin-right: 30px;
    border-radius: 13px;
    background-color: $blue-denim;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.12);
    &>div {
        display: block;
        width: 100%;
        text-align: center;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100px;
        height: 100%;
        object-fit: cover;
    }
    .name {
        // display: block;
        margin-bottom: 10px;
        font-family: $geogrotesque;
        font-size: 1.1rem;
        font-weight: 500;
        font-style: italic;
        color: $white;
        text-transform: capitalize;

        display: -webkit-box;
        width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .job {
        display: block;
        margin-bottom: 16px;
        font-family: $geogrotesque;
        font-size: 0.75rem;
        font-style: italic;
        color: rgba($gray-900, 0.5);
    }
    a {
        padding: 0.2rem 2rem;
        font-style: italic;
        text-transform: capitalize;
    }
}

@include media-breakpoint-up(lg) {
    /*
    .nutritionist-boxes {
        height: 370px;
    }
    .nutritionist-box {
        width: 100%;
        max-width: 350px;
        height: 109px;
        padding-left: 140px;
        img {
            width: 118px;
        }
        .name {
            font-size: 0.9375rem;
        }
        .job {
            font-size: 0.875rem;
            margin-bottom: 21px;
        }
        a {
            font-size: 0.875rem;
        }
    }
    */
}

section.personal-training {
    position: relative;
    // padding-top: 5rem;
    min-height: 609px;
    margin-top: 6rem;
    color: rgba($white, 0.54);
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 609px;
        border-radius: 25px;
        background: url('/img/pt_home.png')
    }
    .section-title {
        color: $white;
    }
    a {
        color: $white;
        text-decoration: underline;
    }
}

@media (min-width: 1400px) {
    section.nutritions:before {
        width: 1318px;
    }
    section.personal-training:before {
        width: 1318px;
    }
}

div#chartjs-tooltip {
    background-color: #343434;
    border-radius: 14px;
    color: #ffff;
    z-index: 999999!important;
}
section.nutritions:before{
    background: url("/img/nutrition_home.png");
    background-size: cover;
}
