.coach-header {
    height: auto;
    min-height: 624px;
    padding-top: 4rem;
    margin-bottom: 5rem;
    background: url("../../../img/motif2.png"), #f6f5fa;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
   
    .job {
        display: block;
        font-family: $antique-regular;
        font-size: 1.875rem;
        font-style: italic;
        line-height: 1.67;
        color: rgba($blue, 0.5);
        text-transform: uppercase;
    }
    .name {
        display: block;
        margin-bottom: 1rem;
        font-family: $antique;
        font-size: 2.5rem;
        font-style: italic;
        line-height: 1.25;
        color: $gray-900;
        text-transform: uppercase;
        @include media-breakpoint-down(xs) {
            font-size: 2rem;
        }
    }
    .btn {
        
        padding: 16px;
        border-radius: 24px;
        font-family: $antique;
        font-size: 13px;
        font-style: italic;
        font-weight: bold;
        text-transform: uppercase;
    }
    .coach-img {
        // margin-bottom: -100px;
    }
}

.coach-details {
    .title {
        margin-bottom: 30px;
        font-family: $antique;
        font-size: 1.25rem;
        font-style: italic;
        font-weight: bold;
        color: $gray-900;
        .votes {
            margin-top: 1rem;
            font-family: $montserrat;
        }
    }
    .row-spacing {
        margin-bottom: 3.5rem;
    }
    ul.specialities {
        list-style: none;
        padding-left: 0;
        li {
            display: inline-block;
            padding-left: 1.625rem;
            padding-right: 1.625rem;
            font-family: $montserrat;
            font-size: 1.1875rem;
            font-weight: 600;
            color: $gray-900;
            border-right: solid 1px #e0e0e0;
            @include media-breakpoint-down(md) {
                border: none;
                width: 100%;
            }
        }
    }
    ul.languages {
        list-style: none;
        padding-left: 0;
        li {
            display: inline-block;
            padding-left: 26px;
            padding-right: 26px;
            font-family: $montserrat;
            font-size: 1.0625rem;
            font-weight: 500;
            color: $gray-900;
        }
    }
}

.comment {
    margin-bottom: 42px;
    &:not(:last-child) {
        border-bottom: solid 1px #e4e4e4;
    }
    .media {
        margin-bottom: 12px;
        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            margin-right: 1rem;
            border-radius: 50%;
        }
        .name {
            font-size: 1.125rem;
            font-weight: bold;
            .date {
                font-size: 0.875rem;
                font-weight: normal;
                color: rgba($gray-900, 0.6);
            }
            .badge {
                font-weight: 600;
                padding: 4px 8px;
            }
        }
    }
    p {
        margin-bottom: 36px;
    }
}

.available-box {
    width: 100%;
    height: 159px;
    padding: 24px 32px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    .day {
        font-size: 1.125rem;
        font-weight: bold;
        color: $blue;
        margin-bottom: 18px;
    }
    .date {
        font-weight: 500;
        margin-bottom: 15px;
    }
    .time .badge {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        font-size: 1.1875rem;
        font-weight: 600;
    }
}

.time-price-tabs {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    .nav-link {
        margin-right: 0.625rem;
        border-radius: 1.25rem;
        border: solid 2px #9b9b9b;
        background: transparent;
        font-family: $antique-regular;
        font-size: 0.9375rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: #9b9b9b;
        .icon {
            filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(187deg) brightness(93%) contrast(90%);
        }
    }
    &.nav-pills .nav-link.active,
    &.nav-pills .show > .nav-link {
        background-color: transparent;
        border-color: $blue;
        color: $blue;
        .icon {
            filter: invert(32%) sepia(98%) saturate(2695%) hue-rotate(183deg) brightness(93%) contrast(104%);
        }
    }
}

.coachs-container,
.nutritions-container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
}
span#showMore {
    cursor: pointer;
    color: #0080cf;
    font-weight: 500;
}

// Page disponibilities
.modal-open .modal.modal-disponibilities {
    padding-right: 0 !important;
}

.modal-disponibilities {
    &.has-stiked-footer {
        .modal-body {
            margin-bottom: 115px;
        }
        .modal-footer {
            background: $white;
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 999;
        }
    }
    .modal-header .close {
        margin: -1rem auto -1rem -1rem;
        padding: 0.5rem;
        float: left;
        font-size: 2.5rem;
    }
    .modal-dialog {
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
    }
    .pack {
        display: block;
        font-family: $antique;
        font-size: 1.4375rem;
        font-style: italic;
        color: $gray-900;
        margin-bottom: 32px;
        padding-left: 20px;
        .number {
            color: $blue;
        }
    }
    .pack-details {
        min-height: 110px;
    }
    .pack-count {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 106px;
        height: 79px;
        border-radius: 6px;
        background-color: $gray-900;
        font-family: $montserrat;
        font-size: 1rem;
        font-weight: 600;
        color: $white;
        .count {
            font-size: 1.25rem;
            line-height: 1.2;
            .number {
                font-size: 1.9375rem;
            }
        }
    }
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        text-align: center;
        .btn {
            font-weight: bold;
            &.btn-primary {
                font-family: $antique;
                font-size: 0.8125rem;
                font-style: italic;
                text-transform: uppercase;
            }
        }
    }
}

.pack-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px;
}

.b-sessions {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 300px);
}

.b-session {
    max-width: 230px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;
    font-size: 0.8125rem;
    border-left: 1px solid #e3e3e3;
    .date {
        font-size: 13px;
        font-weight: 600;
        color: $blue;
    }
    .times {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 1rem;
        .time {
            padding: 8px 12px;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 8px;
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.14);
            background-color: $white;
            font-size: 1rem;
            font-weight: 600;
            .icon {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.b-disabled {
    opacity: 0.65;
    pointer-events: none;
}

.selectedButton {
    background-color: $blue;
    span {
        display: none;
    }
    img {
        display: block;
        height: 20px;
        margin: 0 auto;
    }
}

@include media-breakpoint-down(md) {
    .pack-details {
        justify-content: center;
        flex-wrap: wrap;
    }
    .modal-disponibilities .pack-count .count {
        margin-left: auto;
        margin-right: auto;
    }
    .b-sessions {
        width: 100%;
        max-width: 100%;
        margin-top: 32px;
        margin-bottom: 32px;
        justify-content: flex-start;
    }
    .modal-disponibilities .pack {
        font-size: 1rem;
    }
}

.b-disponibilities {
    display: none;
    .title {
        display: block;
        font-family: $montserrat;
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 2.125rem;
    }
    .date {
        padding: 0.375rem 0.75rem;
        margin-bottom: 1rem;
        border-radius: 0.1875rem;
        background-color: $blue;
        color: $white;
        font-size: 0.9375rem;
        font-weight: 600;
    }
    ul {
        list-style: none;
        padding-left: 0;
        li {
            margin-bottom: 1rem;
        }
    }
    .no-dispo {
        margin-top: 2rem;
        text-align: center;
        font-weight: bold;
    }
}

.b-dispo-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    min-height: 74px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    span,
    .select-time {
        font-weight: bold;
    }
    .select-time {
        width: 170px;
        height: 39px;
        text-align: center;
        img {
            display: none;
        }
        &.selected {
            background-color: $blue;
            span {
                display: none;
            }
            img {
                display: block;
                height: 20px;
                margin: 0 auto;
            }
        }
    }
}

.modal-disponibilities {
    .fc-toolbar h2 {
        font-family: $montserrat;
        font-size: 1.35rem;
        font-weight: 600;
        text-transform: capitalize;
    }
    .fc-button-primary {
        color: #979797;
        background-color: $white;
        border-color: $white;
    }
    .fc-unthemed th {
        font-family: $montserrat;
        font-size: 0.9375rem;
        font-weight: 500;
        color: rgba($gray-900, 0.5);
    }
    .fc-unthemed th,
    .fc-unthemed td,
    .fc-unthemed thead,
    .fc-unthemed tbody,
    .fc-unthemed .fc-divider,
    .fc-unthemed .fc-row,
    .fc-unthemed .fc-content,
    .fc-unthemed .fc-popover,
    .fc-unthemed .fc-list-view,
    .fc-unthemed .fc-list-heading td {
        border-color: white;
    }
    .fc-content-skeleton td {
        position: relative;
    }
    .fc-unthemed tbody td {
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 40px;
            border-radius: 3.6px;
            background-color: #dee1e3;
            font-family: $montserrat;
            font-size: 1.125rem;
            font-weight: 500;
        }
        &.fc-state-highlight span {
            color: $white;
            background-color: $blue;
        }
    }
    .fc-unthemed td.fc-today {
        background-color: $white;
        span {
            color: $white;
            background-color: rgba($blue, 0.5);
        }
    }
    .fc-dayGrid-view .fc-body .fc-row {
        min-height: 3em;
    }
    .fc-dayGrid-view .fc-body .fc-row {
        height: 48px !important;
    }
    .fc-widget-header {
        margin-bottom: 13px;
    }
    @include media-breakpoint-up(md) {
        .fc-unthemed tbody td span {
            width: 60px;
            height: 60px;
            cursor: pointer;
        }
        .fc-dayGrid-view .fc-body .fc-row {
            height: 75px !important;
        }
    }
    .fc-scroller.fc-day-grid-container {
        height: auto !important;
    }
    .fc-day-top.disable-day {
        position: relative;
        color: #bbbbbb;
        span {
            background-color: rgba(#dee1e3, 0.3);
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 32px;
            left: 12px;
            transform: rotate(-45deg);
            height: 1px;
            width: 80%;
            background-color: #bbbbbb;
        }
    }
}

.list-dispo {
    overflow-y: auto;
    max-height: 436px;
}

.baseline-dispo {
    align-items: baseline;
}

td.td-coach.date-coach.live-past-session {
    position: absolute;
    right: 172px;
}

.error-time {
    margin-top: 1rem;
    font-size: 1.1875rem;
    text-align: center;
    color: #161d39;
}

i.icon.icon-wifi-black.i-xl.live-past-icon {
    position: absolute;
    left: 15px;
    top: 16px;
}

.date-availability {
    float: right;
    font-size: 0.875rem;
    font-weight: 600;
    color: #040404;
    text-transform: capitalize;
}

ul#dataList li:nth-child(n + 3) {
    display: none;
}

// span {
//     cursor: pointer;
// }
