.hero-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 364px;
    background-color: rgba(52, 52, 52, 0.57);
    background-size: cover;
    background-position: center;
    @include media-breakpoint-down(md) {
        background-position: center right;
    }
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 0;
    }
    .title {
        position: relative;
        font-family: $antique;
        font-size: 2.1875rem;
        font-style: italic;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 0.5rem;
        margin-bottom: 1.75rem;
        color: $white;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-bottom: 4px solid #0080ce;
        }
        @include media-breakpoint-down(md) {
            font-size: 1.5rem;
        }
    }
}

.filters {
    margin: 2.625rem 0;
    text-align: center;
    .section-title {
        font-size: 1.0625rem;
        line-height: 1.71;
        &:after {
            border-color: $gray-900;
        }
    }
}

.days-plans {
    margin-top: 3.125rem;
    margin-bottom: 1.875rem;
}

.day-plan {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-top: 2.125rem;
    padding-left: 1rem;
    margin-bottom: 2.5rem;
    flex-wrap: wrap;
    .planing-live-corse {
        margin-bottom: 5px;
        overflow: hidden;
        width: 80%;
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }
    @media (min-width: 992px) {
        .planing-live-corse .owl-stage-outer {
            padding: 0px 0px;
        }
    }
    .customNextBtn {
        width: 32px;
        height: 37px;
        display: inline-block;
        cursor: pointer;
        &.disabled {
            opacity: 0.5;
            cursor: default;
        }
    }
    .customPrevBtn {
        width: 32px;
        height: 37px;
        display: inline-block;
        cursor: pointer;
        &.disabled {
            opacity: 0.5;
            cursor: default;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -5%;
        width: 110%;
        height: 250px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        background-image: linear-gradient(to bottom right, #dee6ec 60%, #f3f6f8 85%);
        transform: skewX(24deg);
        z-index: -1;
    }
    .course-card {
        height: auto;
    }
    .day {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1.5rem;
        width: 168px;
        font-family: $antique;
        font-size: 1.1875rem;
        font-style: italic;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: $gray-900;
        i {
            margin-bottom: 0.5rem;
        }
        .date {
            display: block;
            padding-top: 0.625rem;
            font-family: $antique-regular;
            font-size: 1.125rem;
            font-weight: normal;
            text-transform: initial;
            font-style: italic;
            color: $blue;
        }
    }
    .b-info {
        margin-top: 0.625rem;
    }
    .media {
        align-items: center;
        margin-right: 0;
        font-size: 15px;
        text-align: left;
        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 25px;
        }
    }
    &.coming-soon {
        &:before {
            background-image: linear-gradient(to bottom right, #efefef 60%, #f6f6f6 85%);
        }
        .text {
            position: absolute;
            top: 100px;
            left: 50%;
            width: 100%;
            font-family: $antique-regular;
            font-size: 1.25rem;
            font-style: italic;
            transform: translateX(-50%);
        }
    }
}

@include media-breakpoint-down(lg) {
    .day-plan {
        flex-direction: column;
        align-items: center;
        .day {
            margin-bottom: 20px;
        }
    }
}

@include media-breakpoint-down(md) {
    .day-plan {
        padding-left: 0;
        &::before {
            left: -15px;
            width: calc(100% + 30px);
            transform: skewX(0deg);
            border-radius: 0;
        }
    }
}

//   .live {
//       .group-card {
//           height: auto;
//       }
//   }

.live-course-title::after {
    border-bottom: 4px solid $live-course-color !important;
}

.small-group-title::after {
    border-bottom: 4px solid $small-group-color !important;
}

.personal-training-title::after {
    border-bottom: 4px solid $personal-training-color !important;
}

.vod-title::after {
    border-bottom: 4px solid $vod-color !important;
}

.nutrition-title::after {
    border-bottom: 4px solid $nutrition-color !important;
}
